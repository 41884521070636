/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import React from 'react';
import {connect} from 'react-redux';
import {Button, Checkbox, Col, Form, Input, Layout, Row} from 'antd';

import {signUpRequest} from '../../AuthenticationApiActions';
import './SignUp.css';
import logo from '../../../../assets/logo.png';
import ConstantMessages from '../../../../utils/Strings';
import UtilHelper from "../../../../utils/UtilHelper";
import MessageModal from "../../../../utils/components/MessageModal/MessageModal";
import CustomFooter from "../../../Dashboard/components/CustomFooter/CustomFooter";
import GenaLoader from "../../../../utils/components/GenaLoader/GenaLoader";
import MobileNumber from "../../../../utils/components/MobileNumber/MobileNumber";
import {STATIC_URLS} from "../../../../utils/Constants";

const {Content, Footer} = Layout;
const FormItem = Form.Item;

class SignUpForm extends React.Component {

  /**
   * This method is triggered when form is submitted by the user
   * @param e
   */
  handleSubmit = e => {
    e.preventDefault();

    this.setState({formSubmit: true}, () => {
      this.props.form.validateFields((err, formData) => {
        if (!err && this.state.validPhoneNumber && this.state.disclaimerSelected) {

          formData.phone = this.state.phone;

          this.setState({showLoader: true}, () => {
            this.props.onSignUp(formData).then(() => {
              this.setState({showLoader: false}, () => {
                this.refs.messageModal.setState({
                  showModal: true, title: ConstantMessages.signupSuccessTitle,
                  message: ConstantMessages.signupSuccessMessage,
                  showOkButton: true,
                  handleOk: this.navigateToHome,
                  okText: 'Ok'
                });
              });
            }, () => {
              this.setState({showLoader: false});
            });
          });
        }
      });
    });

  };
  navigateToHome = () => {
    window.location = '/';
  };

  updateMobileNumber = (phone, validPhoneNumber) => {
    this.setState({phone, validPhoneNumber});
  };

  acceptDisclaimer = e => {
    this.setState({disclaimerSelected: e.target.checked});
  };

  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      formSubmit: false,
      phone: '',
      validPhoneNumber: false,
      disclaimerSelected: false
    };

  }

  /**
   * Render the UI of the login form
   * @returns {XML}
   */
  render() {
    const {getFieldDecorator} = this.props.form;

    const fieldValidationRules = {
      username: {
        rules: [
          {type: 'email', message: ConstantMessages.enterValidEmailAddress},
          {required: true, message: ConstantMessages.emailAddressRequired}
        ],
      },
      firstName: {
        rules: [
          {required: true, message: ConstantMessages.firstNameRequired},
          {validator: UtilHelper.validateUserName}
        ]
      },
      lastName: {
        rules: [
          {required: true, message: ConstantMessages.lastNameRequired},
          {validator: UtilHelper.validateUserName}
        ]
      },
      organization: {
        rules: [
          {required: true, message: ConstantMessages.organizationRequired},
          {validator: UtilHelper.validateBlankSpaces}
        ]
      }
    };

    return (
      <Layout>

        <Content>
          <div className='signup-form-outer-div'>
            <div className='signup-form-inner-div'>

              <Form onSubmit={this.handleSubmit}>

                <FormItem>
                  <div className='application-logo-img-div'>
                    <img className='application-logo-img' src={logo} alt="Loading..."/>
                  </div>
                </FormItem>

                <Row>
                  <Col span={11}>
                    <Form.Item label="First Name">
                      {getFieldDecorator('firstName', fieldValidationRules.firstName)(
                        <Input placeholder="Enter first name" maxLength={50}/>
                      )}
                    </Form.Item>
                  </Col>

                  <Col offset={2} span={11}>
                    <Form.Item label="Last Name">
                      {getFieldDecorator('lastName', fieldValidationRules.lastName)(
                        <Input placeholder="Enter last name" maxLength={50}/>
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={11}>
                    <Form.Item label="Email Address">
                      {getFieldDecorator('username', fieldValidationRules.username)(
                        <Input placeholder="Enter email address"/>
                      )}
                    </Form.Item>
                  </Col>

                  <Col offset={2} span={11}>
                    <MobileNumber phone={this.state.phone}
                                  updateMobileNumber={this.updateMobileNumber}
                                  className="sign-up-input"
                                  required={true}
                    />
                    {
                      this.state.formSubmit && !this.state.validPhoneNumber ?
                        <div className="phone-error">Please enter valid mobile number</div> : ''
                    }
                  </Col>
                </Row>

                <Row>
                  <Col span={11}>
                    <Form.Item label="Organization">
                      {getFieldDecorator('organization', fieldValidationRules.organization)(
                        <Input placeholder="Enter organization" maxLength={50}/>
                      )}
                    </Form.Item>
                  </Col>

                  <Col offset={2} span={11}>
                    <div className="height-35"></div>
                    <Form.Item>
                      {getFieldDecorator('acceptCondition', fieldValidationRules.acceptCondition)(
                        <div>
                          <Checkbox onChange={this.acceptDisclaimer}></Checkbox>
                          <span>I accept the <a href={STATIC_URLS.DISCLAIMER} target='_blank' rel="noopener noreferrer">disclaimer</a></span>
                        </div>
                      )}
                      {
                        this.state.formSubmit && !this.state.disclaimerSelected ?
                          <div
                            className="error-terms-condition-not-selected">Please accept the disclaimer</div> : ''
                      }
                    </Form.Item>

                  </Col>
                </Row>

                <Row>
                  <Col span={11}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="signup-form-button">
                      Sign Up
                    </Button>
                  </Col>

                  <Col offset={2} span={11}>
                    <div className="signup-redirect-to-login">
                      Or <a href="/signin">go to sign in!</a>
                    </div>
                  </Col>
                </Row>
              </Form>

            </div>

            <MessageModal
              ref="messageModal"
            />
          </div>
        </Content>
        <Footer>
          <CustomFooter/>
        </Footer>

        <GenaLoader showLoader={this.state.showLoader}/>
      </Layout>
    )
  }

}

let SignUp = Form.create()(SignUpForm);

const mapStateToProps = state => {
  return {
    loggedInUser: state.auth.loggedInUser
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {

    onSignUp: (data) => {
      return dispatch(signUpRequest(data)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    }

  }
};

SignUp = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp);

export default SignUp;

import React from 'react';
import {connect} from 'react-redux';
import {Button, Form, Icon, Input, Modal, Select, Tooltip} from 'antd';
import './ChangeUserPassword.css';
import UtilHelper from "../../../../utils/UtilHelper";
import {changeUserPasswordRequest} from "../../AuthenticationApiActions";
import GenaLoader from "../../../../utils/components/GenaLoader/GenaLoader";

const {Item: FormItem} = Form;

let ChangeUserPassword = Form.create()(class extends React.Component {

  showModal = () => {
    this.setState({ visible: true});
  };

  handleCancelPasswordChange = () => {
    this.setState({visible: false, showLoader: false }, () => {
      this.props.form.resetFields();
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({showLoader: true}, () => {
          this.props.changeUserPassword(this.props.userId, values.password).then(res => {
            this.handleCancelPasswordChange();
          }, error => {
            this.setState({showLoader: false});
          });
        });
      }
    });
  };

  /**
   * Compare the new password is matching
   * @param rule: rule name
   * @param value: value of the input field which is to be validated
   * @param callback: Method to be called after validation is performed
   */
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Passwords does not match!');
    } else {
      callback();
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      showLoader: false
    }
  };

  render() {
    const {getFieldDecorator} = this.props.form;

    const {visible} = this.state;

    const fieldValidationRules = {
      password: {
        rules: [
          {required: true, message: "Please input new password"},
          {validator: this.validateToNextPassword},
          {validator: UtilHelper.validateBlankSpaces},
          {validator: UtilHelper.validatePassword}
        ]
      },
      newPassword: {
        rules: [
          {required: true, message: "Please re-enter new password"},
          {validator: UtilHelper.validateBlankSpaces},
          {validator: this.compareToFirstPassword}
        ]
      }
    };

    return (
      <>
      <Tooltip placement="top" title="Change password">
        <Button onClick={() => this.showModal()}>
          <Icon className="red" type="redo" />
        </Button>
      </Tooltip>

      <Modal
        visible={visible}
        title= {"Change Password"}
        maskClosable={false}
        onCancel={this.handleCancelPasswordChange}
        footer={null}>

        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="New Password">
            {getFieldDecorator('password', fieldValidationRules.password)(
              <Input type="password" placeholder="Enter new password"/>
            )}
          </Form.Item>
          <Form.Item label="Re-enter New Password">
            {getFieldDecorator('newPassword', fieldValidationRules.newPassword)(
              <Input type="password" placeholder="Re-enter new password"/>
            )}
          </Form.Item>

          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              className="change-password-form-button">
              Change Password
            </Button>

            <Button
              type="default"
              className="change-password-form-button"
              onClick={this.handleCancelPasswordChange}>
              Cancel
            </Button>

          </FormItem>
        </Form>
      </Modal>

      <GenaLoader showLoader={this.state.showLoader} />
      </>
    );
  };

});


const mapStateToProps = (state) => {
  return {
    users: state.user.users,
    loggedInUser: state.auth.loggedInUser,
    currentRole: state.auth.currentRole
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeUserPassword: (userId, password) => {
      return dispatch(changeUserPasswordRequest(userId, password)).then(res => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    }
  }
};

ChangeUserPassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeUserPassword);



export default ChangeUserPassword;
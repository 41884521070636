/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import Cookies from 'universal-cookie';

const cookies = new Cookies();

/**
 * Used to manage the cookies of the application
 */
class Auth {

  static login(user) {
    cookies.set('user', user, {path: '/'});
  };

  /**
   * Remove all cookies so that users session will be expired
   */
  static logout() {
    cookies.remove('user', {path: '/'});
  };

  /**
   * Remove all cookies so that users session will be expired
   */
  static logoutAndNavigateToLogin() {
    cookies.remove('user', {path: '/'});
    window.location = '/';
  }

  /**
   * Check if authentication JWT token exists in cookies
   * If it exists then return true
   */
  static isLoggedIn() {
    const user = cookies.get('user', {path: '/'});
    return !!user;
  };

  /**
   * Get logged in user details
   */
  static getUserDetails() {
    return cookies.get('user', {path: '/'});
  }

  static setSystemGeneratedPassword(isSystemGeneratedPassword) {
    const user = cookies.get('user', {path: '/'});
    cookies.set('user', {...user, isSystemGeneratedPassword: isSystemGeneratedPassword}, {path: '/'});
  };

  static setSurveySubmit(isSurveySubmitted) {
    const user = cookies.get('user', {path: '/'});
    cookies.set('user', {...user, isSurveySubmitted: isSurveySubmitted}, {path: '/'});
  };

}

export default Auth;
import React from 'react';
import {connect} from 'react-redux';
import {getSubscriptionPlans, updatePlanActiveStatusRequest} from "../../BillingApiActions";
import {setPageTitle} from "../../../Dashboard/DashboardActions";
import './Plans.css';
import AddPlan from "../AddPlan/AddPlan";
import Notification from '../../../../utils/Notification';
import GenaLoader from "../../../../utils/components/GenaLoader/GenaLoader";
import {Button} from 'antd';
import MessageModal from "../../../../utils/components/MessageModal/MessageModal";
import UtilHelper from "../../../../utils/UtilHelper";


const notification = new Notification();

/**
 * User table component which shows all users data
 */
class Plans extends React.Component {

  changePlanView = e => {
    const viewType = e.target.value;

    this.setState({viewType});
  };

  hideLoader = () => {
    this.setState({showLoader: false});
  };

  getPlanList = () => {
    this.setState({showLoader: true}, () => {
      this.props.getSubscriptionPlans().then(res => {


        this.setState({subscriptionPlans: UtilHelper.sortJsonArray(res, 'name'), showLoader: false}, () => {
        });
      }, error => {
        this.hideLoader();
      });
    });
  };

  updatePlanActiveStatus = (planId, isActive) => {

    this.refs.messageModal.setState({
      showModal: true,
      message: 'Are you sure you want to ' + (isActive ? 'activate' : 'deactivate') + ' the subscription plan?',
      title: isActive ? 'Activate Subscription Plan' : 'Deactivate Subscription Plan',
      showOkButton: true,
      handleOk: () => this.updatePlan(planId, isActive),
      okText: 'Yes',
      cancelText: 'No',
      showCancelButton: true
    });
  };

  updatePlan = (planId, isActive) => {
    this.setState({showLoader: true}, () => {
      const formData = {subscriptionPlanId: planId, isActive: isActive};

      this.props.updatePlanActiveStatus(formData).then(res => {
        const message = res.isActive ? 'Subscription plan activated successfully.' : 'Subscription plan deactivated successfully.';
        notification.show('success', 'Subscription Plan', message);
        this.getPlanList();
      }, error => {
        this.hideLoader();
      });
    });

  };

  constructor(props) {
    super(props);

    this.state = {
      subscriptionPlans: [],
      showLoader: false
    }
  }

  componentDidMount() {
    this.props.updatePageTitle(this.props.pageTitle, false);

    this.getPlanList();
  }

  render() {
    return (
      <div className="sivotec-page-content">

        <AddPlan refreshPlanList={this.getPlanList}/>

        <div id="planContainer" className="plan-container">
          {
            this.state.subscriptionPlans.map(plan => {
              let classes = "plan-details";

              let type = '';

              if (plan.isDefault) {
                type = 'Default';
              } else if (plan.isPromotional) {
                type = 'Promotional';
              } else if (plan.isSpecial) {
                type = 'Special';
              } else if (plan.isOfflinePayment) {
                type = 'Offline';
              } else {
                type = 'Normal';
              }

              let planAmount = plan.payByPaypalOnly ? Number.parseFloat(plan.paypalAmount) / 100 : Number.parseFloat(plan.amount) / 100;
              let discountAmount = planAmount;
              if (plan.Coupon) {
                discountAmount = ((planAmount * (100 - plan.Coupon.percentOff)) / 100).toFixed(2);
              }
              return (
                <div className="plan" key={plan.id}>
                  <div className="plan-type-container">
                    <div className="plan-type">
                      {type}
                    </div>
                  </div>
                  <div className="plan-type-bottom">
                    <div className="arrow-down">
                    </div>
                  </div>

                  <div className={classes} key={plan.id}>
                    <div className="color-white">
                      {plan.name}
                    </div>

                    <div className="subscription-amount-info">
                      <span className="align-top color-gray">$</span>
                      <span className="color-white plan-amount">
                        {discountAmount}
                      </span>
                      <span className="color-gray">/{plan.interval}</span>
                    </div>

                    {plan.Coupon && (
                      <div className="subscription-actual-plan-amount">
                        <span className="color-white">{plan.Coupon.percentOff}% off on </span>
                        <span className="color-white actual-plan-amount">${planAmount}</span>
                      </div>
                    )}

                    <div className="color-white">
                      {plan.noOfCases !== null ? plan.noOfCases + (plan.noOfCases > 1 ? ' Free Cases' : ' Free Case') : 'Unlimited Cases'}
                    </div>

                    {!plan.isDefault
                      ?
                      (
                        plan.isActive
                          ? <Button type="primary" onClick={() => this.updatePlanActiveStatus(plan.id, false)}
                                    className="deactivate-plan"> Deactivate Plan</Button>
                          : <Button type="primary" onClick={() => this.updatePlanActiveStatus(plan.id, true)}
                                    className="activate-plan"> Activate Plan</Button>
                      )
                      : <span></span>
                    }
                  </div>
                </div>
              )
            })
          }
        </div>

        <GenaLoader showLoader={this.state.showLoader}/>

        <MessageModal
          ref="messageModal"
        />
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {}
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updatePageTitle: (pageTitle, enablePageTitle) => {
      dispatch(setPageTitle(pageTitle, enablePageTitle));
    },

    getSubscriptionPlans: () => {
      return dispatch(getSubscriptionPlans()).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    updatePlanActiveStatus: (formData) => {
      return dispatch(updatePlanActiveStatusRequest(formData)).then(res => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

  }
};

Plans = connect(
  mapStateToProps,
  mapDispatchToProps
)(Plans);


export default Plans;

import React, {Component} from 'react';
import Routes from './routes';
import './App.css';
import 'antd/dist/antd.css';
import 'react-table/react-table.css';

/**
 * Root component of the application
 */
class App extends Component {
  render() {
    return (
      <Routes/>
    );
  }
}

export default App;

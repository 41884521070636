/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import React from 'react';
import {connect} from 'react-redux';
import {Form, Icon, Input, Modal, Select, Tooltip, Checkbox} from 'antd';
import Notification from '../../../../utils/Notification';
import './AssociatePlan.css';
import UtilHelper from "../../../../utils/UtilHelper";
import {
  assignSubscriptionPlan,
  getSpecialSubscriptionPlans,
  subscribeOfflinePaymentPlan
} from '../../BillingApiActions';
import GenaLoader from "../../../../utils/components/GenaLoader/GenaLoader";

const {Item: FormItem} = Form;
const {Option} = Select;

const notification = new Notification();

let AssociatePlanForm = Form.create()(class extends React.Component {

    constructor(){
      super()
      this.state = {
        isSingleUserPlan: false
      }
    }

    isSingleUserPlanChange = e => {
      this.setState({isSingleUserPlan: e.target.checked });
    }

    render() {
      let {visible, onCancel, onCreate, form} = this.props;
      const {getFieldDecorator, getFieldError} = form;

      let isOnlinePayment = this.props.planType === 'onlinePayment';

      // form fields validation rules
      const fieldsConfig = {
        planType: {
          rules: [{
            required: true, message: 'Please select subscription plan type'
          }]
        }
      };

      if (isOnlinePayment) {
        fieldsConfig['subscriptionPlanId'] = {
          rules: [
            {required: true, message: 'Please select subscription plan'}
          ]
        }
      } else {
        fieldsConfig['amount'] = {
          rules: [
            {required: true, message: 'Please enter plan amount'},
            {validator: UtilHelper.validateNegativeValue}
          ]
        }
      }


      const plans = this.props.plans && this.props.plans.filter((plan) => {
        return !this.props.associatedPlans.find(associated => associated.id === plan.id);
      });

      return (
        <Modal
          visible={visible}
          title="Assign Special Subscription Plan"
          okText="OK"
          maskClosable={false}
          onCancel={onCancel}
          onOk={onCreate}>
          <Form layout="vertical">

            <FormItem
              validateStatus={getFieldError('planType') ? 'error' : ''}
              help={getFieldError('planType') || ''}
              label="Subscription Plan Type">
              {getFieldDecorator('planType', fieldsConfig.planType)(
                <Select placeholder="Select a subscription plan type" onChange={this.props.planTypeChange}>
                  <Option key="onlinePayment" value="onlinePayment">Online Payment</Option>
                  <Option key="offlinePayment" value="offlinePayment">Offline Payment</Option>
                </Select>
              )}
            </FormItem>

            {
              isOnlinePayment ?
                (<FormItem
                  validateStatus={getFieldError('subscriptionPlanId') ? 'error' : ''}
                  help={getFieldError('subscriptionPlanId') || ''}
                  label="Special Subscription Plan">
                  {getFieldDecorator('subscriptionPlanId', fieldsConfig.subscriptionPlanId)(
                    <Select placeholder="Select subscription plan">
                      {
                        plans.map(plan => {
                          return <Option key={plan.id} value={plan.id}>{plan.name} (Amount: ${plan.hasOwnProperty('paypalAmount') ? (plan.paypalAmount/100) : (plan.amount / 100)})</Option>
                        })
                      }
                    </Select>
                  )}
                </FormItem>)
                : (
                  <>
                    <FormItem
                      validateStatus={getFieldError('amount') ? 'error' : ''}
                      help={getFieldError('amount') || ''}
                      label="Amount">
                      {getFieldDecorator('amount', fieldsConfig.amount)(
                        <Input placeholder="Enter amount" type="number" min={0}/>
                      )}
                    </FormItem>
                    <FormItem
                      validateStatus={getFieldError('isSingleUserPlan') ? 'error' : ''}
                      help={getFieldError('isSingleUserPlan') || ''}
                      label="Is single user plan?">
                      {getFieldDecorator('isSingleUserPlan', fieldsConfig.isSingleUserPlan)(
                        <Checkbox checked={this.state.isSingleUserPlan} onChange={this.isSingleUserPlanChange}>
                          Yes, Single user only
                        </Checkbox>
                      )}
                    </FormItem>
                  </>
                )
            }

          </Form>
        </Modal>
      );
    }
  }
);

class AssociatePlan extends React.Component {

  planTypeChange = value => {
    this.setState({planType: value});
  };

  showModal = () => {
    this.setState({visible: true, showLoader: true}, () => {
      this.props.getSpecialSubscriptionPlans().then(res => {
        this.setState({plans: res, showLoader: false});
      }, err => {
        this.setState({showLoader: false});
      });
    });
  };

  handleCancel = () => {
    const {form} = this.formRef.props;
    form.resetFields();
    this.setState({visible: false, showLoader: false});
  };

  /**
   * Method to add new experiment
   */
  assignSubscriptionPlan = () => {
    const {form} = this.formRef.props;

    form.validateFields((err, formData) => {
      if (err) {
        return;
      }

      formData.organizationId = this.props.organizationId;
      this.setState({showLoader: true}, () => {

        if (formData.planType === 'onlinePayment') {
          this.props.assignSubscriptionPlan(formData).then(response => {
            notification.show('info', 'Assign Plan', 'Plan assigned successfully.');
            this.handleCancel();
            this.props.getOrganizationsAndPlans();
          }, err => {
            this.setState({showLoader: false});
          });
        } else {
          formData.amount = formData.amount * 100;

          this.props.subscribeOfflinePaymentPlan(formData).then(response => {
            notification.show('info', 'Subscribe Plan', 'Plan subscribed successfully.');
            this.handleCancel();
            this.props.getOrganizationsAndPlans();
          }, err => {
            this.setState({showLoader: false});
          });
        }
      });

    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  constructor(props) {
    super(props);

    this.state = {
      plans: [],
      visible: false,
      showLoader: false,
      planType: 'onlinePayment'
    }

  };

  componentDidMount() {
  }

  render() {
    return (
      <>
      <Tooltip placement="top" title="Assign Special Subscription Plan">
        <Icon className="green" type="plus-circle" onClick={this.showModal}/>
      </Tooltip>

      <AssociatePlanForm
        wrappedComponentRef={this.saveFormRef}
        visible={this.state.visible}
        onCancel={this.handleCancel}
        onCreate={this.assignSubscriptionPlan}
        plans={this.state.plans}
        planType={this.state.planType}
        planTypeChange={this.planTypeChange}
        associatedPlans={this.props.associatedPlans}
      />

      <GenaLoader showLoader={this.state.showLoader}/>
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSpecialSubscriptionPlans: () => {
      return dispatch(getSpecialSubscriptionPlans()).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    assignSubscriptionPlan: (formData) => {
      return dispatch(assignSubscriptionPlan(formData)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    subscribeOfflinePaymentPlan: (formData) => {
      return dispatch(subscribeOfflinePaymentPlan(formData)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },
  }
};

AssociatePlan = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssociatePlan);

export default AssociatePlan;
import React from 'react';
import {isValidPhoneNumber} from 'react-phone-number-input';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/dist/style.css';
import {getCountryCallingCode} from 'react-phone-number-input/libphonenumber';

class MobileNumber extends React.Component {

  render() {
    return (
      <>
      <div className="ant-col ant-form-item-label">
        <label title="Mobile number" className= { this.props.required ? "ant-form-item-required" : "" }>
          Mobile number
        </label>
      </div>
      <ReactPhoneInput
        defaultCountry='us'
        enableSearchField={true}
        disableAreaCodes={true}
        countryCodeEditable={false}
        value={this.props.phone}
        onChange={(phone, data) => {
          if (phone) {
            let phoneNumber = '+' + phone.replace(/[^0-9]+/g, '');
            let countryCode = getCountryCallingCode(data.countryCode.toUpperCase());
            this.props.updateMobileNumber(phoneNumber, isValidPhoneNumber(phoneNumber) || phoneNumber.replace('+' + countryCode, '').length === 0);
          }
        }}
      />
      </>
    )
  }

}

export default MobileNumber;
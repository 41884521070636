import React, {Component} from 'react';
import './SearchClinicalFeature.css';
import {Button, Col, Icon, Input, Popover, Row} from 'antd';

const {Search} = Input;

const clinicalFeatureHelp = (
  <>
  <div>Example 1. microcephaly</div>
  <div>Example 2. cardiom* (* is wildcard, and stands for any number of characters - but not zero)</div>
  <div>Example 3. talipes AND dandy</div>
  <div>Example 4. seizu* AND microcep*</div>
  <div>Example 5. (delay OR mental retard*) NOT (regression OR deaf OR deafn* OR blind OR blindn*)</div>
  <div>Example 6. (hearing OR deaf OR deafn*) AND (hepati* OR liver) AND cardiomy* AND (delay OR retard*)</div>
  <div>Example 7. ((liver AND dysfunc*) OR (hepat* AND dysfunc*)) AND cardi* AND (delay OR mental retard*)</div>
  <div>Example 8. short stature AND (sensorineur* OR deaf OR deafn*) AND calcific*</div>
  </>
);

class SearchClinicalFeature extends Component {

  clearClinicalFeaturesFilter = () => {
    this.refs.clinicalFeatureSearchFilter.input.state.value = '';
    this.props.clearClinicalFeaturesFilter();
  }

  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {
    return (
      <div className='menu-separator'>
        <span className="mainHeading">Clinical Features</span>
        <Popover placement="topLeft" content={clinicalFeatureHelp} title="Examples for Clinical Feature Search:"
                 trigger="hover">
          <Icon theme="twoTone" className="info-button" type="info-circle"/>
        </Popover>
        <div className='case-dashboard-clinical-features-search-input'>
          <Search placeholder="Search..."
                  onSearch={value => this.props.applyClinicalFeaturesFilter(value)}
                  ref="clinicalFeatureSearchFilter"
                  enterButton/>
          {
            this.props.clinicalFeatureSearchStr !== '' ?
              (<Row className="case-dashboard-clinical-feature-query">
                <Col span={24}>
                    <span>
                      <strong>Search:</strong>&nbsp;
                      {this.props.clinicalFeatureSearchStr}
                    </span>
                  <br/>
                  <Button className="clearClinicalFeaturesFilter" type="primary" size="small"
                          onClick={this.clearClinicalFeaturesFilter}>
                    Clear
                  </Button>
                </Col>
              </Row>) : ''
          }
        </div>
      </div>
    )
  }

}

export default SearchClinicalFeature;
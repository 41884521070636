/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

// Export Constants (Action names)
export const GET_ALL_CASES = 'GET_ALL_CASES';
export const CREATE_CASE = 'CREATE_CASE';
export const UPDATE_CASE = 'UPDATE_CASE';

export function getAllCases(cases) {
  return {
    type: GET_ALL_CASES,
    cases
  };
}

export function createCase(caseValue) {
  return {
    type: CREATE_CASE,
    caseValue
  };
}

export function updateCase(caseValue) {
  return {
    type: UPDATE_CASE,
    caseValue
  };
}
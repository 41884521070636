/**
 * @author Punit Vajpeyi <punit@wemotiveforge.com>
 */

import React from 'react';
import {connect} from 'react-redux';
import {Button, Col, Form, Input, Row} from 'antd';
import {
  getUserPermissionTemplateRequest,
  getUserProfileImage,
  getUserRequest,
  updateUserRequest
} from '../../UserApiActions';
import './UserDetails.css';
import MobileNumber from "../../../../utils/components/MobileNumber/MobileNumber";
import UtilHelper from "../../../../utils/UtilHelper";
import ConstantMessages from '../../../../utils/Strings';
import MessageModal from "../../../../utils/components/MessageModal/MessageModal";


const {Item: FormItem} = Form;

const UpdateUserForm = Form.create()(
  class extends React.Component {

    handleSubmit = e => {
      this.setState({formSubmit: true});
      const {user} = this.props;
      e.preventDefault();
      this.props.form.validateFields((err, formData) => {
        if (!err && this.state.validPhoneNumber) {
          formData.phone = this.state.phone && this.state.phone.length > 4 ? this.state.phone : '';
          this.props.handleUpdateUser(user.id, formData);
        }
      });
    };

    updateMobileNumber = (phone, validPhoneNumber) => {
      this.setState({phone, validPhoneNumber});
    };

    constructor(props) {
      super(props);

      this.state = {
        phone: '',
        formSubmit: false,
        validPhoneNumber: true
      }
    }

    componentWillReceiveProps(nextProps) {
      if (this.props.user) {
        if (this.props.user.phone !== nextProps.user.phone) {
          if (nextProps.user.phone !== null)
            this.setState({phone: nextProps.user.phone, validPhoneNumber: true});
          else
            this.setState({phone: '', validPhoneNumber: true});
        } else if (this.state.phone === '' && this.props.user.phone) {
          if (this.props.user.phone !== null)
            this.setState({phone: this.props.user.phone, validPhoneNumber: true});
          else
            this.setState({phone: '', validPhoneNumber: true});
        }
      }
    }

    render() {
      const {form, user} = this.props;
      const {getFieldDecorator, getFieldError} = form;

      // form fields validation rules
      const fieldsConfig = {
        firstName: {
          rules: [
            {required: true, message: 'Please input first name!'},
            {validator: UtilHelper.validateUserName}
          ]
        },
        lastName: {
          rules: [
            {required: true, message: 'Please input last name!'},
            {validator: UtilHelper.validateUserName}
          ]
        },
        username: {
          rules: [
            {type: 'email', message: ConstantMessages.enterValidEmailAddress},
            {required: true, message: ConstantMessages.emailAddressRequired}
          ]
        }

      };

      if (!this.props.currentRole.isSuperAdmin) {
        fieldsConfig['organization'] = {
          rules: [{required: true, message: ConstantMessages.organizationRequired}]
        }
      }

      return (
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col span={12} className="padding-16">
              <FormItem
                validateStatus={getFieldError('firstName') ? 'error' : ''}
                help={getFieldError('firstName') || ''}
                label="First Name">
                {getFieldDecorator('firstName', {...fieldsConfig.firstName, initialValue: user && user.firstName})(
                  <Input placeholder="Input first name"/>
                )}
              </FormItem>
            </Col>
            <Col span={12} className="padding-16">
              <FormItem
                validateStatus={getFieldError('lastName') ? 'error' : ''}
                help={getFieldError('lastName') || ''}
                label="Last Name">
                {getFieldDecorator('lastName', {...fieldsConfig.lastName, initialValue: user && user.lastName})(
                  <Input placeholder="Input last name"/>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={12} className="padding-16">
              <Form.Item label="Email address">
                {getFieldDecorator('username', {...fieldsConfig.username, initialValue: user && user.username})(
                  <Input placeholder=""/>
                )}
              </Form.Item>
            </Col>
            <Col span={12} className="padding-16 internal-phone-number-container">
              <MobileNumber phone={this.state.phone}
                            updateMobileNumber={this.updateMobileNumber}/>
              {
                this.state.formSubmit && !this.state.validPhoneNumber ?
                  <div className="phone-error">Please enter valid mobile number</div> : ''
              }
            </Col>
          </Row>

          {
            !this.props.currentRole.isSuperAdmin &&
            <Row>
              <Col span={12} className="padding-16">
                <Form.Item label="Organization name">
                  {getFieldDecorator('organization', {
                    ...fieldsConfig.organization,
                    initialValue: user && user.organization && user.organization.name
                  })(
                    <Input placeholder="" disabled={this.props.currentRole.isAnalyst}/>
                  )}
                </Form.Item>
              </Col>
            </Row>
          }

          <Row>
            <Col span={12} className="padding-16">
              <Form.Item>
                <Button type="primary" htmlType="submit">Update</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      );
    }
  }
);

/**
 * Component to show users permission template
 */
class UserDetails extends React.Component {

  /**
   * Method to update user details
   */
  handleUpdateUser = (userId, data) => {
    this.props.updateUserRequest(userId, data).then(() => {
      this.refs.messageModal.setState({
        showModal: true,
        title: ConstantMessages.updateUserNotificationTitle,
        message: ConstantMessages.updateUserSuccessMessage,
        showOkButton: true,
        handleOk: () => {
        },
        okText: 'Ok',
        cancelText: 'Cancel'
      });
    }, (error) => {
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      user: {},
      permissionTemplate: {},
      profileImg: null
    }
  }

  componentDidMount() {
    const {id: userId} = this.props.match.params;

    this.props.getUserRequest(userId).then((response) => {
      this.setState({user: response}, () => {

        this.props.getUserProfileImage(userId).then((res) => {
          this.setState({profileImg: res});
        }, (error) => {
        });

      });
    }, (error) => {
    });

    // // Get user permission template
    // this.props.getUserPermissionTemplate(userId).then((resp) => {
    //   this.setState({permissionTemplate: resp})
    // }, (error) => {
    // });
  }

  render() {
    return (
      <>
      <div className="sivotec-page-content">
        <Row>
          {/*<Col span={6} className="padding-16">*/}
          {/*<UserPermissionList template={this.state.permissionTemplate}/>*/}
          {/*</Col>*/}
          <Col span={6} className="profile-image text-align-center">
            <img src={this.state.profileImg} alt="avatar" className="width-200 height-200 userProfile"/>
          </Col>

          <Col span={18} className="padding-16">
            <UpdateUserForm user={this.state.user} handleUpdateUser={this.handleUpdateUser}
                            currentRole={this.props.currentRole}/>
          </Col>
        </Row>
      </div>

      <MessageModal
        ref="messageModal"
      />

      </>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    resources: state.authorisation.resources,
    loggedInUser: state.auth.loggedInUser,
    currentRole: state.auth.currentRole
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserPermissionTemplate: (userId) => {
      return dispatch(getUserPermissionTemplateRequest(userId)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    getUserRequest: (id) => {
      return dispatch(getUserRequest(id)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    updateUserRequest: (userId, data) => {
      return dispatch(updateUserRequest(userId, data)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    getUserProfileImage: (id) => {
      return dispatch(getUserProfileImage(id, false)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },
  }
};

UserDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetails);

export default UserDetails;

/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */
/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

  // Export Constants (Action names)
export const GET_ALL_RESOURCES = 'GET_ALL_RESOURCES';
export const GET_ALL_ROLES = 'GET_ALL_ROLES';
export const GET_ALL_TEMPLATES = 'GET_ALL_TEMPLATES';
export const UPDATE_ALL_TEMPLATES = 'UPDATE_ALL_TEMPLATES';
export const ADD_DRAFT_TEMPLATE = 'ADD_DRAFT_TEMPLATE';
export const UPDATE_RESOURCE_OF_TEMPLATE = 'UPDATE_RESOURCE_OF_TEMPLATE';
export const UPDATE_TEMPLATE_ID = 'UPDATE_TEMPLATE_ID';
export const GET_PERMISSION_TEMPLATE = 'GET_PERMISSION_TEMPLATE';
export const UPDATE_LOG_IN_USER_RESOURCES = 'UPDATE_LOG_IN_USER_RESOURCES';

export function getAllResources(resources) {
  return {
    type: GET_ALL_RESOURCES,
    resources
  };
}

export function setPermissionTemplate(template) {
  return {
    type: GET_PERMISSION_TEMPLATE,
    template
  }
}

export function getAllRoles(roles) {
  return {
    type: GET_ALL_ROLES,
    roles
  };
}

export function getAllTemplates(templates) {
  return {
    type: GET_ALL_TEMPLATES,
    templates
  };
}

export function updateTemplates(templates) {
  return {
    type: UPDATE_ALL_TEMPLATES,
    templates
  };
}

export function addDraftTemplate(template) {
  return {
    type: ADD_DRAFT_TEMPLATE,
    template
  };
}

export function updateResource(resource, templateId) {
  return {
    type: UPDATE_RESOURCE_OF_TEMPLATE,
    resource,
    templateId
  }
}

export function updateTemplateId(newTemplateId, oldTemplateId) {
  return {
    type: UPDATE_TEMPLATE_ID,
    newTemplateId,
    oldTemplateId
  }
}

export function updateUserResources(resources) {
  return {
    type: UPDATE_LOG_IN_USER_RESOURCES,
    resources
  }
}

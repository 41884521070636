import React from 'react';
import ReactTable from 'react-table';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {Button, Icon, Modal, Row, Tooltip} from 'antd';
import {formatPhoneNumberIntl} from 'react-phone-number-input';

import {
  activateDeactivateUserRequest,
  createUserRequest,
  getAllUsersRequest,
  updateUserRequest
} from '../../UserApiActions';

import AddUser from '../AddUser/AddUser';
import {setPageTitle} from "../../../Dashboard/DashboardActions";

import ChangeUserPassword from "../../../Authentication/components/ChangeUserPassword/ChangeUserPassword";
import './UserTable.css';
import UtilHelper from "../../../../utils/UtilHelper";
import GenaLoader from "../../../../utils/components/GenaLoader/GenaLoader";

/**
 * User table component which shows all users data
 */
class UserTable extends React.Component {

  componentDidMount = () => {
    const {id } = this.props.match.params;

    this.props.getAllUsers(id).then(() => {
    }, (error) => {
    });

    let title = '';

    if(id) {
      title = ' (' + decodeURI(this.props.location.search.substr(1)) + ')';
    }

    this.props.updatePageTitle(this.props.pageTitle + title, false);
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {id } = this.props.match.params;

    if(!this.props.match.params.hasOwnProperty('id') && prevProps.match.params.hasOwnProperty('id')) {
      let title = '';

      if(id) {
        title = ' (' + decodeURI(this.props.location.search.substr(1)) + ')';
      }

      this.props.updatePageTitle(this.props.pageTitle + title, false);

      this.props.getAllUsers(id).then(() => {
      }, (error) => {
      });
    }
  };

  handleAddUser = (user) => {
    return new Promise((resolve, reject) => {
      this.props.createUser(user).then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  };

  handleUpdateUser = (userId, data) => {
    this.props.updateUser(userId, data).then(() => {
    }, (error) => {
    });
  };
  handleActivateUser = (userId) => {
    this.setState({userId, showUserEnableDisableModal: true, userEnabled: true, enableUser: 'Enable'});
  };
  handleDeactivateUser = (userId) => {
    this.setState({userId, showUserEnableDisableModal: true, userEnabled: false, enableUser: 'Disable'});
  };
  activateDeactivateUser = () => {
    this.props.activateDeactivateUser(this.state.userId, this.state.userEnabled).then(() => {
      this.setState({showUserEnableDisableModal: false, userId: ''});
    }, (error) => {
    });

  };
  closeModal = () => {
    this.setState({showUserEnableDisableModal: false, userId: ''});
  };

  constructor(props) {
    super(props);
    this.state = {
      showUserEnableDisableModal: false,
      enableUser: 'Enable',
      userEnabled: true,
      userId: '',
      showLoader: false
    }
  }

  render() {
    const {users, loggedInUser} = this.props;

    const columns = [{
      Header: "#",
      maxWidth: 70,
      filterable: false,
      resizable: false,
      id: 'SrNo',
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      }
    }, {
      Header: 'First Name',
      accessor: 'firstName',
      resizable: false
    }, {
      Header: 'Last Name',
      accessor: 'lastName',
      resizable: false
    }, {
      Header: 'Email',
      accessor: 'email',
      resizable: false
    }, {
      Header: 'Phone',
      accessor: d => formatPhoneNumberIntl(d.phone),
      resizable: false,
      id: 'phone'
    }, {
      Header: 'Role',
      maxWidth: 180,
      filterable: false,
      accessor: 'humanReadableRoles',
      resizable: false
    }, {
      Header: 'Active',
      id: 'active',
      maxWidth: 70,
      filterable: false,
      resizable: false,
      sortable: false,
      Cell: props => (<div className="active-column-cell">
        {
          (props.original.enabled === true) ?
            <span><Icon type="check-circle" className="user-enable"/></span> :
            <span><Icon type="close-circle" className="user-disable"/></span>
        }
      </div>)
    }, {
      Header: 'Action',
      maxWidth: 150,
      filterable: false,
      resizable: false,
      accessor: 'id',
      sortable: false,
      Cell: props => {
        return (
          <div className="action-column-cell">
            {
              props.original.id !== loggedInUser.id ? (
                <>
                <Tooltip placement="top" title="Edit user details">
                  <Button>
                    <Link to={`/users/${props.original.id}/update`}>
                      <Icon className="green" type="edit"/></Link>
                  </Button>
                </Tooltip>
                {
                  props.original.enabled === true ?
                    (<Tooltip placement="top" title="Deactivate user">
                      <Button onClick={() => this.handleDeactivateUser(props.value)}>
                        <Icon className="red" type="lock"/>
                      </Button>
                    </Tooltip>) :
                    (<Tooltip placement="top" title="Activate user">
                      <Button onClick={() => this.handleActivateUser(props.value)}>
                        <Icon className="green" type="unlock"/>
                      </Button>
                    </Tooltip>)
                }
                {
                  this.props.currentRole.isSuperAdmin ?
                    (<ChangeUserPassword
                      userId={props.original.id} />)
                    : ''
                }
                </>
              ) : ''
            }
          </div>
        )
      }
    }];

    if(this.props.currentRole.isSuperAdmin) {
      columns.splice(4, 0, {
        Header: 'Organization',
        accessor: d => d.Organization ? d.Organization.name : '-',
        resizable: false,
        id: 'organizationName'
      });
    }

    return (
      <div className="sivotec-page-content">
        <AddUser handleAddUser={this.handleAddUser}/>
        <Row>
          <ReactTable
            filterable
            defaultFilterMethod={UtilHelper.filterColumnReactTable}
            noDataText='No records present. Click Invite User to create a new user'
            defaultPageSize={10}
            data={users}
            columns={columns}
            className="-striped -highlight"
          />
        </Row>

        <Modal
          visible={this.state.showUserEnableDisableModal}
          title={this.state.enableUser}
          onOk={this.activateDeactivateUser}
          onCancel={this.closeModal}
          footer={[
            <Button key="submit" type="default" onClick={this.closeModal}>
              No
            </Button>,
            <Button key="submit" type="primary" onClick={this.activateDeactivateUser}>
              Yes
            </Button>
          ]}
        >
          <p>Are you sure you want to {this.state.enableUser.toLowerCase()} user?</p>
        </Modal>

        <GenaLoader showLoader={this.state.showLoader} />
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    users: state.user.users,
    loggedInUser: state.auth.loggedInUser,
    currentRole: state.auth.currentRole
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUsers: (id) => {
      return dispatch(getAllUsersRequest(id)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    createUser: (data) => {
      return dispatch(createUserRequest(data)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    updateUser: (userId, data) => {
      return dispatch(updateUserRequest(userId, data)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    activateDeactivateUser: (userId, status) => {
      return dispatch(activateDeactivateUserRequest(userId, status)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    updatePageTitle: (pageTitle, enablePageTitle) => {
      dispatch(setPageTitle(pageTitle, enablePageTitle));
    }
  }
};

UserTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTable);

export default UserTable;
/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import React from 'react';
import {connect} from 'react-redux';
import {Button, Form, Input} from 'antd';

import {updatePasswordRequest} from '../../AuthenticationApiActions';
import {setPageTitle} from "../../../Dashboard/DashboardActions";
import UtilHelper from "../../../../utils/UtilHelper";
import MessageModal from "../../../../utils/components/MessageModal/MessageModal";
import ConstantMessages from "../../../../utils/Strings";
import {ACTIONS} from '../../../../utils/Constants';
import Auth from '../../../../utils/Auth';
import './ChangePassword.css';

const FormItem = Form.Item;

/**
 * Shown to the user when user clicks on the reset password link in the email
 */
class ChangePasswordForm extends React.Component {

  /**
   * Called when component is mount for the first time
   * Setting page title
   */
  componentDidMount = () => {
    this.props.updatePageTitle(this.props.pageTitle, false);
  };
  /**
   * This method is trigger when form is submitted by the user
   * Validates the form and sends the request to server
   * @param e
   */
  handleSubmit = e => {
    e.preventDefault();

    this.setState({submitTrigger: true}, () => {
      this.props.form.validateFields((err, formData) => {
        if (!err) {
          this.props.changePassword(formData.newPassword, formData.currentPassword)
            .then((res) => {
              this.refs.messageModal.setState({
                showModal: true, message: ConstantMessages.passwordChangeMessage,
                title: ConstantMessages.passwordChangeTitle,
                showOkButton: true,
                handleOk: this.navigateToLogin,
                okText: 'Ok'

              });
            }, error => {
            });
        }
      });
    });
  };
  /**
   * Compare the new password is matching
   * @param rule: rule name
   * @param value: value of the input field which is to be validated
   * @param callback: Method to be called after validation is performed
   */
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('Passwords does not match!');
    } else {
      callback();
    }
  };
  /**
   * Forcefully trigger the validation for the confirmPassword field
   * @param rule: rule name
   * @param value: value of the input field which is to be validated
   * @param callback: Method to be called after validation is performed
   */
  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.submitTrigger) {
      form.validateFields(['confirmPassword'], {force: true});
    }
    callback();
  };
  /**
   * Navigate to the previous page
   */
  navigateBack = () => {
    this.props.history.goBack();
  };
  /**
   * Navigate to the login page on successful password change
   */
  navigateToLogin = () => {
    Auth.logout();
    window.location = ACTIONS.SIGN_IN;
  };

  /**
   * Constructor of Change password
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      submitTrigger: false
    };
  }

  /**
   * Default render method to render the UI components and assign initial value to it
   * @returns {XML}
   */
  render() {
    const {getFieldDecorator} = this.props.form;

    const fieldValidationRules = {
      currentPassword: {
        rules: [
          {required: true, message: "Please input your current password"},
          {validator: this.validateToNextPassword},
          {validator: UtilHelper.validateBlankSpaces}
        ]
      },
      newPassword: {
        rules: [
          {required: true, message: "Please input your new password"},
          {validator: this.validateToNextPassword},
          {validator: UtilHelper.validateBlankSpaces},
          {validator: UtilHelper.validatePassword}
        ]
      },
      confirmPassword: {
        rules: [
          {required: true, message: "Please re-enter your new password"},
          {validator: this.compareToFirstPassword},
          {validator: UtilHelper.validateBlankSpaces}
        ]
      }
    };

    return (
      <>
      <div className="sivotec-page-content">
        <div className="password-container">
          <Form onSubmit={this.handleSubmit}>
            <Form.Item label="Current Password">
              {getFieldDecorator('currentPassword', fieldValidationRules.currentPassword)(
                <Input type="password" placeholder="Enter current password"/>
              )}
            </Form.Item>

            <Form.Item label="New Password">
              {getFieldDecorator('newPassword', fieldValidationRules.newPassword)(
                <Input type="password" placeholder="Enter new password"/>
              )}
            </Form.Item>

            <Form.Item label="Re-enter New Password">
              {getFieldDecorator('confirmPassword', fieldValidationRules.confirmPassword)(
                <Input type="password" placeholder="Re-enter new password"/>
              )}
            </Form.Item>

            <FormItem>
              <Button type="primary" htmlType="submit" className="reset-password-form-button">
                Reset Password
              </Button>
              <Button type="primary" htmlType="button" ghost onClick={this.navigateBack}>
                Cancel
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>

      <MessageModal
        ref="messageModal"
      />

      </>
    );
  }
}

let ChangePassword = Form.create()(ChangePasswordForm);

const mapDispatchToProps = (dispatch, ownProps) => {
  return {

    changePassword: (password, currentPassword) => {
      return dispatch(updatePasswordRequest(password, currentPassword)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    updatePageTitle: (pageTitle, enablePageTitle) => {
      dispatch(setPageTitle(pageTitle, enablePageTitle));
    }
  }
};

ChangePassword = connect(
  null,
  mapDispatchToProps
)(ChangePassword);

export default ChangePassword;

import React, {Component} from 'react';
import './SearchHpo.css';
import {connect} from 'react-redux';
import {WithContext as ReactTags} from 'react-tag-input';
import {suggestHpoRequest} from "../../CaseApiActions";
import {Button, Icon, Modal, Popover, Tooltip} from 'antd';
import Notification from "../../../../utils/Notification";

const delimiters = [];
const notification = new Notification();

const hpoSearchHelp = (
  <>
  <div>Search OMIM data for specific hpo hierarchy along with the help of boolean operators like AND, OR</div>
  </>
);

class SearchHpo extends Component {

  /**
   * Show HPO search modal when search button is click
   */
  showModal = () => {

    let tags = this.props.tags ? [...this.props.tags] : [];

    this.setState({visible: true, hpoTags: tags, showErrorMessage: false});
  };
  /**
   * Clear search filter and hide modal
   */
  clearHpoSearch = () => {
    this.setState({searched: false}, () => {
      this.props.clearHpoTags();
    });
  };
  /**
   * Delete the tag from the search tags
   * @param tagIndex
   */
  handleDelete = (tagIndex) => {
    let tags = this.state.hpoTags.slice();

    this.setState({hpoTags: tags.filter((tag, index) => index !== tagIndex)});
  };
  /**
   * Add tag to the tag list
   * @param tag
   */
  handleAddition = (tag) => {
    if (tag.hasOwnProperty('hierarchy')) {
      let tags = this.state.hpoTags.slice();
      tags.push(tag);

      this.setState({hpoTags: tags});
    } else {

      const hpoSuggest = this.props.hpoSuggestions.find(suggestion => suggestion.text.toLowerCase() === tag.text.trim().toLowerCase());

      if (hpoSuggest) {
        let tags = this.state.hpoTags.slice();
        tags.push(hpoSuggest);

        this.setState({hpoTags: tags});

      } else
        notification.show('error', 'HPO Search', 'HPO information you are trying to paste is not associated with the current case');
    }
  };
  /**
   * Add AND operator tag
   */
  addAndTag = () => {
    this.handleAddition({
      text: "AND",
      hierarchy: "AND",
      id: "ADD" + this.state.hpoTags.length
    });
  };
  /**
   * Add OR operator tag
   */
  addOrTag = () => {
    this.handleAddition({
      text: "OR",
      hierarchy: "OR",
      id: "OR" + this.state.hpoTags.length
    });
  };
  /**
   * On cancel button hide modal
   */
  onCancel = () => {
    this.setState({visible: false});
  };
  /**
   * Validate modal and search HPO data
   */
  onSearch = () => {
    if (this.state.hpoTags.length % 2 === 0) {
      this.setState({showErrorMessage: true});
    } else {
      this.setState({showErrorMessage: false, visible: false, searched: true}, () => {
        this.props.handleHpoTagSearch(this.state.hpoTags);
      });
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      showErrorMessage: false,
      searched: false,
      hpoTags: []
    };
  }

  /**
   * Render Search HPO modal on UI
   * @returns {XML}
   */
  render() {
    let suggestions = this.props.hpoSuggestions;
    let placeholder = "Search HPO term";
    const tagsLength = this.state.hpoTags.length;

    return (
      <div className="menu-separator">
        <span className="title mainHeading">HPO Lookup</span>

        <Popover placement="topLeft" content={hpoSearchHelp} title="HPO search:" trigger="hover">
          <Icon
            className="info-button"
            type="info-circle"
            theme="twoTone"/>
        </Popover>
        <Tooltip placement="top" title="Search HPO" trigger="hover">
          <Icon
            className={"search-hpo"}
            type="search"
            onClick={this.showModal}
          />
        </Tooltip>
        {
          this.state.searched ?
            (<>
            <div className="case-dashboard-hpo-query">
              <strong>Search:</strong>&nbsp;
              {this.props.tags.map(tag => tag.text).join(" ")}
            </div>
            <div>
              <Button
                className="clearClinicalFeaturesFilter search-hpo"
                type="primary"
                size="small"
                onClick={this.clearHpoSearch}>
                Clear
              </Button>
            </div>
            </>) : ""
        }

        <Modal
          visible={this.state.visible}
          title="Search HPO"
          okText="Search"
          maskClosable={false}
          onCancel={this.onCancel}
          className={"hpo-search"}
          onOk={this.onSearch}>

          <div className="hpo-suggestion-tags">
            {
              this.state.hpoTags && this.state.hpoTags.map((tag, index) =>
                <div key={tag.id} className="hpo-suggestion-tag">
                  <span>{tag.text}</span>
                  <Icon type="close-circle" onClick={() => this.handleDelete(index)} className="hpo-suggestion-remove"/>
                </div>)
            }
          </div>

          {
            tagsLength !== 0 && tagsLength % 2 !== 0 ? (<div className="hpo-operators">
              <Button type={"primary"} htmlType={"button"} onClick={this.addAndTag}>
                AND
              </Button>
              <Button type={"primary"} htmlType={"button"} onClick={this.addOrTag}>
                OR
              </Button>
            </div>) : (
              <ReactTags
                classNames={{
                  tags: 'tags-container',
                  tag: 'hpo-suggestion-tag',
                  remove: 'hpo-suggestion-remove',
                  tagInputField: 'ant-input hpo-suggest-tag-input',
                }}
                placeholder={placeholder}
                tags={[]}
                suggestions={suggestions}
                handleDelete={this.handleDelete}
                handleAddition={this.handleAddition}
                allowDeleteFromEmptyInput={false}
                allowUnique={false}
                allowDragDrop={false}
                renderSuggestion={({text, hierarchy}, query) => {
                  return (<div>
                    <div title={hierarchy}><b>{text}</b></div>
                  </div>)
                }}
                delimiters={delimiters}/>

            )
          }

          {
            this.state.showErrorMessage ?
              <span className="hpo-error">Please enter a valid HPO search criteria</span> : ""
          }
        </Modal>

      </div>
    )
  }

}


const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {
    suggestHpo: (data) => {
      return dispatch(suggestHpoRequest(data)).then((response) => {
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error);
      });
    }
  }
};

SearchHpo = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchHpo);

export default SearchHpo;
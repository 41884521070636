/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {Button, Form, Input, Modal, Select} from 'antd';
import {getAllRolesRequest} from '../../../Authorisation/AuthorisationApiActions';
import {HUMAN_READABLE_SYSTEM_ROLES, RESOURCES, SYSTEM_ROLES} from '../../../../utils/Constants';
import Notification from '../../../../utils/Notification';
import './AddUser.css';
import UtilHelper from "../../../../utils/UtilHelper";
import GenaLoader from "../../../../utils/components/GenaLoader/GenaLoader";
import ConstantMessages from '../../../../utils/Strings';
import MobileNumber from "../../../../utils/components/MobileNumber/MobileNumber";
import {getCurrentSubscriptionPlan} from "../../../Billing/BillingApiActions";

const {Item: FormItem} = Form;
const {Option} = Select;

const notification = new Notification();

let AddUserForm = Form.create()(class extends React.Component {

    /**
     * This Method executes when user selects role
     */
    onRoleChange = (value) => {
      this.setState({selectedRole: value});
    };

    constructor(props) {
      super(props);
      this.state = {
        selectedRole: undefined
      }
    }

  componentWillReceiveProps(nextProps) {
      if (this.props.visible !== nextProps.visible) {
        const {form} = this.props;
        // Reset form fields
        form.resetFields();
      }
    }

    render() {
      const {selectedRole} = this.state;
      let {visible, onCancel, onCreate, form, roles} = this.props;
      const {getFieldDecorator, getFieldError} = form;

      // Remove ANALYST from roles as super admin can't add analyst user
      if (this.props.currentRole.isSuperAdmin) {
        roles = _.filter(roles, function (o) {
          return (o.name !== SYSTEM_ROLES.ANALYST);
        });
      } else { // Remove roles other than ANALYST as organization manager can only add ANALYST users
        roles = _.filter(roles, function (o) {
          return (o.name === SYSTEM_ROLES.ANALYST);
        });
      }

      // form fields validation rules
      const fieldsConfig = {
        username: {
          rules: [
            {type: 'email', message: 'Please enter a valid email address'},
            {required: true, message: 'Please enter email address'}
          ]
        },
        firstName: {
          rules: [
            {required: true, message: 'Please enter first name'},
            {validator: UtilHelper.validateUserName}
          ]
        },
        lastName: {
          rules: [
            {required: true, message: 'Please enter last name'},
            {validator: UtilHelper.validateUserName}
          ]
        },
        role: {
          rules: [
            {required: true, message: 'Please select role'},
            {validator: UtilHelper.validateBlankSpaces}
          ]
        }
      };

      if(this.state.selectedRole === SYSTEM_ROLES.ORG_MANAGER) {
        fieldsConfig['organization'] = {
          rules: [
            {required: true, message: ConstantMessages.organizationRequired},
            {validator: UtilHelper.validateBlankSpaces}
          ]
        };
      }
      return (
        <Modal
          visible={visible}
          title="Invite New User"
          okText="OK"
          maskClosable={false}
          onCancel={onCancel}
          onOk={onCreate}>
          <Form layout="vertical">

            <FormItem
              validateStatus={getFieldError('firstName') ? 'error' : ''}
              help={getFieldError('firstName') || ''}
              label="First Name">
              {getFieldDecorator('firstName', fieldsConfig.firstName)(
                <Input placeholder="Enter first name"/>
              )}
            </FormItem>

            <FormItem
              validateStatus={getFieldError('lastName') ? 'error' : ''}
              help={getFieldError('lastName') || ''}
              label="Last Name">
              {getFieldDecorator('lastName', fieldsConfig.lastName)(
                <Input placeholder="Enter Last name"/>
              )}
            </FormItem>

            <FormItem
              validateStatus={getFieldError('username') ? 'error' : ''}
              help={getFieldError('username') || ''}
              label="Email Address">
              {getFieldDecorator('username', fieldsConfig.username)(
                <Input type='email' placeholder="Enter email address"/>
              )}
            </FormItem>

            <div className="margin-bottom-10">
              <MobileNumber phone={this.props.phone}
                            updateMobileNumber={this.props.updateMobileNumber}
                            className="sign-up-input"
                            required={true}
              />
              {
                this.props.formSubmit && !this.props.validPhoneNumber ?
                  <div className="phone-error">Please enter valid mobile number</div> : ''
              }
            </div>

            {/* Below field is only visible if selected role is ORG_MANAGER */}

            <FormItem
              validateStatus={getFieldError('role') ? 'error' : ''}
              help={getFieldError('role') || ''}
              label="Role">
              {getFieldDecorator('role', fieldsConfig.role)(
                <Select placeholder="Select a role" onChange={this.onRoleChange}>
                  {
                    roles && roles.map((role) => {
                      return <Option key={role.id} value={role.name}>{HUMAN_READABLE_SYSTEM_ROLES[role.name]}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>

            {
              (selectedRole === SYSTEM_ROLES.ORG_MANAGER) &&
                <FormItem
                  validateStatus={getFieldError('organization') ? 'error' : ''}
                  help={getFieldError('organization') || ''}
                  label="Organization">
                  {getFieldDecorator('organization', fieldsConfig.organization)(
                    <Input placeholder="Enter organization"/>
                  )}
              </FormItem>
            }

          </Form>
        </Modal>
      );
    }
  }
);

class AddUser extends React.Component {

  updateMobileNumber = (phone, validPhoneNumber) => {
    this.setState({phone, validPhoneNumber});
  };

  showModal = () => {
    this.setState({visible: true, phone: ''});
  };

  handleCancel = () => {
    this.setState({visible: false});
  };

  /**
   * Method to add new experiment
   */
  handleAddUser = () => {
    const {form} = this.formRef.props;

    this.setState({formSubmit: true}, () => {

      form.validateFields((err, formData) => {

        if (!err && this.state.validPhoneNumber) {

          this.setState({showLoader: true}, () => {
            formData.phone = this.state.phone;

            this.props.handleAddUser(formData).then(response => {
              notification.show('info', 'User Added', 'User has been added to the system.');
              this.setState({showLoader: false});
              this.handleCancel();
            }, err => {
              this.setState({showLoader: false});
            });

          });

        }

      });
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      showLoader: false,
      formSubmit: false,
      phone: '',
      validPhoneNumber: false,
      singleUserPlan: false
    }

  };

  componentDidMount() {
    this.props.getAllRoles().then(() => {
      if(this.props.currentRole.isOrgManager) {
        this.props.getCurrentSubscriptionPlan().then(res => {
          this.setState({singleUserPlan: !!(res.noOfUsers && res.noOfUsers === 1) }, () => {
          });
        });
      }
    }, (error) => {
    });
  }

  render() {
    const {roles, loggedInUser, userResources} = this.props;

    const resource = userResources.find(resource => resource.name === RESOURCES.USER);

    return (
      <div className="add-user-btn-div">
        {
          resource && resource.CREATE !== null && !this.state.singleUserPlan
            ? (<Button type="primary" icon="plus" onClick={this.showModal}>
              Invite User
            </Button>)
            : (<></>)
        }

        <AddUserForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleAddUser}
          roles={roles}
          loggedInUser={loggedInUser}
          currentRole={this.props.currentRole}
          formSubmit={this.state.formSubmit}
          updateMobileNumber={this.updateMobileNumber}
          phone={this.state.phone}
          validPhoneNumber={this.state.validPhoneNumber}
        />

        <GenaLoader showLoader={this.state.showLoader}/>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    roles: state.authorisation.roles,
    loggedInUser: state.auth.loggedInUser,
    userResources: state.authorisation.userResources,
    currentRole: state.auth.currentRole
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllRoles: () => {
      return dispatch(getAllRolesRequest()).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },
    getCurrentSubscriptionPlan: () => {
      return dispatch(getCurrentSubscriptionPlan()).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },
  }
};

AddUser = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUser);

export default AddUser;

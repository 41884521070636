class ConstantMessages {
  static signupSuccessTitle = 'Sign Up Successful';
  static signupSuccessMessage = 'Thank you. You will be redirected to the login page. Please use the one time password sent to your email address to sign in to the application.';
  static enterValidEmailAddress = 'Please enter a valid email address';
  static emailAddressRequired = 'Please enter email address';
  static firstNameRequired = 'Please enter first name';
  static lastNameRequired = 'Please enter last name';
  static phoneNumberRequired = 'Please enter mobile number';
  static organizationRequired = 'Please enter organization name';
  static redirectionToLoginPage = ' You will be redirected to the login page. Please use the one time password send to your email address to login into the application';

  static usernameRequired = 'Please enter email';
  static passwordRequired = 'Please enter password';

  static passwordChangeTitle = 'Password updated';
  static passwordChangeMessage = 'Password updated successfully. Please login again to continue';

  static updateUserNotificationTitle = 'Update User';
  static updateUserSuccessMessage = 'User details updated successfully';
  static activateUserNotificationTitle = 'Activate user';
  static activateUserSuccessMessage = 'User account is activated successfully';
  static deactivateUserNotificationTitle = 'Deactivate user';
  static deactivateUserSuccessMessage = 'User account is deactivated successfully';
  static imageUploadSuccessful = 'Upload Successful';
  static imageUploadFailed = 'Upload Failed';

  static updateCaseNotificationTitle = 'Update Case';
  static updateCaseSuccessMessage = 'Case details updated successfully';

  static addCaseNotificationTitle = 'Add Case';
  static addCaseSuccessMessage = 'Case details added successfully';

  static getCaseNotificationTitle = "Get Case";
}

export default ConstantMessages;
import React from 'react';
import * as d3 from 'd3';
import './CaseGraph.css';
import {getHpoTermHierarchyForSegment} from "../../CaseApiActions";

class CaseGraph extends React.Component {

  getCaseHpoDetails = (hpoData = '') => {

    const sunburstContainer = document.getElementById("sunburstContainer");
    sunburstContainer.innerHTML = '';

    this.setState({showLoaderForHpoDetails: true}, () => {

      getHpoTermHierarchyForSegment(this.props.caseId, this.props.query).then((response) => {
        let hpoTermHierarchy = {};

        if (response.length > 0)
          hpoTermHierarchy = response[0];

        this.setState({hpoTermHierarchyForSegment: hpoTermHierarchy}, () => {
          this.createSunburst();
        });

      }, () => {
        this.hideHpoLoader();
      });
    });

  };

  constructor(props) {
    super(props);

    this.state = {
      showLoaderForHpoDetails: true,
      hpoTermHierarchyForSegment: {}
    };

    this.hpoTerms = [];
    this.updateSuggestions = true;

  }

  componentDidMount() {
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.updateSunburst)
      this.createSunburst();
  }

  updateSunburstDataFormat(node) {
    let ids = node.value.match(/HP:[0-9]{7}/g);

    let name = node.value;
    let hpoId = 0;

    if (ids && ids.length > 0) {
      hpoId = ids[0];
      name = node.value.replace(hpoId + ':', '');
    }

    let returnNode = {name, hpoId};

    node.hpoHierarchy = node.hasOwnProperty('hpoHierarchy') ? node.hpoHierarchy + " -> " + name : name;

    if (node.hasOwnProperty("pivot")) {
      returnNode.children = [];
      for (let pivotIndex in node.pivot) {
        let pivot = node.pivot[pivotIndex];
        pivot.hpoHierarchy = node.hasOwnProperty('hpoHierarchy') ? node.hpoHierarchy : name;
        returnNode.children.push(this.updateSunburstDataFormat(pivot));
      }
    } else {
      returnNode.size = node.count;
    }

    if (this.updateSuggestions) {
      this.hpoTerms.push({text: name, hierarchy: node.hpoHierarchy, id: name + node.field, name: name, hpoId: hpoId});
      this.hpoTerms.push({
        text: hpoId,
        hierarchy: node.hpoHierarchy,
        id: name + node.field + hpoId,
        name: name,
        hpoId: hpoId
      });
    }

    return returnNode;
  }

  createSunburst() {

    if (this.updateSuggestions)
      this.hpoTerms = [];

    let root = this.state.hpoTermHierarchyForSegment;

    const sunburstContainer = document.getElementById("sunburstContainer");
    const sunburstBreadCrumbs = document.getElementById("sunburstBreadCrumbs");
    sunburstContainer.innerHTML = '';
    sunburstBreadCrumbs.innerHTML = '';

    if (Object.keys(root).length > 0) {

      root = this.updateSunburstDataFormat(this.state.hpoTermHierarchyForSegment);

      this.updateSuggestions = false;

      this.props.updateHpoSuggestions(this.hpoTerms);

      const width = 400,
        height = 400,
        maxRadius = Math.min(width, height) / 2;

      const formatNumber = d3.format(',d');

      const x = d3.scaleLinear()
        .range([0, 2 * Math.PI])
        .clamp(true);

      const y = d3.scaleLinear()
        .range([maxRadius * .1, maxRadius]);

      const color = d3.scaleOrdinal(d3.schemeCategory20b);

      const partition = d3.partition();

      const arc = d3.arc()
        .startAngle(d => x(d.x0))
        .endAngle(d => x(d.x1))
        .innerRadius(d => Math.max(0, y(d.y0)))
        .outerRadius(d => Math.max(0, y(d.y1)));

      const middleArcLine = d => {
        const halfPi = Math.PI / 2;
        const angles = [x(d.x0) - halfPi, x(d.x1) - halfPi];
        const r = Math.max(0, (y(d.y0) + y(d.y1)) / 2);

        const middleAngle = (angles[1] + angles[0]) / 2;
        const invertDirection = middleAngle > 0 && middleAngle < Math.PI; // On lower quadrants write text ccw
        if (invertDirection) {
          angles.reverse();
        }

        const path = d3.path();
        path.arc(0, 0, r, angles[0], angles[1], invertDirection);
        return path.toString();
      };

      const textFits = d => {
        const CHAR_SPACE = 6;

        const deltaAngle = x(d.x1) - x(d.x0);
        const r = Math.max(0, (y(d.y0) + y(d.y1)) / 2);
        const perimeter = r * deltaAngle;

        return d.data.name.length * CHAR_SPACE < perimeter;
      };

      sunburstContainer.innerHTML = '';

      let docFrag = document.createDocumentFragment();
      const svg = d3.select(docFrag).append('svg')
        .attr('width', '400')
        .attr('height', '400')
        .attr('viewBox', `${-width / 2} ${-height / 2} ${width} ${height}`)
        .on('click', () => focusOn()); // Reset zoom on canvas click

      root = d3.hierarchy(root);
      root.sum(d => d.size);

      const slice = svg.selectAll('g.slice')
        .data(partition(root).descendants());

      slice.exit().remove();

      const newSlice = slice.enter()
        .append('g').attr('class', 'slice')
        .on('click', d => {
          d3.event.stopPropagation();
          focusOn(d);
        });

      newSlice.append('title')
        .text(d => d.data.name + '\n' + formatNumber(d.value));

      newSlice.append('path')
        .attr('class', 'main-arc')
        .style('fill', d => color((d.children ? d : d.parent).data.name))
        .attr('d', arc);

      newSlice.append('path')
        .attr('class', 'hidden-arc')
        .attr('id', (_, i) => `hiddenArc${i}`)
        .attr('d', middleArcLine);

      const text = newSlice.append('text')
        .attr('display', d => textFits(d) ? null : 'none');

      // Add white contour
      text.append('textPath')
        .attr('startOffset', '50%')
        .attr('xlink:href', (_, i) => `#hiddenArc${i}`)
        .text(d => d.data.name)
        .style('fill', 'none')
        .style('stroke', '#fff')
        .style('stroke-width', 4)
        .style('stroke-linejoin', 'round');

      text.append('textPath')
        .attr('startOffset', '50%')
        .attr('xlink:href', (_, i) => `#hiddenArc${i}`)
        .text(d => d.data.name);


      const self = this;

      function focusOn(d = {x0: 0, x1: 1, y0: 0, y1: 1}) {
        if (d.x0 === 0 && d.x1 === 1 && d.y0 === 0 && d.y1 === 1) {

        } else {

          sunburstBreadCrumbs.innerHTML = '';

          let node = d;

          do {
            const button = document.createElement('button');
            button.setAttribute('class', 'bread-crumb-button');
            button.innerHTML = node.data.name;

            let nodeValue = {...node};

            button.addEventListener('click', () => {
              focusOn(nodeValue);
            });

            sunburstBreadCrumbs.prepend(button);

            node = node.parent;
          } while (node);

          if (d.data && d.data.name) {
            self.props.getCaseAnalyticsDetails(d.data.name);
          }

          // Reset to top-level if no data point specified
          const transition = svg.transition()
            .duration(750)
            .tween('scale', () => {
              const xd = d3.interpolate(x.domain(), [d.x0, d.x1]),
                yd = d3.interpolate(y.domain(), [d.y0, 1]);
              return t => {
                x.domain(xd(t));
                y.domain(yd(t));
              };
            });

          transition.selectAll('path.main-arc')
            .attrTween('d', d => () => arc(d));

          transition.selectAll('path.hidden-arc')
            .attrTween('d', d => () => middleArcLine(d));

          transition.selectAll('text')
            .attrTween('display', d => () => textFits(d) ? null : 'none');

          moveStackToFront(d);

          function moveStackToFront(elD) {
            svg.selectAll('.slice').filter(d => d === elD)
              .each(function (d) {
                this.parentNode.appendChild(this);
                if (d.parent) {
                  moveStackToFront(d.parent);
                }
              })
          }
        }
      }

      sunburstContainer.append(docFrag);

      this.hideHpoLoader();

    } else {
      this.hideHpoLoader();
      sunburstContainer.innerHTML = '<div style="text-align:center">No data found</div>'
    }

  }

  hideHpoLoader() {
    this.setState({showLoaderForHpoDetails: false});
  }

  render() {
    return (
      <div className="sunburst-container">
        <h3 className="hpo-hierarchy-title">GENA Case Ontology Navigator</h3>
        <div id="sunburstContainer">
          <div className="text-center">No data found</div>
        </div>
        <div id="sunburstBreadCrumbs"></div>

        {
          this.state.showLoaderForHpoDetails
            ? (
              <div className="loader">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            )
            : <></>

        }
      </div>
    )
  }

}

export default CaseGraph;
/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import {notification} from 'antd';

/**
 * To show the notification messages to the user
 */
class Notification {

  /**
   * Method to display a notification popup whenever user performs an action
   */
  show = (type, title, description) => {
    notification[type]({
      message: title,
      duration: this.duration,
      placement: this.placement,
      description: description
    });
  };

  constructor(duration = 3, placement = 'topRight') {
    this.duration = duration;
    this.placement = placement;
  };
};

export default Notification;
/**
 * @author Punit Vajpeyi <punit@wemotiveforge.com>
 */

import React from 'react';
import {Button, Form, Layout, Select, Input, Row, Col} from 'antd';
import {connect} from 'react-redux';
import {Redirect} from "react-router-dom";
import {ROUTE_ACTIONS, ACTIONS} from '../../../../utils/Constants';
import './UserSurvey.css';
import logo from '../../../../assets/logo.png';
import {submitSurvey} from "../../UserApiActions";
import CustomFooter from "../../../Dashboard/components/CustomFooter/CustomFooter";
import GenaLoader from "../../../../utils/components/GenaLoader/GenaLoader";
import UtilHelper from "../../../../utils/UtilHelper";
const {Content, Footer} = Layout;
const FormItem = Form.Item;
const {Option} = Select;

/*
 * Form to update the password of the user.
 * This component is shown only when the current password is temporary password
 */
class UserSurveyForm extends React.Component {

  hideLoader = () => {
    this.setState({showLoader: false});
  };

  /**
   * This method is triggered when form is submitted by the user
   * @param e
   */
  handleSubmitSurvey = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({showLoader: true}, () => {
          this.props.submitSurvey(this.props.loggedInUser.id, values).then(res => {
            this.setState({showLoader: false});
          }, err => {
            this.hideLoader();
          });
        });
      }
    });
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      submitClicked: false,
      showLoader: false
    };
  }

  /**
   * Render the UI of the reset password form
   * @returns {XML}
   */
  render() {
    const {getFieldDecorator} = this.props.form;

    const fieldValidationRulesForSurvey = {
      addr1: {
        rules: [
          {required: true, message: 'Please enter address line 1'},
          {validator: UtilHelper.validateBlankSpaces},

        ]
      },
      addr2: {
        rules: [
          {validator: UtilHelper.validateBlankSpaces},
        ]
      },
      city: {
        rules: [
          {required: true, message: 'Please enter city'},
          {validator: UtilHelper.validateBlankSpaces},
        ]
      },
      state: {
        rules: [
          {required: true, message: 'Please enter state'},
          {validator: UtilHelper.validateBlankSpaces},
        ]
      },
      postalCode: {
        rules: [
          {required: true, message: 'Please enter postal code'},
        ]
      },
      country: {
        rules: [
          {required: true, message: 'Please enter country'},
          {validator: UtilHelper.validateBlankSpaces},
        ]
      },
      primaryUse: {
        rules: [
          {required: true, message: "Please select use for this tool"},
        ]
      },
      primaryRole: {
        rules: [
          {required: true, message: "Please select primary role"},
        ]
      },
      primaryAffiliation: {
        rules: [
          {required: true, message: "Please select primary work affiliation"},
        ]
      },
    };

    if (this.props.loggedInUser && this.props.loggedInUser.isSurveySubmitted) {
      if(this.props.currentRole && !this.props.currentRole.isAnalyst)
        return (<Redirect to={ROUTE_ACTIONS.DASHBOARD}/>);
      else
        return (<Redirect to={ACTIONS.CASES}/>);
    } else {
      return (
        <Layout>
          <Content>
            <div className='survey-question-form-outer-div'>
              <div className='survey-question-form-inner-div'>
                <Form onSubmit={this.handleSubmitSurvey}>

                  <FormItem>
                    <div className='application-logo-img-div'>
                      <img className='application-logo-img' src={logo} alt="Loading..."/>
                    </div>
                  </FormItem>

                  <Row>
                    <Col span={11}>
                      <Form.Item label="Address Line 1">
                        {getFieldDecorator('addr1', fieldValidationRulesForSurvey.addr1)(
                          <Input placeholder="Enter address line 1" maxLength={50}/>
                        )}
                      </Form.Item>
                    </Col>

                    <Col offset={2} span={11}>

                      <Form.Item label="Address Line 2">
                        {getFieldDecorator('addr2', fieldValidationRulesForSurvey.addr2)(
                          <Input placeholder="Enter address line 2" maxLength={50}/>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={11}>

                      <Form.Item label="City">
                        {getFieldDecorator('city', fieldValidationRulesForSurvey.city)(
                          <Input placeholder="Enter city" maxLength={50}/>
                        )}
                      </Form.Item>
                    </Col>

                    <Col span={11} offset={2}>
                      <Form.Item label="State">
                        {getFieldDecorator('state', fieldValidationRulesForSurvey.state)(
                          <Input placeholder="Enter state" maxLength={50}/>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={11}>

                      <Form.Item label="Postal Code">
                        {getFieldDecorator('postalCode', fieldValidationRulesForSurvey.postalCode)(
                          <Input placeholder="Enter postal code" type="number" min={0} className="postal-code" />
                        )}

                      </Form.Item>
                    </Col>

                    <Col span={11} offset={2}>
                      <Form.Item label="Country">
                        {getFieldDecorator('country', fieldValidationRulesForSurvey.country)(
                          <Input placeholder="Enter country" maxLength={50}/>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={11}>

                      <Form.Item label="What is your primary use for this tool?">
                        {getFieldDecorator('primaryUse', fieldValidationRulesForSurvey.primaryUse)(
                          <Select placeholder="Select use for tool">
                            <Option key="Clinical Diagnosis" value="Clinical Diagnosis">Clinical Diagnosis</Option>
                            <Option key="Research" value="Research">Research</Option>
                            <Option key="Academic" value="Academic">Academic</Option>
                            <Option key="Other" value="Other">Other</Option>
                          </Select>
                        )}
                    </Form.Item>
                    </Col>

                    <Col span={11} offset={2}>
                      <Form.Item label="What is your primary role?">
                        {getFieldDecorator('primaryRole', fieldValidationRulesForSurvey.primaryRole)(
                          <Select placeholder="Select primary role">
                            <Option key="Geneticist" value="Geneticist">Geneticist</Option>
                            <Option key="" value="Genetic Counselor">Genetic Counselor</Option>
                            <Option key="Lab Director" value="Lab Director">Lab Director</Option>
                            <Option key="Lab Technologist" value="Lab Technologist">Lab Technologist</Option>
                            <Option key="Other Physician / Healthcare Provider"
                                    value="Other Physician / Healthcare Provider">Other Physician / Healthcare
                              Provider</Option>
                            <Option key="Researcher" value="Researcher">Researcher</Option>
                            <Option key="Medical School Faculty or Student" value="Medical School Faculty or Student">Medical
                              School Faculty or Student</Option>
                            <Option key="Other" value="Other">Other</Option>

                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={11}>

                    <Form.Item label="What is your primary work affiliation?">
                    {getFieldDecorator('primaryAffiliation', fieldValidationRulesForSurvey.primaryAffiliation)(
                      <Select placeholder="Select primary work affiliation">
                        <Option key="Genetics Department" value="Genetics Department">Genetics Department (in a
                          Hospital, Clinic or Health Center)</Option>
                        <Option key="Genetic Lab" value="Genetic Lab">Genetics Lab (in a Hospital, Clinic or Health
                          Center)</Option>
                        <Option key="Independent / Commercial Lab" value="Independent / Commercial Lab">Independent
                          / Commercial Lab</Option>
                        <Option key="Pharmaceutical Company" value="Pharmaceutical Company">Pharmaceutical
                          Company</Option>
                        <Option key="Government or Regulatory Body" value="Government or Regulatory Body">Government
                          or Regulatory Body</Option>
                        <Option key="University / Medical School" value="University / Medical School">University /
                          Medical School</Option>
                        <Option key="Research Institute / Company" value="Research Institute / Company">Research
                          Institute / Company</Option>
                        <Option key="Medical Equipment Manufacturer" value="Medical Equipment Manufacturer">Medical
                          Equipment Manufacturer</Option>
                        <Option key="Other" value="Other">Other</Option>
                      </Select>
                    )}
                  </Form.Item>
                    </Col>

                    <Col span={11} offset={2}>
                      <div className="height-35"></div>
                      <FormItem>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="user-survey-form-button">
                          Save
                        </Button>
                      </FormItem>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>

            <GenaLoader showLoader={this.state.showLoader}/>
          </Content>
          <Footer>
            <CustomFooter/>
          </Footer>
        </Layout>
      )
    }
  }

}

let Survey = Form.create()(UserSurveyForm);

const mapStateToProps = state => {
  return {
    loggedInUser: state.auth.loggedInUser,
    currentRole: state.auth.currentRole
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    submitSurvey: (userId, data) => {
      return dispatch(submitSurvey(userId, data)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    }
  }
};

const UserSurvey = connect(
  mapStateToProps,
  mapDispatchToProps
)(Survey);


export default UserSurvey;

/**
 * @author Punit Vajpeyi <punit@wemotiveforge.com>
 */

import React from 'react';
import {connect} from 'react-redux';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {Button, Layout, Modal} from 'antd';

import './Dashboard.css';
import UserProfile from "../../../User/components/UserProfile/UserProfile";
import UserTable from "../../../User/components/UserTable/UserTable";
import UserDetails from "../../../User/components/UserDetails/UserDetails";
import ChangePassword from "../../../Authentication/components/ChangePassword/ChangePassword";
import CaseTable from '../../../Case/components/CaseTable/CaseTable';
import AddCase from "../../../Case/components/AddCase/AddCase";
import CaseDashboard from "../../../Case/components/CaseDashboard/CaseDashboard";
import {signOut} from '../../../Authentication/AuthenticationActions';
import {ACTIONS, ALLOWED_ACTIONS, ROUTE_ACTIONS} from '../../../../utils/Constants';
import CustomHeader from "../CustomHeader/CustomHeader";
import CustomFooter from "../CustomFooter/CustomFooter";
import Auth from "../../../../utils/Auth";
import {setPageTitle} from "../../DashboardActions";
import {getAllResourcesRequest} from "../../../Authorisation/AuthorisationApiActions";
import {getLoggedInUserPermissionTemplateRequest} from "../../../User/UserApiActions";
import {updateUserResources} from "../../../Authorisation/AuthorisationActions";
import UnAuthorized from "../../../../utils/components/UnAuthorized/UnAuthorized";
import UserDashboard from "../UserDashboard/UserDashboard";
import GenaLoader from "../../../../utils/components/GenaLoader/GenaLoader";
import MessageModal from "../../../../utils/components/MessageModal/MessageModal";
import SubscriptionPlan from "../../../Billing/components/SubscriptedPlans/SubscriptedPlan";
import Plans from "../../../Billing/components/Plans/Plans";
import PaymentHistory from "../../../Billing/components/PaymentHistory/PaymentHistory";
import Organizations from "../../../Billing/components/Organizations/Organizations";

const {Content, Footer, Header} = Layout;

class Dashboard extends React.Component {

  /*
   * Check if the user is idle from last minutes, if so log out the user from the system
   */
  detectNoActivity = () => {
    let IDLE_TIMEOUT = 30; //minutes
    let _idleSecondsCounter = 0;

    const self = this;

    document.onclick = function () {
      _idleSecondsCounter = 0;
    };

    document.onmousemove = function () {
      _idleSecondsCounter = 0;
    };

    document.onkeypress = function () {
      _idleSecondsCounter = 0;
    };

    let interval = window.setInterval(CheckIdleTime, 60000);

    function CheckIdleTime() {

      _idleSecondsCounter++;
      if (_idleSecondsCounter >= IDLE_TIMEOUT) {
        self.setState({noActivityDetected: true});
        clearInterval(interval);
      }
    }

  };
  /**
   * Method executes on every menu click action
   */
  handleMenuClick = (menu) => {
    // If logout menu is clicked then trigger sign out action
    if (menu.key === 'logout') {
      this.logoutUser();
    } else {
      document.getElementsByClassName('ant-menu-submenu-title')[0].click();
      this.menu = menu.key;
    }
  };

  logoutUser = () => {
    this.refs.messageModal.setState({
      showModal: true, message: 'Are you sure you want to logout?', title: 'Log out',
      showOkButton: true,
      showCancelButton: true,
      handleOk: this.props.signOut,
      handleCancel: () => {
      },
      okText: 'Logout',
      cancelText: 'Cancel'
    });
  };

  validatePermissions = () => {
    const {menu} = this.props;
    const {path} = this.props.match;

    let pathAllowed = ALLOWED_ACTIONS.indexOf(menu) >= 0;

    let showLoader = true;

    if (!pathAllowed) {
      const {userResources} = this.props;

      if (!userResources) {
        showLoader = true;
      } else {
        showLoader = false;

        let resource = userResources.find(resource => resource.name === menu);

        if (resource) {
          if (path.indexOf('/add') >= 0 && resource.CREATE !== null) {
            pathAllowed = true;
          } else if (path.indexOf('/:id/update') >= 0 && resource.UPDATE !== null) {
            pathAllowed = true;
          } else if (path.indexOf('/:id/view') >= 0 && resource.READ !== null) {
            pathAllowed = true;
          } else if (path.indexOf('/:id/list') >= 0 && resource.READ !== null) {
            pathAllowed = true;
          } else if (path.indexOf('/:id') < 0 && resource.READ !== null) {
            pathAllowed = true;
          }
        } else {
          showLoader = true;
        }
      }
    } else {
      showLoader = false;
    }

    this.setState({showLoader, pathAllowed});
  };

  constructor(props) {
    super(props);

    this.menu = this.props.menu;

    this.state = {
      noActivityDetected: false,
      showLoader: true,
      pathAllowed: false
    }
  }

  componentDidMount() {
    this.detectNoActivity();
    this.getUserResourcePermission();
  }

  render() {

    const {showLoader, pathAllowed} = this.state;
    const {loggedInUser} = this.props;

    return (
      !loggedInUser
        ? (<Redirect to="/"/>)
        : (
          <>
          <Router>
            <Layout>

              <Header>
                <CustomHeader menu={this.menu} handleMenuClick={this.handleMenuClick}/>
              </Header>

              <Content className="page-content">
                {
                  (showLoader
                      ?
                      (<div></div>)
                      :
                      (pathAllowed
                          ?
                          (
                            <Switch>
                              <Route path={ROUTE_ACTIONS.DASHBOARD} render={(props) => {
                                return <UserDashboard pageTitle='Dashboard'/>
                              }}/>

                              <Route path={ACTIONS.USER_DETAILS} render={(props) => {
                                return <UserDetails {...props} pageTitle='Update User Details'/>
                              }
                              }/>

                              <Route exact path={ROUTE_ACTIONS.USER_LIST} render={(props) => {
                                return <UserTable {...props} pageTitle='Users'/>
                              }
                              }/>

                              <Route exact path={ACTIONS.USER_LIST} render={(props) => {
                                return <UserTable {...props} pageTitle='Users'/>
                              }
                              }/>

                              <Route path={ACTIONS.PROFILE_VIEW} render={(props) => {
                                return <UserProfile {...props} pageTitle='Profile'/>
                              }
                              }/>

                              <Route path={ROUTE_ACTIONS.SUBSCRIPTIONS} render={(props) => {
                                return <SubscriptionPlan {...props} pageTitle='Subscriptions'/>
                              }
                              }/>

                              <Route path={ROUTE_ACTIONS.PLANS} render={(props) => {
                                return <Plans {...props} pageTitle='Subscriptions'/>
                              }
                              }/>

                              <Route path={ROUTE_ACTIONS.PAYMENT_HISTORY} render={(props) => {
                                return <PaymentHistory {...props} pageTitle='Payment History'/>
                              }
                              }/>

                              <Route path={ROUTE_ACTIONS.ORGANIZATIONS} render={(props) => {
                                return <Organizations {...props} pageTitle='Organizations'/>
                              }
                              }/>

                              <Route path={ACTIONS.CHANGE_PASSWORD} render={(props) => {
                                return <ChangePassword {...props} pageTitle='Change Password'/>
                              }
                              }/>

                              {/*<Route path="/permissions" render={(props) => {*/}
                              {/*return <TemplatePermission {...props} pageTitle='Template Permission'/>*/}
                              {/*}*/}
                              {/*}/>*/}

                              <Route path={ROUTE_ACTIONS.CASE_DETAILS_VIEW} render={(props) => {
                                return <CaseDashboard {...props} pageTitle='Case Details'/>
                              }
                              }/>

                              <Route path={ROUTE_ACTIONS.CASE_LIST} render={(props) => {
                                return <CaseTable {...props} pageTitle='Cases'/>
                              }
                              }/>

                              <Route path="/cases/:id" render={(props) => {
                                return <AddCase {...props} pageTitle='Update Case'/>
                              }
                              }/>

                              <Route exact path="/cases" render={(props) => {
                                return <CaseTable {...props} pageTitle='Cases'/>
                              }
                              }/>

                              <Route path="/addCase" render={(props) => {
                                return <AddCase {...props} pageTitle='Add Case'/>
                              }
                              }/>

                            </Switch>
                          )
                          :
                          (<UnAuthorized/>)
                      )
                  )
                }
              </Content>

              <Footer>
                <CustomFooter/>
              </Footer>
            </Layout>
          </Router>

          <Modal
            visible={this.props.showSessionExpiredModal}
            title="Session Expired"
            onOk={this.clearSession}
            closable={false}
            footer={[
              <Button key="submit" type="primary" onClick={this.clearSession}>
                Ok
              </Button>
            ]}>
            <p>{this.props.sessionExpiredError}</p>
          </Modal>

          <Modal
            visible={this.state.noActivityDetected}
            title="No Activity Detected"
            onOk={this.clearSession}
            closable={false}
            footer={[
              <Button key="submit" type="primary" onClick={this.clearSession}>
                Ok
              </Button>
            ]}>
            <p>System has detected no activity in past 30 minutes. Please login again to continue.</p>
          </Modal>

          <MessageModal
            ref="messageModal"/>

          <GenaLoader showLoader={this.state.showLoader}/>
          </>
        )
    );
  }

  getUserResourcePermission() {
    this.props.getAllResources().then(res => {
      if (this.props.loggedInUser) {
        this.props.getUserPermissionTemplate(this.props.loggedInUser.id).then(res => {
          this.generateResourceWisePermission();
        });
      }
    }, error => {
      this.setState({showLoader: false});
    });
  }

  generateResourceWisePermission() {
    const {resources, template} = this.props;

    let userResources = resources.map(resource => {
      let object = {
        name: resource.name,
        id: resource.id,
        CREATE: null,
        READ: null,
        UPDATE: null,
        DELETE: null
      };

      template.ResourceActions.forEach(action => {
        if (action.ResourceId === resource.id) {
          switch (action.type) {
            case "CREATE":
              object.CREATE = action.possession;
              break;

            case "READ":
              object.READ = action.possession;
              break;

            case "UPDATE":
              object.UPDATE = action.possession;
              break;

            case "DELETE":
              object.DELETE = action.possession;
              break;

            default:
              console.log('default');
          }
        }
      });

      return object;

    });

    this.props.updateUserResources(userResources);

    this.validatePermissions();

  }

  clearSession() {
    Auth.logoutAndNavigateToLogin();
  }

}

const mapStateToProps = state => {
  return {
    loggedInUser: state.auth.loggedInUser,
    pageTitle: state.dashboard.pageTitle,
    secondaryTitle: state.dashboard.secondaryTitle,
    showSessionExpiredModal: state.auth.showSessionExpiredModal,
    sessionExpiredError: state.auth.sessionExpiredError,
    resources: state.authorisation.resources,
    template: state.authorisation.template,
    userResources: state.authorisation.userResources
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    signOut: () => {
      dispatch(signOut())
    },

    getAllResources: () => {
      return dispatch(getAllResourcesRequest()).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    getUserPermissionTemplate: (userId = 0) => {
      return dispatch(getLoggedInUserPermissionTemplateRequest(userId)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    updatePageTitle: (pageTitle, enablePageTitle) => {
      dispatch(setPageTitle(pageTitle, enablePageTitle));
    },

    updateUserResources: (userResources) => {
      dispatch(updateUserResources(userResources));
    }
  }
};

Dashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default Dashboard;

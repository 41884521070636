import React from 'react';
import ReactTable from 'react-table';
import {connect} from 'react-redux';
import {Icon, Tag, Tooltip} from 'antd';
import {
  cancelCurrentSubscriptionForOrganization,
  getOrganizationSubscriptionSummary,
  unAssignSubscriptionPlan
} from '../../BillingApiActions';
import {setPageTitle} from "../../../Dashboard/DashboardActions";
import './Organizations.css';
import UtilHelper from "../../../../utils/UtilHelper";
import GenaLoader from "../../../../utils/components/GenaLoader/GenaLoader";
import AssociatePlan from "../AssociatePlanWithOrganization/AssociatePlan";
import MessageModal from "../../../../utils/components/MessageModal/MessageModal";

/**
 * User table component which shows all users data
 */
class Organizations extends React.Component {

  getOrganizationsAndPlans = () => {
    this.setState({showLoader: true}, () => {
      this.props.getAllOrganizations().then(res => {
        this.setState({organizations: res, showLoader: false});
      }, error => {
        this.setState({showLoader: false});
      });

    });
  };

  unAssignSubscriptionPlan = (organizationId, subscriptionPlanId) => {

    this.refs.messageModal.setState({
      showModal: true,
      message: 'Are you sure you want to unassign special subscription plan?',
      title: 'Unassign Special Subscription Plan',
      showOkButton: true,
      showCancelButton: true,
      handleOk: () => {
        this.setState({showLoader: true}, () => {
          this.props.unAssignSubscriptionPlan({organizationId, subscriptionPlanId}).then(res => {
            this.refs.messageModal.setState({
              showModal: true,
              message: 'You have successfully unassigned special subscription plan',
              title: 'Unassign Special Subscription Plan',
              showOkButton: true,
              showCancelButton: false,
              handleOk: () => {
                this.getOrganizationsAndPlans();
              },
              okText: 'Ok'
            });
            this.setState({showLoader: false});
          }, err => {
            this.setState({showLoader: false});
          });
        });
      },
      okText: 'Ok'
    });
  };

  removeCurrentSubscription = (organizationId) => {
    this.refs.messageModal.setState({
      showModal: true,
      message: 'Are you sure you want to cancel current subscription plan?',
      title: 'Cancel Subscription',
      showOkButton: true,
      showCancelButton: true,
      handleOk: () => {
        this.setState({showLoader: true}, () => {
          this.props.cancelCurrentSubscriptionForOrganization(organizationId).then(res => {
            this.refs.messageModal.setState({
              showModal: true,
              message: 'Current plan for the organization has been cancelled.',
              title: 'Cancel Subscription',
              showOkButton: true,
              showCancelButton: false,
              handleOk: () => {
                this.getOrganizationsAndPlans();
              },
              okText: 'Ok'
            });
            this.setState({showLoader: false});
          }, err => {
            this.setState({showLoader: false});
          });
        });
      },
      okText: 'Ok'
    });
  };

  componentDidMount = () => {
    this.getOrganizationsAndPlans();
    this.props.updatePageTitle(this.props.pageTitle, false);
  };

  constructor(props) {
    super(props);

    this.state = {
      organizations: [],
      showLoader: false
    }
  }

  render() {

    const adminColumns = [
      {
        Header: 'Organization',
        accessor: d => d.name,
        resizable: false,
        id: 'organization',
        Cell: row => {
          return <span className={'case-table-case-name'}>
            {row.original.name}
          </span>
        }
      }, {
        Header: 'Current Subscription Plan',
        accessor: d => d.Subscription && d.Subscription.SubscriptionPlan ? d.Subscription.SubscriptionPlan.name : '',
        resizable: false,
        id: 'planName'
      },{
        Header: 'Current Subscription Amount',
        accessor: d => '$' + (d.Subscription ? d.Subscription.offlineAmount ? UtilHelper.numberToCurrency(Number.parseFloat(d.Subscription.offlineAmount)/100): (d.Subscription.SubscriptionPlan.payByPaypalOnly ? UtilHelper.numberToCurrency(Number.parseFloat(d.Subscription.SubscriptionPlan.paypalAmount)/100) : UtilHelper.numberToCurrency(Number.parseFloat(d.Subscription.SubscriptionPlan.amount)/100)) : '-'),
        resizable: false,
        id: 'planAmount'
      }, {
        Header: 'Special Subscription Plans',
        accessor: d => d.SubscriptionPlans.map(sub => sub.name).join(' '),
        resizable: false,
        id: 'SubscriptionPlans',
        Cell: props => {
          return (
            <div>
              {
                props.original.SubscriptionPlans.map(sub => <Tag className="subscription-tag" color="green" key={props.original.id + sub.id}>
                  <span>{sub.name}</span>
                  <Icon type="close-circle" className="remove-subscription-association"
                        onClick={() => this.unAssignSubscriptionPlan(props.original.id, sub.id)}/>
                </Tag>)
              }
            </div>)
        }
      }, {
        Header: 'Action',
        maxWidth: 150,
        filterable: false,
        resizable: false,
        id: 'action',
        sortable: false,
        Cell: props => {
          return (
            <div className="action-column-cell">
              <AssociatePlan organizationId={props.original.id}
                             getOrganizationsAndPlans={this.getOrganizationsAndPlans}
                             associatedPlans={props.original.SubscriptionPlans}
              />
              {
                props.original.Subscription && !props.original.Subscription.SubscriptionPlan.isPromotional
                && !props.original.Subscription.SubscriptionPlan.isDefault
                  ?
                    <Tooltip placement="top" title="Cancel Subscription">
                      <Icon className="red margin-left-20" type="close-circle"
                            onClick={() => this.removeCurrentSubscription(props.original.id)}/>
                    </Tooltip>
                  : <div className="blank-placeholder-inline" />

              }
            </div>
          )
        }
      }
    ];

    return (
      <div className="sivotec-page-content">
        <ReactTable
          filterable
          defaultFilterMethod={UtilHelper.filterColumnReactTable}
          noDataText='No records present'
          defaultPageSize={10}
          data={this.state.organizations}
          columns={adminColumns}
          className="-striped -highlight"
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (column.id === 'organization' && rowInfo) {
                  window.open(`/users/${rowInfo.original.id}/list?${rowInfo.original.name}`, '_blank').focus();
                }
              }

            }

          }}
        />

        <MessageModal ref="messageModal"/>
        <GenaLoader showLoader={this.state.showLoader}/>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    users: state.user.users,
    loggedInUser: state.auth.loggedInUser
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllOrganizations: () => {
      return dispatch(getOrganizationSubscriptionSummary()).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    unAssignSubscriptionPlan: (formData) => {
      return dispatch(unAssignSubscriptionPlan(formData)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    cancelCurrentSubscriptionForOrganization: (formData) => {
      return dispatch(cancelCurrentSubscriptionForOrganization(formData)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    updatePageTitle: (pageTitle, enablePageTitle) => {
      dispatch(setPageTitle(pageTitle, enablePageTitle));
    }
  }
};

Organizations = connect(
  mapStateToProps,
  mapDispatchToProps
)(Organizations);

export default Organizations;

import React from 'react';
import {connect} from 'react-redux';
import ReactTable from 'react-table';
import {getPaymentHistory} from "../../BillingApiActions";
import {setPageTitle} from "../../../Dashboard/DashboardActions";
import UtilHelper from "../../../../utils/UtilHelper";
import './PaymentHistory.css';
import {Tag} from 'antd';

/**
 * User table component which shows all users data
 */
class PaymentHistory extends React.Component {

  changePlanView = e => {
    const viewType = e.target.value;

    this.setState({viewType});
  };

  constructor(props) {
    super(props);

    this.state = {
      history: []
    }
  }

  componentDidMount() {
    this.props.updatePageTitle(this.props.pageTitle, false);

    this.props.getPaymentHistory().then(res => {
      this.setState({history: res});
    }, err => {
    });
  }

  render() {

    const columns = [{
      Header: 'Amount',
      accessor: d => '$' + UtilHelper.numberToCurrency(Number.parseFloat(d.amount) / 100),
      id: 'amount',
      resizable: false
    }, {
      Header: 'Payment Mode',
      accessor: d => d.isOffline ? 'Offline' : 'Online',
      resizable: false,
      id: 'isOffline',
      Cell: props => {
        return props.original.isOffline ? <Tag color="blue">Offline</Tag> : <Tag color="green">Online</Tag>;
      }
    }, {
      Header: 'Description',
      accessor: d => d.isOffline ? 'Offline payment' : (d.isPaidByPaypal ? 'Paypal payment' : d.stripeJson.description),
      resizable: false,
      id: 'description'
    }, {
      Header: 'Status',
      Cell: props => {
        switch (props.original.status) {
          case 'succeeded':
            return (<Tag color="green">Successful</Tag>);

          case 'payment_failed':
            return (<Tag color="red">Failed</Tag>);

          default:
            return (<Tag color="blue">{props.original.status.charAt(0).toUpperCase() + props.original.status.slice(1)}</Tag>)

        }
      },
      accessor: d => {
        switch (d.status) {
          case 'succeeded':
            return 'Successful';

          case 'payment_failed':
            return 'Failed';

          default:
            return `${d.status}`;
        }
      },
      resizable: false,
      id: 'status',

    }, {
      Header: 'Date/Time',
      accessor: d => d.isOffline || d.isPaidByPaypal ? new Date(d.createdAt).toLocaleString('en-us') : new Date(d.stripeJson.created * 1000).toLocaleString('en-us'),
      resizable: false,
      id: 'transactionTime',

    }, {
      Header: 'Transaction Type',
      accessor: d => {
        if (d.paid) {
          return 'Paid';
        } else if (d.refunded) {
          return 'Refund';
        } else {
          return '';
        }
      },
      Cell: props => {
        if (props.original.paid) {
          return (<Tag color="green">Paid</Tag>)
        } else if (props.original.refunded) {
          return (<Tag color="red">Refund</Tag>)
        } else {
          return (<Tag color="red"> </Tag>);
        }
      },
      id: 'paid',
    }];

    if (this.props.currentRole.isSuperAdmin) {
      columns.splice(2, 0, {
        Header: 'Organization',
        accessor: d => d.Organization.name,
        resizable: false,
        id: 'organizationName'
      })
    } else {
      columns.splice(2, 0, {
        Header: 'Created By',
        accessor: d => d.createdByUser ? d.createdByUser : '-',
        resizable: false,
        id: 'createdByUser'
      })
    }

    return (
      <div className="sivotec-page-content">
        <ReactTable
          filterable
          defaultFilterMethod={UtilHelper.filterColumnReactTable}
          defaultPageSize={10}
          noDataText='History not available at the moment'
          data={this.state.history}
          columns={columns}
          className="-striped -highlight"
        />
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    loggedInUser: state.auth.loggedInUser,
    currentRole: state.auth.currentRole
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updatePageTitle: (pageTitle, enablePageTitle) => {
      dispatch(setPageTitle(pageTitle, enablePageTitle));
    },

    getPaymentHistory: () => {
      return dispatch(getPaymentHistory()).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

  }
};

PaymentHistory = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentHistory);


export default PaymentHistory;
import React from 'react';
import {LOCATION_UNITS} from '../../../../utils/Constants';
import './DataSource.css';

/**
 * User table component which shows all users data
 */
class DataSource extends React.Component {

  render() {

    let locationUnit = '';

    const locationUnitObject = LOCATION_UNITS.find((unit) => {
      return unit.id === this.props.locationUnit;
    });

    if (locationUnitObject !== null && locationUnitObject !== undefined) {
      locationUnit = locationUnitObject.name;
    } else {
      locationUnit = this.props.locationUnit;
    }

    return (
      <div className="menu-separator">
        <div className="mainHeading">Data Source</div>
        <ul>
          <li>Coordinate Unit: <b>{locationUnit}</b></li>
          <li>Original Assembly: <b>{this.props.inputAssembly}</b></li>
          <li>Liftover Assembly: <b>{this.props.outputAssembly}</b></li>
        </ul>
      </div>
    )
  }
}

export default DataSource;
import React from 'react';
import ReactTable from 'react-table';
import {connect} from 'react-redux';
import {setPageSecondaryTitle, setPageTitle} from "../../../Dashboard/DashboardActions";
import {Button, Col, Row} from 'antd';
import {checkAnalysisStatus, createVersion, getCaseAnalysisDetails, getCaseRequest} from "../../CaseApiActions";
import DataSource from "../DataSource/DataSource";
import CaseGraph from "../CaseGraph/CaseGraph";
import SearchHpo from "../SearchHpo/SearchHpo";
import UtilHelper from "../../../../utils/UtilHelper";
import GenaLoader from "../../../../utils/components/GenaLoader/GenaLoader";
import SearchClinicalFeature from "../SearchClinicalFeature/SearchClinicalFeature";
import SearchCytogeneticResult from "../SearchCytogeneticResult/SearchCytogeneticResult";
import {SearchResult} from "../SearchResult/SearchResult";
import SelectCaseVersion from "../SelectCaseVersion/SelectCaseVersion";
import Notification from '../../../../utils/Notification';
import {ACTIONS} from '../../../../utils/Constants';
import './CaseDashboard.css';
import SyndromeAnnotations from "../SyndromeAnnotations/SyndromeAnnotations";
import FilterSegment from "../FilterSegment/FilterSegment";
import FilterOmim from "../FilterOmim/FilterOmim";

const notification = new Notification();

const defaultState = {
  genes: {
    facetCounts: {allOmimed: "-", withDisorders: "-", dominant: "-", recessive: "-"},
    response: {
      docs: []
    }
  },
  case: {},
  selectedFragments: [],
  selectedOmim: ['ALL_GENE'],
  showLoaderForAnalyticsDetails: true,
  pages: 0,
  numFound: 0,
  defaultPageSize: 10,
  currentPage: 0,
  numberOfOmimRecords: 0,
  hpoTermHierarchyForSegment: {},
  searchString: '',
  requestData: true,
  filtered: false,
  hpoTags: [],
  hpoSuggestions: [],
  selectedSyndromes: [],
  clinicalFeatureSearchStr: '',
  query: '',
  caseVersionId: '',
  caseVersionAnalysed: false,
  segmentMappings: [],
  inputAssembly: '',
  outputAssembly: '',
  analysisDate: '',
  hpoTerms: ''
};

/**
 * Gene table component
 */
class CaseDashboard extends React.Component {

  getCaseDetails = (caseId) => {
    this.props.getCaseDetails(caseId).then(caseDetails => {

      let fragmentIds = [];

      const highest = caseDetails.versions.reduce((a, b) => a.version > b.version ? a : b);

      const segmentMappings = this.sortSegmentInSequence(highest.SegmentMappings, caseDetails.locationUnit.toUpperCase());

      fragmentIds = segmentMappings.map(segment => segment.id);

      const currentState = {
        ...this.state,
        case: caseDetails,
        caseVersionId: highest.id,
        segmentMappings,
        inputAssembly: highest.inputAssembly,
        outputAssembly: highest.outputAssembly,
        caseVersionAnalysed: highest.isAnalysed,
        selectedFragments: fragmentIds,
        analysisDate: new Date(highest.analysisDate).toLocaleString('en-us')
      };

      this.setState(currentState, () => {
        if (this.state.caseVersionAnalysed)
          this.getCaseAnalysis();
        else {
          this.setState({showLoaderForAnalyticsDetails: false});
        }
      });

      this.updatePageTitle();
    }, error => {
      this.setState({showLoaderForAnalyticsDetails: false});
    });
  };
  updatePageTitle = () => {
    let {case: caseObj} = this.state;
    const pageTitle = `Analysis Date: ${this.state.analysisDate}`;
    const secondaryTitle = `Accession #: ${caseObj.nickName}`;
    this.props.updatePageTitle(pageTitle, true);
    this.props.updateSecondaryPageTitle(secondaryTitle);
  };
  generateQueryStringForDetails = () => {
    const segments = this.state.selectedFragments;
    const omims = this.state.selectedOmim;
    const syndromeAnnotations = this.state.selectedSyndromes;

    let queryString = "&segmentIds=" + JSON.stringify(segments);

    if (syndromeAnnotations.length > 0) {
      queryString += "&syndromeSegments=" + JSON.stringify(syndromeAnnotations);
    }

    let allOmimed = false;

    let withDisorders = false;

    let dominant = false;

    let recessive = false;

    if(omims.indexOf('ALL_GENE') < 0) {
      for (let omim in omims) {
        switch (omims[omim]) {
          case 'ALL_OMIM':
            allOmimed = true;
            break;

          case 'WITH_DISORDERS':
            withDisorders = true;
            break;

          case 'WITH_DOMINANT_INHERITANCE':
            dominant = true;
            break;

          case 'WITH_RECESSIVE_INHERITANCE':
            recessive = true;
            break;
          default:
        }
      }
    }

    queryString += "&allOmimed=" + allOmimed + "&withDisorders=" + withDisorders + "&dominant=" + dominant +
      "&recessive=" + recessive;

    if (this.state.searchString !== '') {
      queryString += "&searchStr=" + encodeURIComponent(this.state.searchString);
    }

    if (this.state.hpoTags.length > 0) {
      let hpoString = '';

      this.state.hpoTags.forEach((tag, index) => {
        hpoString += tag.text === 'AND' || tag.text === 'OR' ? tag.text : '"' + tag.name + '"';

        if (index < this.state.hpoTags.length - 1)
          hpoString += ' ';
      });

      queryString += "&hpoQueryStr=" + hpoString;

    }

    if (this.state.clinicalFeatureSearchStr !== '') {
      queryString += "&clinicalFeatureSearchStr=" + this.state.clinicalFeatureSearchStr;
    }

    return queryString;
  };
  analyticsDetails = (hpoData = '') => {
    this.setState({currentPage: 0, numberOfOmimRecords: 0, requestData: true}, () => {
      this.getCaseAnalyticsDetails(hpoData);
    });
  };
  updateHpoSuggestions = (suggestions) => {
    this.setState({hpoSuggestions: suggestions});
  };
  getCaseAnalyticsDetails = (hpoData = '') => {

    const {defaultPageSize, currentPage, numberOfOmimRecords} = this.state;

    const start = numberOfOmimRecords;

    const rows = (currentPage * defaultPageSize) + defaultPageSize - start;

    if (rows > 0) {
      if (this.state.requestData && (start >= this.state.genes.response.docs.length || start === 0)) {

        let queryString = this.state.query;
        if (hpoData !== '' && hpoData !== 'All') {
          queryString += "&hpoTerm=" + hpoData;

          this.setState({showLoaderForAnalyticsDetails: true, hpoTerms: hpoData });

        } else if(hpoData === 'All') {
          this.setState({showLoaderForAnalyticsDetails: true, hpoTerms: '' });
        } else if(this.state.hpoTerms !== '' && this.state.hpoTerms !== 'All') {
          queryString += "&hpoTerm=" + this.state.hpoTerms;
          this.setState({showLoaderForAnalyticsDetails: true });
        }

        queryString += "&start=" + start + "&rows=" + rows;

        this.props.getCaseAnalysisDetails(this.state.caseVersionId, queryString).then((genes) => {
          let stateGenes = this.state.genes;

          if (start !== 0) {
            stateGenes.response.docs = stateGenes.response.docs.concat(genes.response.docs);
          } else {
            stateGenes = genes;
          }
          const records = stateGenes.response.docs.length;

          const segments = genes.facetCounts && genes.facetCounts.segments;
          stateGenes.facetCounts = genes.facetCounts;
          stateGenes.consanguinityResults = genes.consanguinityResults;
          stateGenes.syndromeAnnotations = genes.syndromeAnnotations;

          let fragments = [...this.state.segmentMappings];

          let selectedSyndromes = [...this.state.selectedSyndromes];

          let syndromes = [];

          if (selectedSyndromes.length > 0 && selectedSyndromes.length > stateGenes.syndromeAnnotations) {
            syndromes = stateGenes.syndromeAnnotations.map(syndrome => {
              return 'chr' + syndrome.chromosome + ':' + syndrome.startCoordinate + '-' + syndrome.endCoordinate;
            })
          } else {
            syndromes = selectedSyndromes;
          }

          fragments.forEach(fragment => {
            fragment.facetCount = 0;
          });

          // 3. Add case genes if genes field is available
          Object.keys(segments).forEach((key, index) => {
            const keyParts = key.split('AND');

            // filter only segments facet count
            if (keyParts.length > 0 && keyParts[0].indexOf('(chromosome') >= 0) {

              const chromosome = keyParts[0].match(/[\d|X|Y]+/)[0];

              let coordinates = keyParts[1].match(/\d+/g);

              const startCoordinate = coordinates[0];
              const endCoordinate = coordinates[1];

              fragments.forEach(fragment => {
                if (fragment.chromosome === chromosome && fragment.liftoverStartCoordinate === startCoordinate && fragment.liftoverEndCoordinate === endCoordinate) {
                  fragment.facetCount = segments[key];
                }
                if (fragment.chromosome === chromosome && fragment.startCoordinate === startCoordinate && fragment.endCoordinate === endCoordinate) {
                  fragment.facetCount = segments[key];
                }
              });

              if (stateGenes.syndromeAnnotations) {
                stateGenes.syndromeAnnotations.forEach(syndrome => {
                  if (syndrome.chromosome === chromosome && syndrome.startCoordinate === Number.parseInt(startCoordinate) && syndrome.endCoordinate === Number.parseInt(endCoordinate)) {
                    syndrome.facetCount = segments[key];
                  }
                })
              }
            }
          });

          stateGenes.response && stateGenes.response.docs && stateGenes.response.docs.forEach((gene) => {
            if (gene.mapLocation)
              gene.symbol = gene.geneSymbol + ' (' + gene.mapLocation + ')';
          });

          const caseVal = {
            ...this.state.case,
            segmentMappings: fragments
          };

          const currentState = {
            ...this.state,
            genes: stateGenes,
            case: caseVal,
            numFound: genes.response['numFound'],
            pages: Math.ceil(genes.response['numFound'] / defaultPageSize),
            showLoaderForAnalyticsDetails: false,
            numberOfOmimRecords: records,
            selectedSyndromes: syndromes
          };

          this.setState(currentState);

        }, (error) => {
          this.setState({showLoaderForAnalyticsDetails: false});
        });

      }
    }
  };
  getCaseAnalysis = (requestHpoData = true) => {
    let queryString = this.generateQueryStringForDetails();

    this.setState({query: queryString}, () => {
      this.getCaseAnalyticsDetails();
      if (this.refs.caseGraph && requestHpoData) {
        this.refs.caseGraph.getCaseHpoDetails();
      }
    });

  };
  clearHpoTags = () => {
    this.setState({hpoTags: [], currentPage: 0, numberOfOmimRecords: 0, requestData: true, hpoTerms: ''}, () => {
      this.getCaseAnalysis();
    });
  };
  handleHpoTagSearch = (tags) => {
    this.setState({currentPage: 0, numberOfOmimRecords: 0, hpoTags: tags, requestData: true, hpoTerms: '' }, () => {
      this.getCaseAnalysis();
    });
  };
  omimIdClicked = (omimId) => {
    window.open(`http://omim.org/entry/${omimId}`, '_blank').focus();
  };
  searchGenesUsingQuery = (query) => {
    if (query.trim().length > 0) {
      this.setState({searchString: query.trim(), currentPage: 0, numberOfOmimRecords: 0, requestData: true, hpoTerms: ''}, () => {
        this.getCaseAnalysis();
      });
    }
  };
  clearSearchFilter = () => {
    this.setState({searchString: '', currentPage: 0, numberOfOmimRecords: 0, requestData: true, hpoTerms: ''}, () => {
      this.getCaseAnalysis();
    });
  };
  applyClinicalFeaturesFilter = (query) => {
    if (query.trim().length > 0) {
      this.setState({clinicalFeatureSearchStr: query.trim(), currentPage: 0, numberOfOmimRecords: 0, requestData: true, hpoTerms: ''}, () => {
        this.getCaseAnalysis();
      });
    }
  };
  clearClinicalFeaturesFilter = () => {
    this.setState({clinicalFeatureSearchStr: '', currentPage: 0, numberOfOmimRecords: 0, requestData: true, hpoTerms: ''}, () => {
      this.getCaseAnalysis();
    });
  };
  updateCaseVersion = (version) => {

    const segmentMappings = this.sortSegmentInSequence(version.SegmentMappings, this.state.case.locationUnit.toUpperCase());

    const selectedFragments = segmentMappings.map(segment => segment.id);

    const analysisDate = new Date(version.analysisDate).toLocaleString('en-us');

    const currentState = {
      ...defaultState,
      caseVersionId: version.id,
      caseVersionAnalysed: version.isAnalysed,
      segmentMappings,
      inputAssembly: version.inputAssembly,
      outputAssembly: version.outputAssembly,
      selectedFragments,
      case: this.state.case,
      analysisDate,
      showLoaderForAnalyticsDetails: false
    };

    this.setState(currentState, () => {
      this.updatePageTitle();
      if (this.state.caseVersionAnalysed)
        this.getCaseAnalysis();
    })
  };
  downloadReport = () => {
    let query = `${process.env.REACT_APP_SERVER_API_URL}${ACTIONS.DOWNLOAD_CASE_ANALYSIS_SPREADSHEET}?caseVersionId=${this.state.caseVersionId}${this.state.query}`;
    if (this.state.hpoTerms !== '' && this.state.hpoTerms !== 'All')
      query += "&hpoTerm=" + this.state.hpoTerms;

    window.open(query, '_blank').focus();
  };

  reAnalyseCase = () => {
    let {id: caseId} = this.props.match.params;

    this.setState({showLoaderForAnalyticsDetails: true}, () => {
      this.props.reAnalyseCase(caseId).then((res) => {

        notification.show('success', 'Reanalyse Case', 'Case reanalysis successful');

        let caseValue = Object.assign({}, this.state.case);
        caseValue.versions.push(res);

        const currentState = Object.assign({}, this.state, {
          case: caseValue,
          showLoaderForAnalyticsDetails: false
        });

        this.setState(currentState, () => {
          this.updateCaseVersion(res);
        });

      }, err => {
        this.setState({showLoaderForAnalyticsDetails: false});
      });
    });

  };
  checkAnalysisStatus = () => {
    this.setState({showLoaderForAnalyticsDetails: true}, () => {
      this.props.checkAnalysisStatus(this.state.caseVersionId).then((res) => {
        this.setState({caseVersionAnalysed: res.status, showLoaderForAnalyticsDetails: false}, () => {
          if (this.state.caseVersionAnalysed)
            this.getCaseAnalysis();
        });
      }, err => {
        this.setState({showLoaderForAnalyticsDetails: false});
      });
    });
  };
  getOmimForSegment = (segments) => {
    this.setState({selectedFragments: segments, currentPage: 0, numberOfOmimRecords: 0, requestData: true, hpoTerms: ''}, () => {
      this.getCaseAnalysis();
    });
  };
  getOmimForOmimFilter = (omims) => {
    this.setState({selectedOmim: omims, currentPage: 0, numberOfOmimRecords: 0, requestData: true, hpoTerms: ''}, () => {
      this.getCaseAnalysis();
    });
  };
  getOmimForSyndrome = (syndromes) => {
    this.setState({selectedSyndromes: syndromes, currentPage: 0, numberOfOmimRecords: 0, hpoTerms: ''}, () => {
      this.getCaseAnalysis();
    });
  };

  sortSegmentInSequence = (segmentMappings, locationUnit) => {

    let multiplicationFactor = 1;

    switch (locationUnit) {

      case 'KB':
        multiplicationFactor = 1000;
        break;

      case 'MB':
        multiplicationFactor = 1000000;
        break;

      default:
        multiplicationFactor = 1;

    }

    segmentMappings = segmentMappings.filter((frag) => {
      frag.start = (parseInt(frag.startCoordinate) * multiplicationFactor).toString();
      frag.end = (parseInt(frag.endCoordinate) * multiplicationFactor).toString();
      return frag;
    });

    // sort segments by chromosome number from 1 to 22 through X and Y
    segmentMappings.sort((a, b) => {
      let chr1 = isNaN(parseInt(a.chromosome)) ? a.chromosome : parseInt(a.chromosome);
      let chr2 = isNaN(parseInt(b.chromosome)) ? b.chromosome : parseInt(b.chromosome);

      if (typeof chr1 === 'number' && typeof chr2 === 'number') {
        if (chr1 === chr2) {
          return (parseInt(a.startCoordinate) - parseInt(b.startCoordinate));
        }
        return chr1 - chr2;
      } else if (typeof chr1 === 'string' && typeof chr2 === 'string') {
        if (chr1 < chr2) //sort string ascending
          return -1;
        if (chr1 > chr2)
          return 1;
        if (chr1 === chr2) {
          return (parseInt(a.startCoordinate) - parseInt(b.startCoordinate));
        }
        // return 0 //default return value (no sorting)
      } else if (typeof chr1 === 'number' && typeof chr2 === 'string') {
        return -1;
      } else if (typeof chr1 === 'string' && typeof chr2 === 'number') {
        return 1;
      }

      return 1;
    });

    return segmentMappings;
  };

  constructor(props) {
    super(props);

    this.state = {...defaultState}
  }

  componentDidMount() {
    let {id: caseId} = this.props.match.params;
    this.getCaseDetails(caseId);
  }

  render() {

    const {genes} = this.state;

    const genesToDisplay = genes.response && genes.response.docs;

    const columns = [{
      Header: "#",
      maxWidth: 50,
      filterable: false,
      Cell: (row) => {
        return <div>{row.index + 1}</div>;
      }
    }, {
      Header: 'Chromosome',
      maxWidth: 100,
      accessor: 'chromosome',
      id: 'chromosome'
    }, {
      Header: 'OMIM ID',
      accessor: 'omimId',
      id: 'omimId',
      filterable: true,
      className: 'case-dashboard-link-text',
      maxWidth: 70
    }, {
      Header: 'OMIM Title',
      accessor: 'omimDesc',
      minWidth: 200
    }, {
      Header: 'Gene Map Disorder',
      id: 'disorders',
      accessor: d => d.disorders && d.disorders.join("\n "),
      Cell: props => (<ol>
        {
          props.original.disorders && props.original.disorders.map(order => {
            let values = order.split(',');
            if (values.length > 0) {
              let lastValue = values[values.length - 1];
              let omimId = lastValue.match(/\d{6}/g);
              if (omimId) {
                let index = lastValue.indexOf(omimId[0]);

                let inheritances = '';

                if(props.original.hasOwnProperty('_childDocuments_')) {
                  const document = props.original['_childDocuments_'].find(document => document.omimId == omimId[0]);

                  if(document && document.inheritances)
                    inheritances = document.inheritances.map(inheritance => inheritance.match(/\b\w/g).join('').toUpperCase()).join(', ');
                }

                return <li key={order}>
                  {values.slice(0, values.length - 1).join(',')},
                  {lastValue.substring(0, index)}
                  {<span className="case-dashboard-link-text"
                         onClick={() => this.omimIdClicked(omimId)}>{omimId}</span>}
                  {lastValue.substring(omimId[0].length + index)}
                  {inheritances !== '' && <span className="green"> [{inheritances}]</span>}
                </li>
              }
            }
            return <li key={order}>{order}</li>
          })
        }
      </ol>),
      filterable: false,
      minWidth: 200
    }, {
      Header: 'Gene Id',
      accessor: 'geneId',
      maxWidth: 100
    }, {
      Header: 'Mapped Genes',
      maxWidth: 200,
      className: 'case-dashboard-link-text',
      accessor: 'symbol',
      id: 'symbol'
    }];

    return (
      <>
      <Row className="case-dashboard-container">
        <Col span={this.props.showSideMenu ? 5 : 0} className="case-dashboard">

          <SelectCaseVersion
            caseVersionId={this.state.caseVersionId}
            versions={this.state.case.versions}
            updateCaseVersion={this.updateCaseVersion}
            caseOwner={this.state.case.createdBy}
            loggedInUser={this.props.loggedInUser.id}
            reAnalyseCase={this.reAnalyseCase}/>

          <DataSource locationUnit={this.state.case.locationUnit}
                      outputAssembly={this.state.outputAssembly}
                      inputAssembly={this.state.inputAssembly}/>

          <SearchHpo tags={this.state.hpoTags} hpoSuggestions={this.state.hpoSuggestions}
                     handleHpoTagSearch={this.handleHpoTagSearch} clearHpoTags={this.clearHpoTags}/>

          <SearchClinicalFeature
            applyClinicalFeaturesFilter={this.applyClinicalFeaturesFilter}
            clearClinicalFeaturesFilter={this.clearClinicalFeaturesFilter}
            clinicalFeatureSearchStr={this.state.clinicalFeatureSearchStr}
          />

          <FilterSegment
            segmentMappings={this.state.segmentMappings}
            inputAssembly={this.state.inputAssembly}
            outputAssembly={this.state.outputAssembly}
            selectedFragments={this.state.selectedFragments}
            getOmimForSegment={this.getOmimForSegment}
          />

          <FilterOmim
            numFound={this.state.numFound}
            facetCounts={genes.facetCounts}
            selectedFragmentsLength={this.state.selectedFragments.length}
            selectedOmim={this.state.selectedOmim}
            getOmimForOmimFilter={this.getOmimForOmimFilter}
          />

          {
            this.state.case && this.state.case.queryType === 'Microdeletions' &&
            (<SyndromeAnnotations
              syndromeAnnotations={this.state.genes.syndromeAnnotations}
              selectedSyndromes={this.state.selectedSyndromes}
              getOmimForSyndrome={this.getOmimForSyndrome}
            />)
          }
        </Col>

        <Col span={this.props.showSideMenu ? 19 : 24} className="dashboard-container">

          {this.state.caseVersionAnalysed
            ?
            (<div>
              <CaseGraph
                ref="caseGraph"
                caseId={this.state.caseVersionId}
                query={this.state.query}
                getCaseAnalyticsDetails={this.analyticsDetails}
                updateHpoSuggestions={this.updateHpoSuggestions}/>

              <div className="wrapper-container">
                {
                  this.state.case && this.state.case.queryType !== 'Microdeletions' &&
                  <div className="margin-bottom-20">
                    <h3>Consanguinity</h3>
                    <Row className="font-weight-600">
                      <Col span={8}>
                        Coefficient of consanguinity
                        (f): {genes.consanguinityResults && genes.consanguinityResults.coefficientOfConsanguinity}
                      </Col>
                      <Col span={8}>
                        Coefficient of inbreeding
                        (F): {genes.consanguinityResults && genes.consanguinityResults.coefficientOfInbreeding}
                      </Col>
                      <Col span={8}>
                        ROH Mb: {genes.consanguinityResults && genes.consanguinityResults.rohLengthInMb}
                      </Col>
                    </Row>
                  </div>
                }

                <h3>Cytogenetic Results</h3>
                <div>
                  <SearchResult
                    className="verticle-margin-10"
                    query={this.state.query}
                    hpoTags={this.state.hpoTags}
                    segmentMappings={this.state.segmentMappings}
                  />

                  <Row className="tableSearch">
                    <Col span={14}>
                      <SearchCytogeneticResult
                        searchGenesUsingQuery={this.searchGenesUsingQuery}
                        searchString={this.state.searchString}
                        clearSearchFilter={this.clearSearchFilter}
                      />
                    </Col>

                    <Col span={4} offset={6} className="text-right">
                      <Button type="primary" className="download-excel"
                              onClick={this.downloadReport}>
                        Export
                      </Button>
                    </Col>
                  </Row>

                </div>

                <ReactTable
                  ref={(r) => {
                    this.cytogeneticResultTable = r;
                  }}
                  data={genesToDisplay}
                  filterable
                  defaultFilterMethod={UtilHelper.filterColumnReactTable}
                  defaultPageSize={this.state.defaultPageSize}
                  loading={this.state.showLoaderForAnalyticsDetails}
                  showPagination={true}
                  showPaginationTop={false}
                  showPaginationBottom={true}
                  minRows={undefined}
                  collapseOnPageChange={true}
                  collapseOnDataChange={true}
                  showPageJump={true}
                  columns={columns}
                  pages={this.state.pages}
                  page={this.state.currentPage}
                  className="-striped -highlight"
                  resizable={false}
                  onPageChange={(page) => {
                    const {currentPage, filtered} = this.state;
                    if (page < currentPage || filtered) {
                      this.setState({currentPage: page, requestData: false});
                    } else {
                      this.setState({currentPage: page, requestData: true}, () => {
                        this.getCaseAnalyticsDetails();
                      });
                    }
                  }}
                  onPageSizeChange={(size) => {

                    const defaultPageSize = this.state.defaultPageSize;
                    const currentPage = this.state.currentPage + 1;

                    let totalRecords = currentPage * defaultPageSize;
                    if (totalRecords < size) {
                      this.setState({defaultPageSize: size, currentPage: 0, requestData: true}, () => {
                        this.getCaseAnalyticsDetails();
                      });
                    } else {
                      this.setState({
                        defaultPageSize: size,
                        currentPage: 0,
                        pages: Math.ceil(this.state.numFound / size),
                        requestData: false
                      });
                    }
                  }}
                  onFilteredChange={(filtered, column) => {

                    if (filtered.length > 0) {
                      const pages = Math.ceil(this.cytogeneticResultTable.getResolvedState().sortedData.length / this.state.defaultPageSize);

                      this.setState({currentPage: 0, requestData: false, filtered: true, pages});
                    } else {

                      this.setState({
                        filtered: false,
                        pages: Math.ceil(this.state.numFound / this.state.defaultPageSize)
                      });
                    }
                  }}
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e, handleOriginal) => {
                        if (column.id === 'omimId' && rowInfo) {
                          this.omimIdClicked(rowInfo.original.omimId);
                        } else if (column.id === 'symbol' && rowInfo) {
                          window.open(`http://www.ncbi.nlm.nih.gov/entrez/query.fcgi?db=gene&cmd=Retrieve&dopt=full_report&list_uids=${rowInfo.original.geneId}`, '_blank').focus();
                        }
                      }
                    }
                  }}
                />
              </div>
            </div>)
            : (<div className="case-dashboard-content-not-available">
              <div className="reload-content">
                <div>Data analysis in progress. Reload to show results.</div>
                <Button type="primary" htmlType={"button"} onClick={this.checkAnalysisStatus}>
                  Reload
                </Button>
              </div>
            </div>)
          }
        </Col>
      </Row>

      <GenaLoader showLoader={this.state.showLoaderForAnalyticsDetails}/>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.user.users,
    loggedInUser: state.auth.loggedInUser,
    showSideMenu: state.dashboard.showSideMenu
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePageTitle: (pageTitle, enablePageTitle) => {
      dispatch(setPageTitle(pageTitle, enablePageTitle));
    },

    updateSecondaryPageTitle: (pageTitle) => {
      dispatch(setPageSecondaryTitle(pageTitle));
    },

    getCaseAnalysisDetails: (caseId, queryString) => {

      return dispatch(getCaseAnalysisDetails(caseId, queryString)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    getCaseDetails: (caseId) => {
      return dispatch(getCaseRequest(caseId)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    reAnalyseCase: (caseId) => {
      return dispatch(createVersion(caseId)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    checkAnalysisStatus: (caseVersionId) => {
      return dispatch(checkAnalysisStatus(caseVersionId)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    }
  }
};

CaseDashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseDashboard);

export default CaseDashboard;
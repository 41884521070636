/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import {PAGE_SECONDARY_TITLE, PAGE_TITLE, SHOW_SIDE_MENU, TOGGLE_SIDE_MENU} from './DashboardActions';
import {SIGN_OUT} from "../Authentication/AuthenticationActions";

const initialState = {pageTitle: null, secondaryTitle: null, showSideMenu: true, enableMenuToggle: false};

/**
 * Reducer to update the state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const DashboardReducer = (state = initialState, action) => {

  switch (action.type) {
    case PAGE_TITLE :
      return Object.assign({}, state, {
        pageTitle: action.pageTitle,
        secondaryTitle: null,
        enableMenuToggle: action.enableMenuToggle,
        showSideMenu: action.enableMenuToggle
      });

    case PAGE_SECONDARY_TITLE :
      return Object.assign({}, state, {
        pageTitle: state.pageTitle,
        secondaryTitle: action.secondaryTitle,
      });

    case TOGGLE_SIDE_MENU:
      return Object.assign({}, state, {
        showSideMenu: !state.showSideMenu
      });

    case SHOW_SIDE_MENU:
      return Object.assign({}, state, {
        showSideMenu: true
      });

    case SIGN_OUT:
      return Object.assign({}, initialState);

    default:
      return state;
  }
};

export default DashboardReducer;

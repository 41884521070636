import React from 'react';
import {Select} from 'antd';
import './SelectCaseVersion.css';

const {Option} = Select;

class SelectCaseVersion extends React.Component {

  handleCaseVersionChange = (value) => {
    const caseVersion = this.props.versions.find(version => {
      return version.id === value;
    });

    this.props.updateCaseVersion(caseVersion);
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedVersion: {}
    };

  }

  render() {
    return (
      <div className="menu-separator">
        {
          this.props.versions && this.props.versions.length > 0
            ?
              <div className='case-version-container'>
                <div className="mainHeading">Case Version</div>
                <div className="case-dashboard-clinical-features-search-input">
                  <Select className="width-100" value={this.props.caseVersionId} onChange={this.handleCaseVersionChange}>
                    {
                      this.props.versions.map((version) => {
                        return <Option key={version.id}> V{version.version}
                          - {new Date(version.analysisDate).toLocaleDateString('en-us')}</Option>
                      })
                    }
                  </Select>
                </div>
                {/*{*/}
                {/*this.props.caseOwner === this.props.loggedInUser ?*/}
                {/*<Button onClick={this.props.reAnalyseCase} type="primary" className="re-analyse-case">*/}
                {/*Re analyse*/}
                {/*</Button>*/}
                {/*: ''*/}
                {/*}*/}
              </div>
            : ''
        }
      </div>
    )
  }

}

export default SelectCaseVersion;
/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import {ACTIVATE_USER, CREATE_USER, DEACTIVATE_USER, GET_ALL_USERS, UPDATE_USER} from './UserActions';
import {SIGN_OUT} from "../Authentication/AuthenticationActions";

const initialState = {
  users: []
};

/**
 * User reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const UserReducer = (state = initialState, action) => {

  switch (action.type) {

    //Get list of users to be shown
    case GET_ALL_USERS:
      return Object.assign({}, state, {
        users: action.users
      });

    //Append newly created user
    case CREATE_USER:
      return Object.assign({}, state, {
        users: [...state.users, action.user]
      });

    //Update user details
    case UPDATE_USER:
      let {users} = state;

      if (users.length === 0) {
        users.push(action.user);
      } else {
        users = users.map((user) => {
          if (user.id === action.user.id) {
            return action.user;
          }
          return user;
        });
      }

      return Object.assign({}, state, {
        users: users
      });

    //Activate user
    case ACTIVATE_USER:
      users = state.users;

      users = users.map((user) => {
        if (user.id === action.user.id) {
          user.enabled = action.user.enabled;
        }
        return user;
      });

      return Object.assign({}, state, {
        users: users
      });

    //Deactivate user
    case DEACTIVATE_USER:
      users = state.users;

      users = users.map((user) => {
        if (user.id === action.user.id) {
          user.enabled = action.user.enabled;
        }
        return user;
      });

      return Object.assign({}, state, {
        users: users
      });

    case SIGN_OUT:
      return Object.assign({}, initialState);

    default:
      return state;
  }
};

export default UserReducer;
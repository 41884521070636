/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import React from 'react';
import {connect} from 'react-redux';
import {Button, Checkbox, Col, Form, Input, Modal, Row, Select} from 'antd';
import {createNewPlan} from "../../BillingApiActions";
import './AddPlan.css';
import UtilHelper from "../../../../utils/UtilHelper";
import GenaLoader from "../../../../utils/components/GenaLoader/GenaLoader";
import MessageModal from "../../../../utils/components/MessageModal/MessageModal";

const {Item: FormItem} = Form;
const {Option} = Select;

let AddPlanForm = Form.create()(class extends React.Component {

  render() {
    let {visible, onCancel, handleAddNewPlan, form} = this.props;
    const {getFieldDecorator, getFieldError} = form;

    const unlimitedCases = this.props.casesCount === 'UNLIMITED';

    // form fields validation rules
    const fieldsConfig = {
      name: {
        rules: [
          {required: true, message: 'Please enter subscription plan name'},
          {validator: UtilHelper.validateBlankSpaces}
        ]
      },
      amount: {
        rules: [
          {required: true, message: 'Please enter subscription plan amount'},
          {validator: UtilHelper.validateNegativeValue}
        ]
      },
      casesCount: {
        rules: [
          {required: true, message: 'Please select subscription plan type'}
        ]
      },
      isSpecial: {
        rules: []
      },
      payByPaypalOnly: {
        rules: [
          {validator: this.validateIfSpecialPlan}
        ]
      }
    };

    if (!unlimitedCases) {
      fieldsConfig['noOfCases'] = {
        rules: [
          {required: true, message: 'Please enter number of cases'},
          {validator: UtilHelper.validateNegativeValue},
          {validator: UtilHelper.validateFloatValue},
        ]
      }
    }

    return (
      <Modal
        visible={visible}
        title="Create Subscription"
        maskClosable={false}
        onCancel={onCancel}
        footer={null}
      >

        <Form>

          <Row>

            <Col span={12} className="padding-16">
              <FormItem
                validateStatus={getFieldError('name') ? 'error' : ''}
                help={getFieldError('name') || ''}
                label="Name">
                {getFieldDecorator('name', fieldsConfig.name)(
                  <Input placeholder="Enter name" type="text" maxLength={50}/>
                )}
              </FormItem>
            </Col>

            <Col span={12} className="padding-16">
              <FormItem
                validateStatus={getFieldError('amount') ? 'error' : ''}
                help={getFieldError('amount') || ''}
                label="Amount">
                {getFieldDecorator('amount', fieldsConfig.amount)(
                  <Input placeholder="Enter amount" type="number" min={0}/>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col span={12} className="padding-16">
              <FormItem
                validateStatus={getFieldError('isSpecial') ? 'error' : ''}
                help={getFieldError('isSpecial') || ''}
                label="Is this a special plan?">
                {getFieldDecorator('isSpecial', fieldsConfig.isSpecial)(
                  <Checkbox checked={this.props.isSpecialPlan} onChange={this.props.specialPlanChange}>
                    Special plan
                  </Checkbox>
                )}
              </FormItem>
            </Col>

            <Col span={12} className="padding-16">
              <FormItem
                label="Duration">
                <Checkbox checked={this.props.subscriptionType === 'month'} onChange={() => this.props.subscriptionTypeChange('month')}>Monthly</Checkbox>
                <Checkbox checked={this.props.subscriptionType === 'year'} onChange={() => this.props.subscriptionTypeChange('year')}>Yearly</Checkbox>
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col span={12} className="padding-16">
              <FormItem
                validateStatus={getFieldError('casesCount') ? 'error' : ''}
                help={getFieldError('casesCount') || ''}
                label="Subscription Plan Type">
                {getFieldDecorator('casesCount', fieldsConfig.casesCount)(
                  <Select placeholder="Subscription Plan Type" onChange={this.props.casesCountChange}>
                    <Option key="unlimited" value="UNLIMITED">Unlimited</Option>
                    <Option key="limited" value="LIMITED">Limited</Option>
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col span={12} className="padding-16">
              {
                !unlimitedCases
                  ?
                  (<FormItem
                    validateStatus={getFieldError('noOfCases') ? 'error' : ''}
                    help={getFieldError('noOfCases') || ''}
                    label="Number of cases">
                    {getFieldDecorator('noOfCases', fieldsConfig.noOfCases)(
                      <Input placeholder="Enter cases count" type="number" min={0}/>
                    )}
                  </FormItem>)
                  : ('')
              }
            </Col>
          </Row>

          <Row>

            <Col span={12} className="padding-16">
              <FormItem
                validateStatus={getFieldError('payByPaypalOnly') ? 'error' : ''}
                help={getFieldError('payByPaypalOnly') || ''}
                label="Is this paypal payment?">
                {getFieldDecorator('payByPaypalOnly', fieldsConfig.payByPaypalOnly)(
                  <Checkbox checked={this.props.payByPaypalOnly} onChange={this.props.payByPaypalChange}>
                    Yes, paypal only
                  </Checkbox>
                )}
              </FormItem>
            </Col>

            <Col span={12} className="padding-16">
              <FormItem
                validateStatus={getFieldError('noOfUsers') ? 'error' : ''}
                help={getFieldError('noOfUsers') || ''}
                label="Is single user plan?">
                {getFieldDecorator('noOfUsers', fieldsConfig.noOfUsers)(
                  <Checkbox checked={this.props.noOfUsers} onChange={this.props.noOfUsersChange}>
                    Yes, Single user only
                  </Checkbox>
                )}
              </FormItem>

            </Col>
          </Row>

          <Row>
            <Col offset={6} span={12} className="padding-16">
              <FormItem>
                <div className="text-right margin-top-35">
                  <Button type="primary" onClick={handleAddNewPlan}>
                    Create Subscription Plan
                  </Button>
                </div>
              </FormItem>
            </Col>
          </Row>
        </Form>

      </Modal>
    );
  }

  /**
   * Check if special is marked for paypal payment
   * @param rule: rule name
   * @param value: value of the input field which is to be validated
   * @param callback: Method to be called after validation is performed
   */
  validateIfSpecialPlan = (rule, value, callback) => {
    const form = this.props.form;
    if (value) {
      if(form.getFieldValue('isSpecial'))
        callback();
      else
        callback('Please select special plan');
    } else {
      callback();
    }
  };

});

class AddPlan extends React.Component {

  casesCountChange = value => {
    this.setState({casesCount: value});
  };

  specialPlanChange = e => {
    this.setState({isSpecialPlan: e.target.checked});
  };

  subscriptionTypeChange = type => {
    this.setState({subscriptionType: type});
  };

  payByPaypalChange = e => {
    this.setState({payByPaypalOnly: e.target.checked });
  };

  noOfUsersChange = e => {
    this.setState({noOfUsers: e.target.checked });
  };

  showModal = () => {
    this.setState({visible: true});
  };

  handleCancel = () => {
    const {form} = this.formRef.props;

    // Reset form fields
    form.resetFields();

    this.setState({visible: false, showLoader: false, casesCount: 'UNLIMITED', isSpecialPlan: false, noOfUsers: false});

  };

  /**
   * Method to add a new segment
   */
  handleAddNewPlan = () => {
    const {form} = this.formRef.props;

    form.validateFields((err, formData) => {
      if (err) {
        return;
      }

      if (formData.casesCount === 'UNLIMITED')
        formData['noOfCases'] = null;

      formData.amount = formData.amount * 100;
      formData.noOfUsers = formData.noOfUsers ? 1 : null;

      const params = Object.assign({}, formData, {
        "interval": this.state.subscriptionType,
        "intervalCount": 1,
        "isActive": true,
        "isDefault": false,
        "isPromotional": false
      });

      this.setState({showLoader: true}, () => {
        this.props.createNewPlan(params).then(res => {

          this.handleCancel();

          this.refs.messageModal.setState({
            showModal: true, message: 'Subscription Plan created successfully.', title: 'Create Subscription',
            showOkButton: true,
            handleOk: () => {
              this.props.refreshPlanList();
            },
            okText: 'Ok'
          });

        }, err => {
          this.setState({showLoader: false});
        });

      })

    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      showLoader: false,
      casesCount: 'UNLIMITED',
      isSpecialPlan: false,
      payByPaypalOnly: false,
      subscriptionType: 'month',
      noOfUsers: false
    }
  };

  render() {
    return (
      <div>
        <Button type="primary" onClick={this.showModal}>Create Subscription Plan</Button>

        <div>
          <AddPlanForm
            ref="addPlan"
            wrappedComponentRef={this.saveFormRef}
            visible={this.state.visible}
            onCancel={this.handleCancel}
            handleAddNewPlan={this.handleAddNewPlan}
            casesCount={this.state.casesCount}
            isSpecialPlan={this.state.isSpecialPlan}
            payByPaypalOnly={this.state.payByPaypalOnly}
            noOfUsers={this.state.noOfUsers}
            subscriptionType={this.state.subscriptionType}
            casesCountChange={this.casesCountChange}
            noOfUsersChange={this.noOfUsersChange}
            specialPlanChange={this.specialPlanChange}
            subscriptionTypeChange={this.subscriptionTypeChange}
            payByPaypalChange={this.payByPaypalChange}
          />
        </div>

        <GenaLoader showLoader={this.state.showLoader}/>

        <MessageModal
          ref="messageModal"/>

      </div>
    );
  };

}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {
    createNewPlan: (formData) => {
      return dispatch(createNewPlan(formData)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },
  }
};

AddPlan = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPlan);

export default AddPlan;

/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import React from 'react';

/**
 * 404 Page not found page
 */
class PageNotFound extends React.Component {
  render() {
    return (
      <div>
        <h1>404 - Page not found</h1>
      </div>
    );
  }
}

export default PageNotFound;

import React from 'react';
import './PrivacyPolicy.css';

export default function PrivacyPolicy(props) {
  return (<div className="privacy-policy-container">
    <p><b>SIVOTEC BioInformatics Privacy Policy</b></p>

    <p>Last modified on July 3, 2019</p>
    <p>Version Control 1.001</p>

    <p><b>Our Privacy Policy Introduction</b></p>

    <p>This document represents SIVOTEC BioInformatics’ commitment to our clients and users, ensuring by every means
      possible the protection and privacy of the information you provide to us. This information covers multiple
      different sources, such as personal information and genomic-related data and metadata, that has been entered in
      our software tools by you when using our suite of products and services.</p>

    <p>This Privacy Policy describes in detail all the policies and procedures that SIVOTEC BioInformatics, LLC. and it
      subsidiaries and affiliated entities (hereafter, “Company”, “we” or “us”) will apply to data received by us with
      respect to the collection, use and disclosure of personal, genomic and other information from users of
      www.GENA.tech or the corresponding sites on social media outlets or through corresponding applications on mobile
      devices (collectively, the “Site”) and from users of our products and services including the online
      software-as-a-service bioinformatics solutions, which includes GENA along with associated software tools,
      databases and services (collectively, the “SIVOGEN Platform”), forums, databases, websites and applications
      offered or made available by Company (collectively, together with the Site and the “Services”).</p>

    <p>All references herein to “you” and “your” include you as the individual user and, to the extent relating to your
      use of the Site or other Services, any company, institution or other entity employing, contracting or retaining
      you, or on whose behalf you are using the Services.</p>

    <p>By agreeing to use or accessing the Site and the SIVOGEN Platform or other Services, or by submitting information
      to Company, you consent to this Privacy Policy. The aforementioned Privacy Policy applies only to information that
      is provided to the Company through the Services and not to information we collect by other means or from other
      sources. This Privacy Policy may be updated from time to time. The Company will provide notice of any changes by
      posting the new Privacy Policy on the Site. You are advised to consult this Privacy Policy regularly for any
      changes.</p>

    <p><b>The Information That We Collect</b></p>

    <p>We collect, receive and store the following types of information:</p>

    <ul>
      <li><p>Personally Identifying Information: We collect Personally Identifying Information(“P.I.I.”) from the Site
        ONLY when you choose to provide it to us. P.I.I. is information that can be used to identify or contact you,
        including but not limited to your name, address, email address, telephone number, company or institution
        affiliation, account identifiers, account password, payment information, credit card information and other
        billing data. In particular, we collect, receive and store the P.I.I. you provide to Company when you trial or
        purchase Services or sign-up or register with Company on the Site and/or for Services, including without
        limitation when you sign up for GENA or open any related account or membership, when you subscribe to a
        newsletter, respond to a survey, fill out a form, or complete your profile through the Site or Services.</p>

        <p>Certain contact and other P.I.I. may be necessary to use, sign up or register for Services, utilize certain
          features or functionality on the Site, or otherwise transact business with us. You can choose not to provide
          such information, but then you might not be able to use or take advantage of our Services, Site, business
          transactions or portions thereof.</p></li>

      <li><p>User Content: User Content is information, data, text, files, software, graphics, audio, photographs,
        video, messages, communications or other materials, including Genomic Data, that you upload, submit, post or
        otherwise provide to the Site or Services. For example, we receive and store User Content you provide to Company
        when you: use the Site, the SIVOGEN Platform, GENA or other Services; and/or post, upload or otherwise provide
        content, materials, reviews or other information on the Site or through the other Services; and/or fill out
        surveys or questionnaires or sign up for or enter promotions, events or other special activities through the
        Site or other Services; and/or communicate with Company or with other users or participants of the Site or other
        Services (including the content of such communications and information provided in connection with such
        communications); and/or provide information to third parties and their web sites, applications and services
        which are accessed or used by Company or through the Site and/or Services.</p></li>

      <li><p>Information We Collect Automatically: We receive and store certain types of information whenever you
        interact with us. For example, we may automatically record your activity on the Site and in the Services, the
        time and date or your activities, your IP address, browser type, page views, domains and similar information.
        Like many websites, we may also use “cookies” (see below), log files, web beacon technologies and other
        automated tools to obtain certain types of information when your web browser or application accesses our
        Services. We typically use this information for internal purposes such as to administer, develop, improve and
        customize the Site and the Services, to understand and analyze how the Site and Services are being used and to
        track, analyze and report on aggregate usage. We may also use this information to personalize your experience,
        or to offer you products and services specifically based on your preferences and usage.</p></li>

      <li><p>Genomic Data: Genomic Data consist of genomic sequences, exomes, variant files, specific DNA or RNA
        sequences, gene sets and variant sets, as well as associated sample information, annotations, demographic
        descriptions (e.g., age, gender, ethnicity, background, etc.), phenotypic descriptions (e.g., disease
        conditions, health-related information, personal traits, family history) and other data that might be uploaded
        by you in connection with any such sequence data. We collect Genomic Data ONLY if you choose to provide it to us
        in connection with your use of our products or services.</p></li>
    </ul>

    <p>If and when we receive or collect your Personal Data from the European Union or European Economic Area (“EU/EEA”)
      we will comply with any GDPR laws, rules and regulations that apply to us with respect to such Personal Data and
      you as a “Data Subject” under the current GDPR. Accordingly, we will process such Personal Data for the
      performance of any agreement between you and the Company such as to provide Services for the purposes of our
      legitimate interests related to the customer and business relationships between the Company and Data Subjects to
      comply with legal obligations applicable to us, including legal corporate and accounting obligations.</p>

    <p>Except with respect to Services we offer that are expressly indicated to be compliant with the Health Insurance
      Portability and Accountability Act (“HIPAA”), we do not purposefully collect, and request that you not provide,
      any individually identifiable health information and disclaim all responsibility or liability with respect to any
      such information provided to Company. With respect to any Services which are expressly indicated to be
      HIPAA-compliant, Company may receive such individually identifiable health information in compliance with
      applicable laws and regulations.</p>

    <p><b>The Purpose of Using Your Information</b></p>

    <p>Our primary goal in receiving information from you is to provide, administer, develop and improve our Services.
      Accordingly, Company may use the information submitted, collected or received from and/or about you for any of the
      following purposes:</p>

    <ul>
      <li><p>To operate, provide, administer, develop, and improve our Services (including without limitation the Site
        and the SIVOGEN Platform and GENA), and to operate and support Company’s related businesses.</p></li>

      <li><p>To better understand how users access and use our Services on an aggregated and individualized basis, to
        track and monitor usage, to conduct quality control, to fix technical and other errors or problems, and to
        respond to user desires and preferences.</p></li>

      <li><p>To analyze, compile and publish, on an aggregated basis, information regarding usage of the Services and
        related subjects of interest to the general public, to analyze, compile and publish aggregated data and
        statistics regarding genomic analysis, and to enhance annotations and database information within (and otherwise
        used for) the Services such as the SIVOGEN Platform, GENA and other genomic analysis products or tools of
        Company (excluding in any event any disclosure or publication of Personal Identifying Information in such
        cases).</p></li>

      <li><p>To provide personalized experiences and recommendations, language and location customization, personalized
        help and instructions, or other responses to your usage of our Services.</p></li>

      <li><p>To track memberships, purchases and usage as necessary for the purpose of our complying with any
        third-party agreements or obligations (such as, for example, making royalty or other payments to third parties).
        However, we will use reasonable efforts not to transfer P.I.I. to such third parties, unless it is strictly
        required for such compliance.</p></li>

      <li><p>To communicate with or contact you concerning your account or membership or your usage of or participation
        in the Services, and for other customer service, this will include use of your e-mail address to send you
        messages and notices for the purposes described above and below.</p></li>

      <li><p>To bill you, process payments and for authorization, account history and billing purposes. This may include
        credit card authorization and verification for purchases made from Company, location verification for products
        and services which may have limited geographical access, and the like.</p></li>

      <li><p>To offer you content, services, or other products and services, including to develop new services and
        products.</p></li>

      <li><p>To provide you with news and newsletters, special offers, promotions, and targeted advertising.</p></li>

      <li><p>To comply with applicable laws, rules and regulations and any regulatory mandate or court order.</p></li>

      <li><p>To protect the safety of any person, to address fraud, security or technical issues, or to protect
        Company’s rights or property.</p></li>

    </ul>


    <p>For advertising, marketing and promotional purposes, however, we do not sell or rent your P.I.I. to any third
      party.</p>

    <p>As used in this policy, the terms “using” and “processing” information include using cookies on a computer,
      subjecting the information to statistical or other analysis and using or handling information in any way,
      including, but not limited to collecting, storing, evaluating, modifying, deleting, using, combining, disclosing
      and transferring information within our organization or among our affiliates within the United States or
      internationally.</p>

    <p><b>The Information That May be Shared or Disclosed to Others or the Public</b></p>

    <p>Information provided by our users is an important part of our business. Company will share and disclose user
      provided or collected information only as compatible with the purposes described above, when we have your consent
      (as may be provided by you from time to time through the Services or otherwise) and as described in this section
      as follows:</p>

    <ul>

      <li><p>For Compliance with Law and Protection of Company and Others: We reserve the right to disclose your P.I.I.
        and any other information when we believe release is appropriate to comply with the law, judicial proceeding,
        court order, subpoena or other legal process; detect, prevent, or otherwise address fraud, security or technical
        issues; enforce or apply our Terms of Use and other agreements; or protect the rights, property, or safety of
        Company, our employees and contractors, our users, or others. This includes exchanging information with other
        companies and organizations for fraud protection and credit risk reduction purposes. This does not include
        selling, renting, sharing, or otherwise disclosing P.I.I. from customers for commercial purposes in violation of
        this Privacy Policy.</p></li>

      <li><p>Company Affiliates: We may share your information with our subsidiaries and other affiliates, but only if
        those entities are either subject to this Privacy Policy or follow practices at least as protective as those
        described in this Privacy Policy.</p></li>

      <li><p>In Public Forums: Any User Content or other user information, content or materials submitted or posted to
        public portions of the Site (such as discussion groups, bulletin boards and similar forums) or portions of the
        Service designed for broad user access (such as reviews, focused user groups, etcetera) are or may be shared
        with the public without restriction. Company also reserves the right to publish or make publicly available any
        information that is already publicly available prior to the time first provided to or collected by Company or
        information that becomes publicly available without any action or omission on the part of Company.</p></li>

      <li><p>Contractors and Agents: We may employ other companies and individuals to perform functions or otherwise act
        on our behalf. Examples include processing payments, resolving service problems, correcting errors related to
        the execution of the Services, sending postal mail and e-mail, removing repetitive information from customer
        lists, analyzing data, providing marketing assistance (including data concerning marketing and promotional
        programs), hosting websites or software, developing websites and software, providing customer service, assisting
        Company with providing, administering, developing or improving the Services, and/or for other purposes within
        the ordinary course of business. These third parties have access to the P.I.I. and other information needed to
        perform their functions on our behalf. We use reasonable efforts to require these companies to (1) provide
        adequate protections for your P.I.I. that are no less protective than those set out in this Privacy Policy and
        (2) use your P.I.I. only for the purposes for which the third party has been engaged by us. We are not liable
        for the acts or omissions of these third parties, except as provided by applicable law.</p></li>

      <li><p>Aggregated Data and Annotations: Company may publish and otherwise disclose (a) on an aggregated basis,
        information regarding usage of the Services and related subjects of interest to the public, (b) aggregated data
        and statistics regarding genomic analysis (excluding in any event any Personal identifying Information in both
        cases). Company may also disclose within the applicable Services themselves (such as the SIVOGEN Platform, GENA
        and other genomic analysis products or tools of Company) enhanced annotations and database information derived
        from your and other users’ usage of the Services.</p></li>

      <li><p>Business Transfers and Collaborations: As we continue to develop our business, we might sell the Company
        and/or its business or assets and/or sell or buy online sites, services, subsidiaries, or other businesses; or
        we might collaborate or partner with other companies in strategic transactions or licenses. In such
        transactions, customer and user information generally would be one of the transferred business assets or
        otherwise one of the components of, or involved in, the transaction, we may therefore share this information in
        connection with such a transaction.</p></li>
    </ul>

    <p><b>How to Change or Delete Your Information</b></p>

    <p>All users who have signed up or registered on the Site or for any of the Services may review, update, correct or
      delete the P.I.I. in their registration or membership profile by changing the applicable information in their
      applicable account settings and/or profile page and/or by formally contacting the privacy contact listed below or
      your account representative at the Company in writing. If you delete some or all such information, then your
      account may become deactivated.</p>

    <p>We endeavor to comply with our obligations under the GDPR in relation to the storage, deletion, access and
      modification of your P.I.I. We particularly respect your rights as a Data Subject under the GDPR to:</p>

    <ul>
      <li><p>access, modify or delete your Personal Data;</p></li>
      <li><p>restrict our processing of your Personal Data or to object to certain processing of your Personal Data;
        and</p></li>
      <li><p>to receive, under certain preconditions, your Personal Data in a structured, commonly used and
        machine-readable format and to transmit such data to another controller.</p></li>
    </ul>

    <p>You may exercise these rights by updating your registration or membership profile or sending us a written notice
      in accordance with the GDPR.</p>

    <p>If for any reason you choose and would like us to delete your P.I.I. across our system, please contact us as
      described below and we will make good faith efforts to accommodate your request, unless we have a legal obligation
      to retain the record or legitimate business need to retain such information. In such cases, we ask users to
      provide proof of identity of themselves and the information requested to be accessed, corrected or removed before
      processing such requests. Except as required to comply with GDPR or other applicable laws, we may decline to
      process requests because of the reasons stated above or because such requests are unreasonably repetitive or
      systematic, require disproportionate technical effort, relate to information that is incorporated into other
      databases or Services as permitted by this Privacy Policy, jeopardize the privacy of others, or would be
      impractical (for instance, requests concerning information residing on backup tapes or derivative information that
      is not separable), or for which access is not otherwise required. Because of the way we maintain certain Services,
      after you delete your information, residual copies may take some time before they are deleted from our active
      servers and may remain in our backup systems.</p>

    <p><b>Using Computer Cookies and Other Files</b></p>

    <p>Like many websites, we may use “cookies” to collect information. A cookie is a file contains an identifier
      composed of a string of letters and numbers that is sent by a web server to a web browser and is stored by the
      browser. The identifier is then sent back to the server each time the browser requests a page from the server. We
      may use cookies to improve the quality of the Services, including for storing user preferences and tracking user
      trends. We may utilize persistent cookies to save your registration/member ID and login password for future logins
      to the Services. We may utilize session ID cookies to enable certain features of the Services, to better
      understand how you interact with the Services and to monitor aggregate usage by Company users and web traffic
      routing on the Services. Unlike persistent cookies, session cookies are usually deleted from your computer when
      you log off from the Services and then close your browser. Third party advertisers on the Services may also place
      or read cookies on your browser. You can instruct your browser, by changing its options, to stop accepting cookies
      or to prompt you before accepting a cookie from the websites you visit. If you do not accept cookies, however, you
      may not be able to use all portions or functionalities of the Services.</p>

    <p>Log file information is automatically reported by your browser each time you access a web page. When you use the
      Services, our servers may record certain information that your web browser sends whenever you visit any website.
      These server logs may include information such as your web request, Internet Protocol (“IP”) address, browser
      type, referring / exit pages and URLs, number of clicks, domain names, landing pages, pages viewed, and other such
      information. When you use the Services, we may employ clear GIFs (also known as web beacons) or similar mechanisms
      which are used to track the online usage patterns of our users anonymously. No P.I.I. from your Company account is
      collected using these clear GIFs. In addition, we may also use clear GIFs in HTML-based emails sent to our users
      to track which emails are opened by recipients. The information is used to enable more accurate reporting and make
      the Services better for our users.</p>

    <p><b>About Identity Theft and Abuse</b></p>

    <p>Identity theft and the practice currently known as “phishing” are a concern of ours and our users, so we take it
      seriously. Safeguarding information to help protect you from identity theft is important to us. We do not and will
      not, at any time, request your credit card information, your account ID, login password, or national
      identification numbers in a non-secure or unsolicited e-mail or telephone communication. For more information
      about phishing, visit the Federal Trade Commission’s website.</p>

    <p><b>About Our Data Security</b></p>

    <p>We are very concerned with safeguarding your – and our – information and protecting the security of your P.I.I.
      and Genomic Data. We use appropriate, industry-typical security measures and technology to protect against
      unauthorized access to, or unauthorized alteration, disclosure or destruction of, user information covered by this
      Privacy Policy (excluding any such data that may be publicly shared in accordance with this Privacy Policy). In
      order to achieve this, we use electronic measures designed to protect your P.I.I. from unauthorized access. We
      will make any legally required disclosures of any breach of the security, confidentiality, or integrity of your
      unencrypted electronically stored “personal data” (as defined in applicable statutes on security breach
      notification) to you via email or conspicuous posting on the Site or Services in the most expedient time possible
      and without unreasonable delay, insofar as these are consistent with (i) the legitimate needs of law enforcement
      or (ii) any measures necessary to determine the scope of the breach and restore the reasonable integrity of the
      data system.</p>

    <p><b>International Transfer</b></p>

    <p>It is possible that, from time to time, your Information and its derivatives may be transferred to – and
      maintained on – computers located in the United States of America as well as other locations and jurisdictions
      where we conduct business, which may be outside of your state, province, country or other governmental
      jurisdiction where the privacy laws may not be the same as those in your jurisdiction. If you are located outside
      the United States and choose to provide information to us, Company transfers P.I.I. to the United States and
      processes and stores it there, and your submission of such information represents your agreement to that
      transfer.</p>

    <p>With respect to Personal Data subject to the GDPR, transfers of such Personal Data outside the EU/EEA are
      performed subject to appropriate safeguards such as standard data protection clauses adopted or otherwise approved
      by the EU Commission in accordance with the GDPR.</p>

    <p><b>Our Policy Toward Children</b></p>

    <p>The Services are not directed to Users under the age of 18. We do not knowingly collect PII from children under
      18. If we become aware that a child under 18 has provided us with P.I.I., we will delete such information from our
      files.</p>

    <p><b>Limitation of Liability</b></p>

    <p>The Company has taken all reasonable commercial steps and efforts available to us at this time to prevent your
      Information, including your Genomic Data, P.I.I. and other information covered by this Privacy Policy from being
      accessed, used, intercepted or disclosed by unauthorized individuals in violation of this Privacy Policy; yet, you
      should know and acknowledge that Company cannot fully eliminate all potentially existing security risks associated
      with your information. By agreeing to this Privacy Policy, you expressly acknowledge and agree that uploading,
      posting, providing, storing, using, analyzing and/or modifying your Genomic Data, P.I.I. and other information on
      or into the Site, the SIVOGEN Platform, GENA or other Services, and the use of all such Services, are all done at
      your sole risk and responsibility. You expressly acknowledge that Company is not liable for (i) any special,
      indirect, consequential, incidental or punitive damages, costs, or liabilities whatsoever arising out of or
      resulting from your use of the Site, the SIVOGEN Platform, GENA or other Services, including from your uploading,
      posting, providing, storing, using, analyzing and/or modifying your Genomic Data, P.I.I. and other information; or
      (ii) any loss, disclosure or use of your Genomic Data, P.I.I. or other information.</p>

    <p><b>How to Reach Out to Us</b></p>

    <p>Please contact us at privacy@sivotecanalytics.com for any questions regarding this Privacy Policy or at: SIVOTEC
      BioInformatics LLC, 4800 T-Rex Ave. Suite 315, Boca Raton, FL 33431 USA</p>

  </div>);
}
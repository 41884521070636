import React from 'react';
import {Checkbox} from 'antd';

import './FilterOmim.css';

const CheckboxGroup = Checkbox.Group;

class FilterOmim extends React.Component {

  toggleOmimSelection = (checkedList) => {
    this.props.getOmimForOmimFilter(checkedList);
  };

  render() {
    const {facetCounts, selectedFragmentsLength, selectedOmim, numFound} = this.props;

    return (
      <div className="menu-separator">
        <div className="case-dashboard-option case-fragments">
          <CheckboxGroup value={selectedOmim}
                         disabled={selectedFragmentsLength === 0}
                         onChange={this.toggleOmimSelection}>
            <div className="margin-top-bottom-7">
              <Checkbox key='ALL_GENE' value='ALL_GENE' className="display-table-cell">
              </Checkbox>
              <span className="display-table-cell">
                All Genes
                {selectedOmim.indexOf('ALL_GENE') > -1 ? ` (${numFound} genes)` : ''}
              </span>
            </div>

            <div className="margin-top-bottom-7">
              <Checkbox key='ALL_OMIM' value='ALL_OMIM' className="display-table-cell">
              </Checkbox>
              <span className="display-table-cell">
                All OMIM
                {selectedOmim.indexOf('ALL_OMIM') > -1 ? ` (${facetCounts.allOmimed} genes)` : ''}
              </span>
            </div>

            <div className="margin-top-bottom-7">
              <Checkbox key='WITH_DISORDERS' value='WITH_DISORDERS' className="display-table-cell">
              </Checkbox>
              <span className="display-table-cell">
                With Disorders
                {selectedOmim.indexOf('WITH_DISORDERS') > -1 ? ` (${facetCounts.withDisorders} genes)` : ''}
              </span>
            </div>

            <div className="margin-top-bottom-7">
              <Checkbox key='WITH_DOMINANT_INHERITANCE' value='WITH_DOMINANT_INHERITANCE'
                        className="display-table-cell">
              </Checkbox>
              <span className="display-table-cell">
                With Dominant Inheritance
                {selectedOmim.indexOf('WITH_DOMINANT_INHERITANCE') > -1 ? ` (${facetCounts.dominant} genes)` : ''}
              </span>
            </div>

            <div className="margin-top-bottom-7">
              <Checkbox key='WITH_RECESSIVE_INHERITANCE' value='WITH_RECESSIVE_INHERITANCE'
                        className="display-table-cell">
              </Checkbox>
              <span className="display-table-cell">
                With Recessive Inheritance
                {selectedOmim.indexOf('WITH_RECESSIVE_INHERITANCE') > -1 ? ` (${facetCounts.recessive} genes)` : ''}
              </span>
            </div>
          </CheckboxGroup>
        </div>
      </div>
    )
  }

}

export default FilterOmim;
/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import React from 'react';
import {connect} from 'react-redux';
import {Button, Modal} from 'antd';
import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from '../CheckoutForm/CheckoutForm';
import {enableOrDisablePayAsYouGo, subscribePlan, updateCustomer, subscribePaypalPaymentPlan} from "../../BillingApiActions";
import './PurchasePlan.css';
import GenaLoader from "../../../../utils/components/GenaLoader/GenaLoader";
import MessageModal from "../../../../utils/components/MessageModal/MessageModal";
import PaypalExpressBtn from 'react-paypal-express-checkout';

/**
 * Login page shown to the user when session is not present
 */
class PurchasePlan extends React.Component {

  showModal = () => {
    this.setState({visible: true});
  };

  onCancel = () => {
    this.setState({visible: false, showLoader: false});
  };

  hideLoader = () => {
    this.setState({showLoader: false});
  };

  showLoader = () => {
    this.setState({showLoader: true});
  };

  subscribeToPlan = (planId, token, singleUserPlanConsentApproved) => {
    this.props.subscribePlan(planId, token, singleUserPlanConsentApproved).then(res => {
      this.onCancel();
      this.refs.messageModal.setState({
        showModal: true, message: 'Plan subscribed successfully.', title: 'Subscription',
        showOkButton: true,
        handleOk: () => {
          this.props.refreshPlanList();
        },
        okText: 'Ok'
      });

    }, err => {
      this.hideLoader();

      if(err.response.status === 428) {
        this.refs.messageModal.setState({
          showModal: true, message: err.response.data.error, title: 'Subscription',
          showOkButton: true,
          showCancelButton: true,
          okText: 'Purchase',
          handleCancel: () => {},
          handleOk: () => {
            this.setState({showLoader: true}, () => {
              this.subscribeToPlan(planId, token, true);
            });
          },
        });
      }
    })
  };

  processPaymentRequest = (token = null) => {
    if (this.props.subscription) {
      this.subscribeToPlan(this.props.planId, token);
    } else {
      let enablePayAsYouGo = !this.props.enablePayAsYouGo;

      let formData = {enablePayAsYouGo: !this.props.enablePayAsYouGo};
      if (enablePayAsYouGo) {
        formData.token = token;
      }

      this.setState({showLoader: true}, () => {
        this.props.enableOrDisablePayAsYouGo(formData)
          .then(res => {
            this.onCancel();

            this.refs.messageModal.setState({
              showModal: true,
              message: enablePayAsYouGo ? 'Payment details added successfully.' : 'Disabled Pay-per-Use successfully',
              title: enablePayAsYouGo ? 'Enabled Pay-per-Use' : 'Disabled Pay-per-Use',
              showOkButton: true,
              handleOk: () => {
                this.props.refreshPlanList();
              },
              okText: 'Ok'
            });

          }, err => {
            this.hideLoader();
          });
      });
    }
  };

  handleTokenCreation = (token) => {
    if (token.hasOwnProperty('token') && token.token.hasOwnProperty('id')) {
      this.setState({showLoader: true}, () => {
        this.processPaymentRequest(token.token.id);
      });
    } else {
      this.hideLoader();
    }
  };

  onPaypalPaymentSuccess = (payment) => {
    // Congratulation, it came here means everything's fine!
    const formData = {
      subscriptionPlanId: this.props.planId,
      paypalJson: payment
    };

    this.setState({showLoader: true}, () => {
      this.props.subscribePaypalPaymentPlan(formData).then(res => {
        this.onCancel();

        this.refs.messageModal.setState({
          showModal: true, message: 'Plan purchased successfully.', title: 'Plan Purchase',
          showOkButton: true,
          handleOk: () => {
            this.props.refreshPlanList();
          },
          okText: 'Ok'
        });
      }, error => {
        this.onCancel();

        this.refs.messageModal.setState({
          showModal: true, message: 'Please contact admin for query regarding this transaction.', title: 'Plan Purchase',
          showOkButton: true,
          handleOk: () => {
            this.props.refreshPlanList();
          },
          okText: 'Ok'
        });
      });
    });
  };

  onPaypalPaymentCancel = (data) => {
  };

  onPaypalPaymentError = (err) => {
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      showLoader: false
    };

    this.client = {
      sandbox: process.env.REACT_APP_PAYPAL_CLIENT_ID,
      production: process.env.REACT_APP_PAYPAL_CLIENT_ID
    };
  }

  render() {

    return (
      <>
      {
        this.props.subscription || !this.props.enablePayAsYouGo
          ? (
            <div>
              {
                this.props.paypalPayment
                  ?<div className="purchase-paypal-plan">
                    <PaypalExpressBtn client={this.client} currency={'USD'} total={this.props.amount}
                                      onSuccess={this.onPaypalPaymentSuccess} onError={this.onPaypalPaymentError}
                                      onCancel={this.onPaypalPaymentCancel} env={'production'} />
                  </div>
                  : <Button type="primary" onClick={this.showModal} className="purchase-plan">{this.props.subscription ? 'Purchase' : 'Enable Pay-per-Use'}</Button>
              }
            </div>
          )
          : (
            <Button type="danger" onClick={() => this.processPaymentRequest()} className="disable-purchase-plan">
              Disable Pay-per-Use
            </Button>
          )
      }

      <Modal
        visible={this.state.visible}
        title="Purchase Subscription Plan"
        maskClosable={false}
        onCancel={this.onCancel}
        footer={null}
      >
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
          <div>
            <Elements>
              <CheckoutForm handleTokenCreation={this.handleTokenCreation} showLoader={this.showLoader}/>
            </Elements>
          </div>
        </StripeProvider>
      </Modal>

      <MessageModal
        ref="messageModal"/>

      <GenaLoader showLoader={this.state.showLoader}/>

      </>
    )
  }

}

const mapStateToProps = state => {
  return {}
};


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    subscribePlan: (planId, token, singleUserPlanConsentApproved) => {
      return dispatch(subscribePlan(planId, token, singleUserPlanConsentApproved)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    updateCustomer: (token) => {
      return dispatch(updateCustomer(token)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    enableOrDisablePayAsYouGo: (token) => {
      return dispatch(enableOrDisablePayAsYouGo(token)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    subscribePaypalPaymentPlan: (formData) => {
      return dispatch(subscribePaypalPaymentPlan(formData)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    }

  }
};

PurchasePlan = connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchasePlan);

export default PurchasePlan;

import React from 'react';
import {Checkbox, Popover} from 'antd';
import './SyndromeAnnotations.css';

const CheckboxGroup = Checkbox.Group;

class SyndromeAnnotations extends React.Component {

  selectAllSyndromes = e => {
    let allSyndromesSelected = e.target.checked;

    const selectedSyndromes = allSyndromesSelected ? this.props.syndromeAnnotations.map((syndrome) => {
      return 'chr' + syndrome.chromosome + ':' + syndrome.startCoordinate + '-' + syndrome.endCoordinate
    }) : [];

    const currentState = {
      ...this.state,
      allSyndromesSelected,
      syndromeIndeterminate: false
    };

    this.setState(currentState, () => {
      this.props.getOmimForSyndrome(selectedSyndromes);
    });

  };

  toggleSyndromeSelection = checkedList => {
    const selectedSyndromeCount = checkedList.length;
    const allSyndromeCount = this.props.syndromeAnnotations.length;
    const syndromeIndeterminate = !!selectedSyndromeCount && selectedSyndromeCount < allSyndromeCount;

    let currentState = {
      ...this.state,
      allSyndromesSelected: selectedSyndromeCount === allSyndromeCount,
      syndromeIndeterminate
    };

    this.setState(currentState, () => {
      this.props.getOmimForSyndrome(checkedList);
    });

  };
  showTooltipForSyndrome = (syndrome) => {
    return (
      <>
      <div>Start coordinate: {syndrome.startCoordinate}</div>
      <div className="margin-bottom-10">End coordinate: {syndrome.endCoordinate}</div>
      <div className="margin-bottom-10">Interval: {Number(syndrome.interval).toLocaleString()}</div>
      <div className="margin-bottom-10">{syndrome.status}</div>
      <div className="margin-bottom-10">Chromosome: {syndrome.chromosome}</div>
      </>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      syndromeIndeterminate: false,
      allSyndromesSelected: false
    }
  }

  render() {
    return (
      this.props.syndromeAnnotations && this.props.syndromeAnnotations.length > 0
        ?
        (<div className="menu-separator">
          <div className="case-dashboard-option">
            <Checkbox
              indeterminate={this.state.syndromeIndeterminate}
              onChange={this.selectAllSyndromes}
              checked={this.state.allSyndromesSelected}
              className="display-table-cell">
            </Checkbox>
            <span
              className="mainHeading display-table-cell">Syndromes {this.props.syndromeAnnotations && this.props.syndromeAnnotations.length > 0 && ('(' + this.props.syndromeAnnotations.length + ')')}</span>
          </div>

          <div className="case-dashboard-option case-fragments">
            <CheckboxGroup value={this.props.selectedSyndromes} onChange={this.toggleSyndromeSelection}>
              {this.props.syndromeAnnotations && this.props.syndromeAnnotations.map((syndrome) => {
                return (
                  <div key={syndrome.id}>
                    <Checkbox className="display-table-cell"
                              value={'chr' + syndrome.chromosome + ':' + syndrome.startCoordinate + '-' + syndrome.endCoordinate}>
                    </Checkbox>
                    <Popover placement="topLeft" content={this.showTooltipForSyndrome(syndrome)} trigger="hover"
                             className="display-table-cell">
                      {syndrome.name} {syndrome.facetCount ? '(' + syndrome.facetCount + ' genes)' : '(0 genes)'}
                    </Popover>

                  </div>
                )
              })
              }
            </CheckboxGroup>
          </div>

        </div>)
        : <></>
    )
  }

}

export default SyndromeAnnotations;
/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from "react-router-dom";
import {Button, Form, Icon, Input, Layout} from 'antd';

import {signInRequest, addDeviceToAccount} from '../../AuthenticationApiActions';
import './SignIn.css';
import logo from '../../../../assets/logo.png';
import ConstantMessages from '../../../../utils/Strings';
import UtilHelper from "../../../../utils/UtilHelper";
import {SYSTEM_ROLES} from '../../../../utils/Constants';
import MessageModal from "../../../../utils/components/MessageModal/MessageModal";
import CustomFooter from "../../../Dashboard/components/CustomFooter/CustomFooter";
import GenaLoader from "../../../../utils/components/GenaLoader/GenaLoader";
import {signIn} from "../../AuthenticationActions";
import Auth from "../../../../utils/Auth";
import DeviceChangeModal from "./DeviceChangeModal";

const {Content, Footer} = Layout;
const FormItem = Form.Item;

/**
 * Login page shown to the user when session is not present
 */
class SignInForm extends React.Component {

  /**
   * This method is triggered when form is submitted by the user
   * @param e
   */
  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({showLoader: true}, () => {
          this.props.onLogin(values).then(res => {
            if(res.loginActivityLog.hasOwnProperty('registeredDeviceLimitReached')) {
              this.setState({
                showLoader: false,
                showDeviceChangeModal: true,
                deviceChangeMessage: res.loginActivityLog.registeredDeviceLimitReachedMsg,
                loginResponse: res
              });
            } else if(res.loginActivityLog.hasOwnProperty('registeredDeviceChanged')) {
              this.setState({
                showLoader: false,
                showDeviceChangeModal: true,
                deviceChangeMessage: res.loginActivityLog.registeredDeviceChangedMsg,
                loginResponse: res
              });
            } else {
              this.signUserToSystem(res);
            }
          }, (error) => {
            this.setState({showLoader: false}, () => {
              if (error.response && error.response.status === 401)
                this.showLoginError(error.response.data.error);
            });
          });
        });
      }
    });
  };

  signUserToSystem = res => {
    this.props.signInSuccess(res);
    let {user} = res;
    Auth.login(user);
  };

  constructor(props) {
    super(props);

    this.state = {
      showLoader: false,
      showDeviceChangeModal: false,
      deviceChangeMessage: '',
      loginResponse: {}
    }
  }

  /**
   * Render the UI of the login form
   * @returns {XML}
   */
  render() {
    document.title = 'GENA by SIVOTEC Bioinformatics';

    const {getFieldDecorator} = this.props.form;
    const indentStyle = { "text-indent": '20px'};

    if (this.props.loggedInUser) {
      if (this.props.loggedInUser.isSystemGeneratedPassword)
        return (<Redirect to="/forceResetPassword"/>);
      else if(!this.props.loggedInUser.isSurveySubmitted) {
        return (<Redirect to="/survey"/>);
      } else {

        const roles = this.props.loggedInUser.roles;

        switch (roles[0].name) {
          case SYSTEM_ROLES.SUPER_ADMIN:
            return (<Redirect to="/dashboard"/>);

          default:
            return (<Redirect to="/cases"/>);

        }

      }
    } else {
      return (
        <Layout>
          <Content>
            <div className='login-form-outer-div'>
              <div className='login-form-inner-div'>
                <Form onSubmit={this.handleSubmit}>
                  <FormItem>
                    <div className='application-logo-img-div'>
                      <div className='gena-name'><b>GENA</b></div>
                      <div>by</div>
                      <img className='application-logo-img' src={logo} alt="Loading..."/>
                    </div>
                  </FormItem>
                  <div>Hi from the GENA™ team. If you already have an account, please sign in below. Also, please remember that if your free trial has expired, you can sign-up to either a Single-User or Enterprise Account online in the Subscriptions tab; alternatively, you can contact us at info@gena.tech. More info and terms are available in our <a href="https://sivotecbioinformatics.com/" target="_blank">website</a>. Thanks!</div>
                  <br/>
                  <div>The GENA™ Team.</div>
                  <FormItem label="Email">
                    {getFieldDecorator("username", {
                      rules: [
                        {required: true, message: ConstantMessages.usernameRequired},
                        {validator: UtilHelper.validateBlankSpaces},
                      ]
                    })(
                      <Input prefix={<Icon type="user"/>} placeholder="Email"/>
                    )}
                  </FormItem>

                  <FormItem label="Password">
                    {getFieldDecorator("password", {
                      rules: [
                        {required: true, message: ConstantMessages.passwordRequired},
                        {validator: UtilHelper.validateBlankSpaces},
                      ]
                    })(
                      <Input
                        prefix={<Icon type="lock"/>}
                        type="password"
                        placeholder="Password"
                      />
                    )}
                  </FormItem>

                  <FormItem>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button">
                      Sign in
                    </Button>
                    <div className="forgotPassword">
                      <a className="login-form-forgot" href="/forgotPassword">
                        Forgot password?
                      </a>
                    </div>
                    <div className="signUpNow">Or <a href="/signup">sign up now!</a></div>
                  </FormItem>
                </Form>
              </div>

              <MessageModal ref="messageModal" />
              <DeviceChangeModal
                ref="deviceChange"
                showDeviceChangeModal={this.state.showDeviceChangeModal}
                deviceChangeMessage={this.state.deviceChangeMessage}
                addDeviceToAccount={this.addDeviceToAccount}
              />

            </div>
          </Content>
          <Footer>
            <CustomFooter/>
          </Footer>

          <GenaLoader showLoader={this.state.showLoader}/>
        </Layout>
      );
    }
  }

  /**
   * Show modal with the message and title received from the server
   * @param loginErrorMessage
   * @param loginErrorTitle
   */
  showLoginError(loginErrorMessage = 'User name or password is incorrect.', loginErrorTitle = "Invalid Credentials") {
    this.refs.messageModal.setState({
      showModal: true, message: loginErrorMessage, title: loginErrorTitle,
      showOkButton: true,
      handleOk: () => {
      },
      okText: 'Ok'
    });
  }

  addDeviceToAccount = deviceName => {
    this.setState({showLoader: true}, () => {
      const {loginActivityLog} = this.state.loginResponse;
      this.props.addDeviceToAccount(deviceName, loginActivityLog.id).then(res => {
        this.signUserToSystem(this.state.loginResponse);
      }, err => {
        this.setState({showLoader: false});
      });
    });
  }
}

let SignIn = Form.create()(SignInForm);

const mapStateToProps = state => {
  return {
    loggedInUser: state.auth.loggedInUser,
    signUp: state.auth.signUp
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onLogin: (data) => {
      return dispatch(signInRequest(data)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },
    addDeviceToAccount: (deviceName, deviceId) => {
      return dispatch(addDeviceToAccount(deviceName, deviceId)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },
    signInSuccess: data => {
      dispatch(signIn(data));
    }
  }
};

SignIn = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);

export default SignIn;

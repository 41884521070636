/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import HttpRequest from '../../utils/HttpRequest';
import {getAllResources, getAllRoles, getAllTemplates, updateTemplateId} from "./AuthorisationActions";
import Notification from "../../utils/Notification";

const notification = new Notification();

/**
 * Server API call to get all Resources
 * - Dispatch getAllResources action after successful response from server
 * @returns {function(*)}
 */
export function getAllResourcesRequest() {
  return (dispatch) => {
    return new HttpRequest(dispatch, '/resources', 'get')
      .send()
      .then(res => {
        dispatch(getAllResources(res.data.data));
        return Promise.resolve(res.data.data);
      }, error => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to get all Roles
 * - Dispatch getAllRoles action after successful response from server
 * @returns {function(*)}
 */
export function getAllRolesRequest() {
  return (dispatch) => {
    return new HttpRequest(dispatch, '/roles', 'get')
      .send()
      .then(res => {
        dispatch(getAllRoles(res.data.data));
      });
  };
}

/**
 * Server API call to get all Templates
 * - Dispatch getAllTemplates action after successful response from server
 * @returns {function(*)}
 */
export function getAllTemplatesRequest() {
  return (dispatch) => {
    return new HttpRequest(dispatch, '/templates', 'get')
      .send()
      .then(res => {
        dispatch(getAllTemplates(res.data.data));
      });
  };
}

/**
 * Server API call to update templates
 * - Dispatch getAllTemplates action after successful response from server
 * @returns {function(*)}
 */

export function updateSingleTemplate(template) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/templates/${template.id}`, 'put', template)
      .send()
      .then(res => {
        notification.show('info', "Template updation", "Template updated successfully");
      });
  };
}

/**
 * Server API call to update templates
 * - Dispatch getAllTemplates action after successful response from server
 * @returns {function(*)}
 */

export function createSingleTemplate(template) {
  const templateId = template.id;

  return (dispatch) => {
    return new HttpRequest(dispatch, `/templates`, 'post', template)
      .send()
      .then(res => {
        notification.show('info', "Template creation", "New template created");
        dispatch(updateTemplateId(res.data.data.id, templateId));
      });
  };
}
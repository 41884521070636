/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import React from 'react';
import {connect} from 'react-redux';
import {Button, Form, Input, Layout} from 'antd';

import {forgotPasswordRequest} from '../../AuthenticationApiActions';
import './ForgotPassword.css';
import logo from '../../../../assets/logo.png';
import ConstantMessages from '../../../../utils/Strings';
import MessageModal from "../../../../utils/components/MessageModal/MessageModal";
import CustomFooter from "../../../Dashboard/components/CustomFooter/CustomFooter";

const {Content, Footer} = Layout;
const FormItem = Form.Item;

const fieldValidationRules = {
  email: {
    rules: [
      {type: 'email', message: ConstantMessages.enterValidEmailAddress},
      {required: true, message: ConstantMessages.emailAddressRequired}
    ],
  }
};

/*
 * Form to get the email address from the user and send the verification link to the registered email address
 */
class ForgotPasswordForm extends React.Component {

  /**
   * This method is triggered when form is submitted by the user
   * @param e
   */
  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, formData) => {
      if (!err) {

        this.props.forgotPassword(formData).then((res) => {
          this.refs.messageModal.setState({
            showModal: true, message: `${res} You will be redirected to the login page.`,
            title: 'Forgot password',
            showOkButton: true,
            handleOk: this.navigateToHome,
            okText: 'Ok'
          });
        });
      }
    });
  };
  /**
   * On navigate user to the root path
   */
  navigateToHome = () => {
    window.location = '/';
  };

  constructor(props) {
    super(props);

    this.state = {
      showLoader: false
    };
  }

  /**
   * Render the UI of the forgot password form
   * @returns {XML}
   */
  render() {
    const {getFieldDecorator} = this.props.form;

    return (
      <Layout>
        <Content>
          <div className='forgot-password-form-outer-div'>
            <div className='forgot-password-form-inner-div'>
              <Form onSubmit={this.handleSubmit}>
                <FormItem>
                  <div className='application-logo-img-div'>
                    <img className='application-logo-img' src={logo} alt="Loading..."/>
                  </div>
                </FormItem>
                <FormItem label="Email address">
                  {getFieldDecorator("email", fieldValidationRules.email)(
                    <Input placeholder="Enter email address"/>
                  )}
                </FormItem>
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="forgot-password-form-button">
                    Request Temporary Password
                  </Button>
                  Or <a href="/signin">go to sign in!</a>
                </FormItem>
              </Form>
            </div>
          </div>

          <MessageModal
            ref="messageModal"
          />
        </Content>
        <Footer>
          <CustomFooter/>
        </Footer>
      </Layout>
    );
  }

}

let ForgotPassword = Form.create()(ForgotPasswordForm);

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    forgotPassword: (data) => {
      return dispatch(forgotPasswordRequest(data)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    }
  }
};

ForgotPassword = connect(
  null,
  mapDispatchToProps
)(ForgotPassword);

export default ForgotPassword;

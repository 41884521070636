/**
 * @author Punit Vajpeyi <punit@wemotiveforge.com>
 */

import {CREATE_CASE, GET_ALL_CASES, UPDATE_CASE} from './CaseActions';
import {SIGN_OUT} from "../Authentication/AuthenticationActions";

/**
 * Authorisation reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const CaseReducer = (state = {cases: []}, action) => {
  let {cases} = state;

  switch (action.type) {
    case GET_ALL_CASES:
      return Object.assign({}, state, {
        cases: action.cases
      });

    case CREATE_CASE:
      return Object.assign({}, state, {
        cases: [...state.cases, action.caseValue]
      });

    case UPDATE_CASE:
      if (cases !== undefined) {
        cases = cases.map((caseVal) => {
          if (caseVal.id === action.caseValue.id) {
            return action.caseValue;
          }
          return caseVal;
        });

      } else {
        cases = [action.caseValue];
      }

      return Object.assign({}, state, {
        cases
      });

    case SIGN_OUT:
      return Object.assign({}, {});

    default:
      return state;
  }
};

export default CaseReducer;
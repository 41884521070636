import React from 'react';
import ReactTable from 'react-table';
import {connect} from 'react-redux';
import {LOCATION_UNITS} from '../../../../utils/Constants';

import {Icon, Tooltip} from 'antd';

import './FragmentTable.css';
import AddFragment from "../AddFragment/AddFragment";

const regDigit = new RegExp('^\\d+$');

/**
 * User table component which shows all users data
 */
class FragmentTable extends React.Component {

  componentDidMount = () => {
  };

  deleteFragment = (id) => {
    this.props.removeFragmentFromCase(id);
  };

  handleUpdateFragment = (fragment) => {
    this.props.updateFragment(fragment);
  };

  render() {
    const {fragments, locationUnit} = this.props;

    const columns = [{
      Header: 'Chromosome',
      id: 'chromosome',
      accessor: 'chromosome',
      resizable: false,
      Cell: props => {
        return (props.value.indexOf('chr') < 0) ? 'chr' + props.value : props.value;
      }
    }, {
      Header: 'Start Coordinate',
      accessor: 'startCoordinate',
      resizable: false
    }, {
      Header: 'End Coordinate',
      accessor: 'endCoordinate',
      resizable: false
    }, {
      Header: 'Unit',
      accessor: '',
      resizable: false,
      Cell: row => {
        const unit = LOCATION_UNITS.find((unit) => {
          return unit.id === locationUnit;
        });

        return unit ? unit.name : locationUnit;
      }
    }, {
      Header: 'Action',
      sortable: false,
      resizable: false,
      id: 'action',
      Cell: props => (
        <div className="action-column-cell">
          {
            regDigit.test(props.original.id)
              ? <AddFragment handleUpdateFragment={this.handleUpdateFragment} update={true} fragment={props.original}/>
              : <div className="display-inline-block width-25"></div>
          }

          <Tooltip placement="top" title="Delete segment">
            <Icon type="delete" onClick={() => this.deleteFragment(props.original.id)} className="delete-segment"/>
          </Tooltip>
        </div>)
    }];

    return (
      <div className="margin-top-10">
        {
          fragments.length > 0 &&
          <ReactTable
            defaultPageSize={5}
            data={fragments}
            columns={columns}
            className="-striped -highlight"
          />
        }
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

FragmentTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(FragmentTable);

export default FragmentTable;
import React from 'react';

import {Button, Input, Modal} from 'antd';
import './DeviceChangeModal.css';

/**
 * User table component which shows all users data
 */
class DeviceChangeModal extends React.Component {

  closeModal = () => {
    this.setState({showModal: false});
  };

  constructor(props) {
    super(props);
    this.state = {
      showRequiredErrorMessage: false,
      showSpacesErrorMessage: false,
      showMaxLengthErrorMessage: false,
      deviceName: ''
    }
  }

  componentDidLoad() {
  }

  deviceNameChange = e => {this.setState({deviceName: e.target.value})};

  handleOk = () => {
    const {deviceName} = this.state;

    if(deviceName.length === 0) {
      this.setState({showRequiredErrorMessage: true});
    } else if(deviceName.trim().length === 0) {
      this.setState({showSpacesErrorMessage: true});
    } else if(deviceName.trim().length > 100) {
      this.setState({showMaxLengthErrorMessage: true});
    } else {
      this.props.addDeviceToAccount(deviceName);
    }
  };

  render() {
    const {showRequiredErrorMessage, showSpacesErrorMessage, showMaxLengthErrorMessage, deviceName} = this.state;

    const footerButtons = [<Button key="submit" type="primary" onClick={() => this.handleOk()}>Add Device</Button>];

    return (
      <Modal
        centered={true}
        confirmLoading={false}
        visible={this.props.showDeviceChangeModal}
        title={'Device Change Detected'}
        closable={false}
        footer={footerButtons}>
        <div>
          <div>{this.props.deviceChangeMessage}</div>
          <div className={'device-name-container'}>
            <Input placeholder={'Device name'} onChange={this.deviceNameChange} value={deviceName}/>
            {showRequiredErrorMessage && <span className={'device-change-error'}>Please enter device name</span>}
            {showSpacesErrorMessage && <span className={'device-change-error'}>Only spaces not allowed</span>}
            {showMaxLengthErrorMessage && <span className={'device-change-error'}>Only 100 characters allowed</span>}
          </div>
        </div>
      </Modal>
    )
  }

}

export default DeviceChangeModal;

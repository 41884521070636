/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import React from 'react';
import {connect} from 'react-redux';
import {Button, Col, Form, Input, Row, Select} from 'antd';
import {ACTIONS, ANALYSIS_TYPE, GENOM_ASSEMBLY, LOCATION_UNITS} from '../../../../utils/Constants';
import './AddCase.css';
import AddFragment from "../AddFragment/AddFragment";
import FragmentTable from "../FragmentTable/FragmentTable";
import Notification from "../../../../utils/Notification";
import ConstantMessages from '../../../../utils/Strings';
import {createCaseRequest, getCaseRequest, updateCaseRequest, dissociateFromOrganization} from "../../CaseApiActions";
import UtilHelper from "../../../../utils/UtilHelper";
import MessageModal from "../../../../utils/components/MessageModal/MessageModal";
import GenaLoader from "../../../../utils/components/GenaLoader/GenaLoader";
import Auth from "../../../../utils/Auth";

const {Item: FormItem} = Form;
const {Option} = Select;
const notification = new Notification();

class AddCaseForm extends React.Component {

  getCaseDetails = (caseId) => {
    this.setState({showLoader: true}, () => {
      this.props.getCaseDetails(caseId).then((res) => {
        this.setInitialValues(res);
        this.setState({showLoader: false});
      }, (error) => {
        this.setState({showLoader: false});
      });
    });
  };
  setInitialValues = (caseValue = {}) => {

    if (caseValue.hasOwnProperty('id')) {

      const showSaveButton = caseValue.hasOwnProperty('createdBy') && caseValue.createdBy === this.props.loggedInUserId;

      const currentState = Object.assign({}, this.state, {
        caseAnalysis: caseValue,
        locationUnit: caseValue.locationUnit,
        fragments: caseValue.fragments,
        showSaveButton
      });

      this.setState(currentState);

    } else {
      this.setState({showSaveButton: true});
    }

  };
  validateFragmentsAndSubmitForm = (formData) => {
    const {fragments} = this.state;

    if (fragments.length === 0) {
      notification.show('error', 'Segments Not Added', 'Please add at least one segment to the case.');
    } else {
      if (this.validateLocationAndFragments(this.state.locationUnit)) {

        if (this.validateFragmentRepeat()) {

          this.setState({showLoader: true}, () => {
            formData.genes = formData.genes ? formData.genes.split(",") : [];

            if (this.props.match.params.hasOwnProperty("id")) {
              formData.fragments = fragments.map(fragment => {
                return {chromosome: fragment.chromosome, startCoordinate: fragment.startCoordinate, endCoordinate: fragment.endCoordinate};
              });

              formData.technology = 'UNKNOWN';

              this.props.updateCase(this.props.match.params.id, formData).then(() => {
                this.setState({showLoader: false});
                this.refs.messageModal.setState({
                  showModal: true,
                  title: ConstantMessages.updateCaseNotificationTitle,
                  message: ConstantMessages.updateCaseSuccessMessage,
                  showOkButton: true,
                  handleOk: this.navigateToCaseList,
                  okText: 'Ok',
                  cancelText: 'Cancel'
                });
              }, (error) => {
                this.setState({showLoader: false});

                if (error && error.response && error.response.status === 409) {
                  if(this.props.currentRole.isAnalyst)
                    this.showDissociateFlowStepOne(error.response.data);
                  else {
                    notification.show('error', 'Error', error.response.data.error);
                  }
                }
              });

            } else {
              formData.fragments = fragments.map(fragment => {
                return {chromosome: fragment.chromosome, startCoordinate: fragment.startCoordinate, endCoordinate: fragment.endCoordinate};
              });

              formData.technology = 'UNKNOWN';
              formData.makePaymentRequest = false;

              this.createNewCase(formData);
            }
          });

        }
      }
    }
  };

  createNewCase = (formData) => {

    this.setState({showLoader: true}, () => {
      this.props.createCase(formData).then(() => {
        this.setState({showLoader: false}, () => {
          this.refs.messageModal.setState({
            showModal: true,
            title: ConstantMessages.addCaseNotificationTitle,
            message: ConstantMessages.addCaseSuccessMessage,
            showOkButton: true,
            showCancelButton: false,
            handleOk: this.navigateToCaseList,
            okText: 'Ok',
            cancelText: 'Cancel'
          });
        });

      }, (error) => {
        this.setState({showLoader: false}, () => {
          if (error && error.response && error.response.status === 428) {
            this.refs.messageModal.setState({
              showModal: true,
              title: 'Case Payment',
              message: error.response.data.error,
              showOkButton: true,
              showCancelButton: true,
              handleOk: () => {
                formData.makePaymentRequest = true;
                this.createNewCase(formData);
              },
              okText: 'Ok',
              cancelText: 'Cancel'
            });
          } else if (error && error.response && error.response.status === 409) {
            if(this.props.currentRole.isAnalyst)
              this.showDissociateFlowStepOne(error.response.data);
            else {
              notification.show('error', 'Error', error.response.data.error);
            }
          }

        });
      });
    });
  };

  showDissociateFlowStepOne = (error) => {
    this.refs.messageModal.setState({
      showModal: true,
      title: 'Dissociate From Organization',
      message: <div>{error.metadata.msg1} You can learn more about Single-User and Enterprise accounts <a href = "http://www.sivotecbioinformatics.com/plans-pricing" target = "_blank">here</a>.</div>,
      showOkButton: true,
      showCancelButton: true,
      handleOk: () => {
        this.showDissociateFlowStepTwo(error);
      },
      okText: 'Dissociate',
      cancelText: 'Cancel'
    });
  };

  showDissociateFlowStepTwo = (error) => {
    this.refs.messageModal.setState({
      showModal: true,
      title: 'Dissociate From Organization',
      message: error.metadata.msg2,
      showOkButton: true,
      showCancelButton: true,
      handleOk: () => {
        this.dissociateFromOrganization();
      },
      okText: 'OK',
      cancelText: 'Cancel'
    });
  };

  dissociateFromOrganization = () => {
    this.setState({showLoader: true}, () => {
      this.props.dissociateFromOrganization(this.props.loggedInUserId).then(() => {
        this.setState({showLoader: false}, () => {
          this.refs.messageModal.setState({
            showModal: true,
            title: 'Dissociate',
            message: 'Thank you for your confirmation. Your account is being migrated. You will be logged out automatically. Please log in again after approximately 10 minutes.',
            showOkButton: true,
            showCancelButton: false,
            handleOk: () => {
              Auth.logout();
              window.location = ACTIONS.SIGN_IN;
            },
            okText: 'Ok',
          });
        });
      }, error => {
        this.setState({showLoader: false});
      });
    });
  };

  validateFormElement = () => {
    return new Promise((resolve, reject) => {
      this.props.form.validateFieldsAndScroll((err, formData) => {
        if (!err) {
          resolve(formData);
        } else {
          reject(err);
        }

      });
    });
  };


  handleSubmit = e => {
    e.preventDefault();
  };
  validateFragmentRepeat = () => {
    const {fragments} = this.state;
    let duplicateExist = false;
    let repeatFragments = [];

    fragments.forEach((frag, index) => {
      let repeated = fragments.filter(other => {
        return other.chromosome === frag.chromosome && frag.startCoordinate === other.startCoordinate && other.endCoordinate === frag.endCoordinate
      });

      if (repeated.length > 1) {
        duplicateExist = true;
        repeatFragments.push(frag);
      }
    });

    let currentState = Object.assign({}, this.state, {
      repeatFragments: repeatFragments
    });

    this.setState(currentState);

    return !duplicateExist;
  };
  validateLocationAndFragments = (locationUnit) => {

    const {fragments} = this.state;

    let isValidSegment;
    let validFragment = true;
    let locationInvalidFragments = [];

    switch (locationUnit) {
      case 'KB':
        isValidSegment = (segment) => {
          if (parseInt(segment.startCoordinate) > parseInt(segment.endCoordinate))
            return false;

          return ((segment.startCoordinate).match(/^[0-9]{1,6}$/) && (segment.endCoordinate).match(/^[0-9]{1,6}$/))
        };
        break;

      case 'MB':
        isValidSegment = (segment) => {
          // convert MB coordinate to base pair
          let startCoordinate = parseFloat(segment.startCoordinate) * 1000000;
          let endCoordinate = parseFloat(segment.endCoordinate) * 1000000;

          if (startCoordinate > endCoordinate)
            return false;

          return ((startCoordinate.toString()).match(/^[0-9]{1,9}$/) && (endCoordinate.toString()).match(/^[0-9]{1,9}$/))
        };
        break;

      default:
        isValidSegment = (segment) => {
          if (parseInt(segment.startCoordinate) > parseInt(segment.endCoordinate))
            return false;

          return ((segment.startCoordinate).match(/^[0-9]{1,9}$/) && (segment.endCoordinate).match(/^[0-9]{1,9}$/))
        }
    }

    fragments.forEach((fragment) => {
      if (!isValidSegment(fragment)) {
        validFragment = false;
        locationInvalidFragments.push(fragment);
      }
    });

    const currentState = Object.assign({}, this.state, {
      locationInvalidFragments: locationInvalidFragments
    });

    this.setState(currentState);

    return validFragment;
  };
  handleAddFragment = (fragment) => {

    let fragments = [...this.state.fragments];

    fragments = [fragment, ...fragments];

    const currentState = Object.assign({}, this.state, {
      fragments: fragments
    });

    this.setState(currentState);

  };
  handleAddFragmentList = (segments) => {

    let fragments = [...this.state.fragments];

    fragments = [...segments, ...fragments];

    const currentState = Object.assign({}, this.state, {
      fragments: fragments
    });

    this.setState(currentState);

  };

  navigateToCaseList = () => {
    this.props.history.goBack();
  };

  removeFragmentFromCase = (id) => {

    let fragments = [...this.state.fragments];

    fragments = fragments.filter(fragment => fragment.id !== id);

    const currentState = Object.assign({}, this.state, {
      fragments: fragments
    });

    this.setState(currentState);

  };

  updateFragment = (newFragment) => {

    let fragments = [...this.state.fragments];

    fragments = fragments.map(fragment => {
      if (fragment.id !== newFragment.id)
        return fragment;
      else
        return newFragment;
    });

    const currentState = Object.assign({}, this.state, {
      fragments: fragments
    });

    this.setState(currentState);

  };

  locationUnitChanged = (locationUnit) => {

    const currentState = Object.assign({}, this.state, {
      locationUnit: locationUnit
    });

    this.setState(currentState);

  };

  constructor(props) {
    super(props);

    this.state = {
      locationUnit: 'BASE',
      locationInvalidFragments: [],
      repeatFragments: [],
      caseAnalysis: {
        name: '',
        nickName: '',
        notes: '',
        locationUnit: 'Base',
        genomeAssembly: 'hg19',
        genes: '',
        queryType: 'Regions of homozygosity'
      },
      fragments: [],
      showLoader: false,
      showSaveButton: true
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.visible !== nextProps.visible) {
      const {form} = this.props;
      // Reset form fields
      form.resetFields();
    }
  }

  componentDidMount() {
    let {id: caseId} = this.props.match.params;
    if (caseId) {
      this.getCaseDetails(caseId);
    } else {
      this.setInitialValues();
    }
  }

  render() {
    let {form} = this.props;

    const {caseAnalysis, locationUnit, locationInvalidFragments, repeatFragments, fragments} = this.state;

    const {getFieldDecorator, getFieldError} = form;

    // form fields validation rules
    const fieldsConfig = {
      nickName: {
        initialValue: caseAnalysis.nickName,
        rules: [
          {required: true, message: 'Please enter accession'},
          {validator: UtilHelper.validateBlankSpaces}
        ]
      },
      locationUnit: {
        initialValue: locationUnit,
        rules: [
          {required: true, message: 'Please select coordinate unit'},
          {validator: UtilHelper.validateBlankSpaces}
        ]
      },
      genomeAssembly: {
        initialValue: caseAnalysis.genomeAssembly,
        rules: [
          {required: true, message: 'Please select genome assembly'}
        ]
      },
      queryType: {
        initialValue: caseAnalysis.queryType,
        rules: [
          {required: true, message: 'Please select analysis type'}
        ]
      },
      notes: {
        initialValue: caseAnalysis.notes
      }
    };

    const invalidFragments = locationInvalidFragments.map(fragment => `chr${fragment.chromosome}:${fragment.startCoordinate}-${fragment.endCoordinate}`).join(", ");

    let errorMessageInvalidFragment = `Please verify the segments ${invalidFragments}, either the coordinate unit does match or the co-ordinates are incorrect.`;

    const repeatFrags = repeatFragments.map(fragment => `chr${fragment.chromosome}:${fragment.startCoordinate}-${fragment.endCoordinate}`).join(", ");

    if (invalidFragments.length === 0) {
      errorMessageInvalidFragment = `${repeatFrags} segments are repeated`;
    }

    return (
      <>
      <Form layout="vertical" onSubmit={this.handleSubmit} className="sivotec-page-content">
        <div className="case-creation-container">
          <Row className="case-creation-note">
            <b>Note: Do not enter any personally identifiable information in any of the fields</b>
          </Row>
          <Row>
            <Col span={12} className="padding-16">
              <FormItem
                validateStatus={getFieldError('queryType') ? 'error' : ''}
                help={getFieldError('queryType') || ''}
                label="Analysis Type">
                {getFieldDecorator('queryType', fieldsConfig.queryType)(
                  <Select placeholder="Select analysis type" disabled={!!this.props.match.params.id}>
                    {
                      ANALYSIS_TYPE && ANALYSIS_TYPE.map((type) => {
                        return <Option key={type.id} value={type.id}>{type.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col span={12} className="padding-16">
              <FormItem
                validateStatus={getFieldError('nickName') ? 'error' : ''}
                help={getFieldError('nickName') || ''}
                label="Accession #">
                {getFieldDecorator('nickName', fieldsConfig.nickName)(
                  <Input placeholder="Enter accession"/>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col span={12} className="padding-16">
              <FormItem
                validateStatus={getFieldError('genomeAssembly') ? 'error' : ''}
                help={getFieldError('genomeAssembly') || ''}
                label="Genome Assembly">
                {getFieldDecorator('genomeAssembly', fieldsConfig.genomeAssembly)(
                  <Select placeholder="Select genome assembly">
                    {
                      GENOM_ASSEMBLY && GENOM_ASSEMBLY.map((unit) => {
                        return <Option key={unit.id} value={unit.id}>{unit.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col span={12} className="padding-16">
              <FormItem
                validateStatus={getFieldError('locationUnit') ? 'error' : ''}
                help={getFieldError('locationUnit') || ''}
                label="Coordinate Unit">
                {getFieldDecorator('locationUnit', fieldsConfig.locationUnit)(
                  <Select placeholder="Select coordinate unit" disabled={!!this.props.match.params.id}
                          onChange={this.locationUnitChanged}>
                    {
                      LOCATION_UNITS && LOCATION_UNITS.map((unit) => {
                        return <Option key={unit.id} value={unit.id}>{unit.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col span={12} className="padding-16">
              <FormItem
                validateStatus={getFieldError('notes') ? 'error' : ''}
                help={getFieldError('notes') || ''}
                label="Notes">
                {getFieldDecorator('notes', fieldsConfig.notes)(
                  <Input placeholder="Enter notes"/>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col span={24} className="padding-16">
              <AddFragment handleAddFragment={this.handleAddFragment} handleAddFragmentList={this.handleAddFragmentList}
                           update={false}/>
              <FragmentTable locationUnit={locationUnit}
                             fragments={fragments}
                             updateFragment={this.updateFragment}
                             removeFragmentFromCase={this.removeFragmentFromCase}/>
              <span
                className={`location-fragment-mismatch ${this.state.locationInvalidFragments.length === 0 && this.state.repeatFragments.length === 0 ? "hide-display" : ""}`}>
                      {errorMessageInvalidFragment}
                    </span>
            </Col>
          </Row>

          <Row>
            <div className="save-case">
              <FormItem>
                <Button htmlType="button" onClick={this.navigateToCaseList}>
                  Cancel
                </Button>
                {this.state.showSaveButton
                  ?
                  <Button type="primary" onClick={() => {
                    this.validateFormElement().then((formData) => {
                      this.validateFragmentsAndSubmitForm(formData);
                    }, () => {

                    });
                  }}>
                    Save case
                  </Button>
                  : <></>
                }
              </FormItem>
            </div>
          </Row>
        </div>

      </Form>

      <MessageModal
        ref="messageModal"
      />

      <GenaLoader showLoader={this.state.showLoader}/>
      </>
    );
  }

}

let AddCase = Form.create()(AddCaseForm);

const mapStateToProps = (state) => {

  return {
    cases: state.caseReducer.cases,
    loggedInUserId: state.auth.loggedInUser.id,
    currentRole: state.auth.currentRole
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    createCase: (data) => {
      return dispatch(createCaseRequest(data)).then(() => {
        return Promise.resolve(data);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    updateCase: (caseId, data) => {
      return dispatch(updateCaseRequest(caseId, data)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    dissociateFromOrganization: (userId) => {
      return dispatch(dissociateFromOrganization(userId)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    getCaseDetails: (caseId) => {
      return dispatch(getCaseRequest(caseId)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    }
  }
};

AddCase = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCase);

export default AddCase;

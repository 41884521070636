/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import {
  ADD_DRAFT_TEMPLATE,
  GET_ALL_RESOURCES,
  GET_ALL_ROLES,
  GET_ALL_TEMPLATES,
  GET_PERMISSION_TEMPLATE,
  UPDATE_ALL_TEMPLATES,
  UPDATE_LOG_IN_USER_RESOURCES,
  UPDATE_RESOURCE_OF_TEMPLATE,
  UPDATE_TEMPLATE_ID
} from './AuthorisationActions';
import {SIGN_OUT} from "../Authentication/AuthenticationActions";

/**
 * Authorisation reducer to update the part of state of the application
 * @param state: Current state value
 * @param action: action received
 * @returns {*} current updated state value
 * @constructor
 */
const AuthorisationReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_RESOURCES:
      return Object.assign({}, state, {
        resources: action.resources
      });

    case GET_PERMISSION_TEMPLATE:
      return Object.assign({}, state, {
        template: action.template
      });

    case GET_ALL_ROLES:
      return Object.assign({}, state, {
        roles: action.roles
      });

    case GET_ALL_TEMPLATES:
      return Object.assign({}, state, {
        templates: action.templates
      });

    case UPDATE_ALL_TEMPLATES:
      return Object.assign({}, state, {
        templates: action.templates
      });

    case ADD_DRAFT_TEMPLATE: {
      action.template.id = "DRAFT" + new Date().getTime();

      return Object.assign({}, state, {
        templates: [...state.templates, action.template]
      });
    }

    case UPDATE_RESOURCE_OF_TEMPLATE: {
      let templates = state.templates;

      let template = templates.find((template) => template.id === action.templateId);

      let index = template.resources.findIndex((resource) => resource.resourceId === action.resource.resourceId);

      template.resources[index] = action.resource;

      return Object.assign({}, state, {
        templates: [...templates]
      });
    }

    case UPDATE_TEMPLATE_ID: {

      let templates = state.templates;

      let template = templates.find((template) => template.id === action.oldTemplateId);

      template.id = action.newTemplateId;

      return Object.assign({}, state, {
        templates: [...templates]
      });

    }

    case UPDATE_LOG_IN_USER_RESOURCES: {

      return Object.assign({}, state, {
        userResources: action.resources
      });
    }

    case SIGN_OUT:
      return Object.assign({}, {});

    default:
      return state;
  }
};

export default AuthorisationReducer;

/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import HttpRequest from '../../utils/HttpRequest';
import Auth from '../../utils/Auth';
import Notification from '../../utils/Notification';
import Constants from '../../utils/Constants';

import {changePassword, signIn} from "./AuthenticationActions";

const notification = new Notification(5);

/**
 * Request the server to validate user credentials and get the access token
 * @param credentials
 * @returns {function(*)}
 */
export function signInRequest(credentials) {
  return (dispatch) => {
    return new HttpRequest(dispatch, Constants.ACTIONS.LOGIN, 'post', {
      username: credentials.username,
      password: credentials.password
    })
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }, error => {
        return Promise.reject(error);
      });
  };
}

/**
 * Request the server to sign up user
 * @param data
 * @returns {function(*)}
 */

export function signUpRequest(data) {
  return (dispatch) => {
    return new HttpRequest(dispatch, Constants.ACTIONS.SIGN_UP, 'post', data)
      .send()
      .then(res => {
        return Promise.resolve(res.data);
      }, err => {
        return Promise.reject(err);
      });
  };
}

export function addDeviceToAccount(deviceName, loginActivityLogId) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/loginActivityLog/${loginActivityLogId}`, 'patch', {deviceName})
      .send()
      .then(res => {
        return Promise.resolve(res.data);
      }, err => {
        return Promise.reject(err);
      });
  };
}

export function changePasswordRequest(data) {
  return (dispatch) => {
    return new HttpRequest(dispatch, '/setpassword', 'post', data)
      .send()
      .then(res => {
        Auth.setSystemGeneratedPassword(false);
        dispatch(changePassword(res.data));
        return Promise.resolve(res.data);
      }, err => {
        return Promise.reject(err);
      });
  };
}

export function updatePasswordRequest(newPassword, currentPassword) {
  return (dispatch) => {
    return new HttpRequest(dispatch, Constants.ACTIONS.CHANGE_PASSWORD, 'post', {currentPassword, newPassword})
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      })
      .catch(err => notification.show('error', 'Change Password', err.response.data.error));
  };
}

export function forgotPasswordRequest(data) {
  return (dispatch) => {
    return new HttpRequest(dispatch, Constants.ACTIONS.FORGOT_PASSWORD, 'post', {
      email: data.email
    })
      .send()
      .then(res => {
        return Promise.resolve(res.data.data.message);
      })
      .catch((err) => {
        notification.show('error', 'Forgot Password', err.response.data.error);
      });
  };
}

export function resetPasswordRequest(password, userId, token) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `${Constants.ACTIONS.RESET_PASSWORD}/${userId}/${token}`, 'post', {
      password
    })
      .send()
      .then(res => {
        notification.show('info', 'Reset Password', res.data.data.message);
      }).catch((err) => {
        notification.show('error', 'Reset Password', err.response.data.error);
      });
  };
}

export function changeUserPasswordRequest(userId,password) {
  return (dispatch) => {
    return new HttpRequest(dispatch, '/setpassword', 'post', {
      userId,
      password
    })
      .send()
      .then(res => {
        notification.show('info', 'Change Password', res.data.data.message);
        return Promise.resolve(res);
      }).catch((err) => {
        notification.show('error', 'Change Password', err.response.data.error);
        return Promise.reject(err);
      });
  };
}

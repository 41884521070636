import HttpRequest from '../../utils/HttpRequest';

export function getSubscriptionPlans() {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/payment/subscriptionPlans`, 'get')
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function getCurrentSubscriptionPlan() {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/payment/getCurrentSubscriptionPlan`, 'get')
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function getPayAsYouGoMetaInfo() {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/payment/getPayAsYouGoMetaInfo`, 'get')
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function subscribePlan(subscriptionPlanId, token, singleUserPlanConsentApproved) {
  let data = {subscriptionPlanId, token};

  if(singleUserPlanConsentApproved) {
    data.singleUserPlanConsentApproved = true;
  }
  return (dispatch) => {
    return new HttpRequest(dispatch, `/payment/subscribePlan`, 'post', data)
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function updateCustomer(token) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/payment/customer`, 'put', {token})
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function enableOrDisablePayAsYouGo(formData) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/payment/enableOrDisablePayAsYouGo`, 'post', formData)
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function createNewPlan(formData) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/payment/subscriptionPlans`, 'post', formData)
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function cancelCurrentSubscriptionPlan() {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/payment/cancelCurrentSubscriptionPlan`, 'post', {})
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function cancelCurrentSubscriptionForOrganization(organizationId) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/payment/cancelCurrentSubscriptionPlan`, 'post', {organizationId})
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function getPaymentHistory() {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/payment/getPaymentHistory`, 'get')
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function getSpecialSubscriptionPlans() {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/payment/subscriptionPlans?isSpecial=true`, 'get')
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function updatePlanActiveStatusRequest(formData) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/payment/subscriptionPlans`, 'patch', formData)
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function getOrganizationSubscriptionSummary() {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/dashboard/getOrganizationSubscriptionSummary`, 'get')
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function assignSubscriptionPlan(formData) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/payment/assignSubscriptionPlan`, 'post', formData)
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function subscribeOfflinePaymentPlan(formData) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/payment/subscribeOfflinePaymentPlan`, 'post', formData)
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function unAssignSubscriptionPlan(formData) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/payment/unassignSubscriptionPlan`, 'post', formData)
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function subscribePaypalPaymentPlan(formData) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/payment/subscribePaypalPaymentPlan`, 'post', formData)
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import HttpRequest from '../../utils/HttpRequest';
import {
  activateUser,
  createUser,
  deactivateUser,
  getAllUsers,
  setCurrentUser,
  updateCurrentUser,
  updateUser
} from "./UserActions";
import {deleteProfileImage, updateProfileImage, surveySubmit} from "../Authentication/AuthenticationActions";
import {setPermissionTemplate} from "../Authorisation/AuthorisationActions";
import Notification from '../../utils/Notification';
import ConstantMessages from '../../utils/Strings';
import Auth from "../../utils/Auth";

const notification = new Notification();

/**
 * Server API call to get all users
 * - Dispatch getAllResources action after successful response from server
 * @returns {function(*)}
 */
export function getAllUsersRequest(id) {
  return (dispatch) => {
    const url = '/users' + (id ? `?organizationId=${id}`: '');

    return new HttpRequest(dispatch, url, 'get')
      .send()
      .then(res => {
        dispatch(getAllUsers(res.data.data));
        return Promise.resolve(res.data.data);
      }, error => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to get single user
 * @returns {function(*)}
 */
export function getUserRequest(id) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/users/${id}`, 'get', {})
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }, error => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to create a new user
 * - Dispatch createUser action after successful response from server
 * @returns {function(*)}
 */
export function createUserRequest(data) {
  return (dispatch) => {
    return new HttpRequest(dispatch, '/users', 'post', data)
      .send()
      .then(res => {
        let user = res.data.data;
        user.roles = [{name: data.role}];
        dispatch(createUser(user));
        return Promise.resolve(res.data.data);
      }, error => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to update an user
 * - Dispatch updateUser action after successful response from server
 * @returns {function(*)}
 */
export function updateUserRequest(userId, data) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/users/${userId}`, 'patch', data)
      .send()
      .then(res => {
        dispatch(updateUser(res.data.data));
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to activate user account
 * - Dispatch activateUser action after successful response from server
 * @returns {function(*)}
 */
export function activateDeactivateUserRequest(userId, status) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/users/${userId}/activateOrDeactivateAccount`, 'patch', {
      enabled: status
    })
      .send()
      .then(res => {
        notification.show('info', ConstantMessages.activateUserNotificationTitle, status ? ConstantMessages.activateUserSuccessMessage : ConstantMessages.deactivateUserSuccessMessage);
        dispatch(status ? activateUser(res.data.data) : deactivateUser(res.data.data));
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to fetch an users permission template
 * @returns {function(*)}
 */
export function getUserPermissionTemplateRequest(userId) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/users/${userId}/template`, 'get', {})
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }, error => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to fetch an users permission template
 * @returns {function(*)}
 */
export function getLoggedInUserPermissionTemplateRequest(userId) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/users/${userId}/template`, 'get', {})
      .send()
      .then(res => {
        dispatch(setPermissionTemplate(res.data.data));
        return Promise.resolve(res.data.data);
      }, error => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to fetch an user details
 * - Dispatch updateUser action after successful response from server
 * @returns {function(*)}
 */
export function getUserDetailsRequest(id) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/users/${id}`, 'get', {})
      .send()
      .then(res => {
        dispatch(setCurrentUser(res.data.data));
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to fetch an user profile image
 * - Dispatch updateUser action after successful response from server
 * @returns {function(*)}
 */
export function getUserProfileImage(id, updateStore = true) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/users/${id}/avatar`, 'get', {})
      .send()
      .then(res => {
        if (updateStore)
          dispatch(updateProfileImage(res.data.data));
        return Promise.resolve(res.data.data);
      }, error => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to delete an user profile image
 * @returns {function(*)}
 */
export function deleteUserProfileImage(id) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/users/${id}/avatar`, 'patch', {})
      .send()
      .then(res => {
        dispatch(deleteProfileImage());
        return Promise.resolve(res.data.data);
      }, error => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to update an user
 * - Dispatch updateUser action after successful response from server
 * @returns {function(*)}
 */
export function updateUserProfileRequest(userId, data) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/users/${userId}`, 'patch', data)
      .send()
      .then(res => {
        dispatch(updateCurrentUser(data));
        return Promise.resolve(data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}


export function uploadProfileImage(formData, id) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/users/${id}/avatar`, 'put', formData)
      .upload()
      .then(res => {
        return Promise.resolve(res);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function submitSurvey(userId, data) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `/users/${userId}/submitSurvey`, 'post', {surveyJson: data})
      .send()
      .then(res => {
        Auth.setSurveySubmit(true);
        dispatch(surveySubmit(true));
        return Promise.resolve(res.data.data.message);
      })
      .catch((err) => {
        notification.show('error', 'Submit Survey', err.response.data.error);
        return Promise.reject(err.response.data.error);
      });
  };
}

import React from 'react';
import {connect} from 'react-redux';
import {Col, Icon, Menu, Row, Tooltip} from 'antd';
import './CustomHeader.css';
import smallLogo from '../../../../assets/genaLogo.png';
import userGuide from '../../../../assets/userGuide.pdf';
import {ACTIONS, ROUTE_ACTIONS} from '../../../../utils/Constants';
import {Link} from 'react-router-dom';
import {toggleSideMenu} from '../../DashboardActions';

const {SubMenu} = Menu;


class CustomHeader extends React.Component {

  toggleSideMenu = () => {
    this.props.toggleSideMenu();
  };

  render() {

    let canViewUser = false;
    let canViewCase = false;

    if (this.props.userResources) {
      this.props.userResources.forEach(resource => {
        if (resource.name === 'user' && !this.props.currentRole.isAnalyst) {
          canViewUser = resource.READ !== null;
        } else if (resource.name === 'case') {
          canViewCase = resource.READ !== null;
        }
      });
    }

    const menuToggleIconType = this.props.showSideMenu ? 'menu-fold' : 'menu-unfold';

    return (
      <Row>
        <Col span={5} className="headerLogoSection">
          <img className='dashboard-application-logo-img' src={smallLogo} alt="Loading..."/>
          <span className="logoTitle">GENA</span>
        </Col>

        <Col span={19} className="headerPageTitleSection">

          {
            this.props.enableMenuToggle
              ? (
                <Tooltip placement="top" title="show/hide filter" trigger="hover">
                  <Icon type={menuToggleIconType} onClick={this.toggleSideMenu} className={"color-white"}/>
                </Tooltip>)
              : ('')

          }

          <span id="pageTitle" className="page-title">{this.props.pageTitle}</span>
          <span className="page-title">{this.props.secondaryTitle}</span>

          <Menu mode="vertical" onClick={this.props.handleMenuClick} theme="light" defaultSelectedKeys={['1']}
                className="dashboard-navigation-menu">
            <SubMenu className="sub-menu" key="navigationMenu" title={
              <Icon type="menu" className="sub-menu-icon"/>
            }>
              <Menu.Item key="dashboard"
                         className={(this.props.menu === 'dashboard' && 'ant-menu-item-selected')}>
                <Icon type="dashboard"/>
                <span className="nav-text">Dashboard</span>
                <Link to="/dashboard"/>
              </Menu.Item>
              {

                this.props.currentRole.isSuperAdmin
                  ?
                  (
                    <Menu.Item key="organizations"
                               className={(this.props.menu === 'organizations' && 'ant-menu-item-selected')}>
                      <Icon type="bank"/>
                      <span className="nav-text">Organizations</span>
                      <Link to={ROUTE_ACTIONS.ORGANIZATIONS}/>
                    </Menu.Item>
                  )
                  : ('')
              }
              {
                this.props.currentRole.isOrgManager
                  ?
                  (
                    <Menu.Item key="subscription"
                               className={(this.props.menu === 'subscription' && 'ant-menu-item-selected')}>
                      <Icon type="dollar"/>
                      <span className="nav-text">Subscriptions</span>
                      <Link to={ROUTE_ACTIONS.SUBSCRIPTIONS}/>
                    </Menu.Item>
                  )
                  : (
                    this.props.currentRole.isSuperAdmin
                      ?
                      (
                        <Menu.Item key="subscription"
                                   className={(this.props.menu === 'plan' && 'ant-menu-item-selected')}>
                          <Icon type="dollar"/>
                          <span className="nav-text">Subscriptions</span>
                          <Link to={ROUTE_ACTIONS.PLANS}/>
                        </Menu.Item>
                      )
                      : ('')
                  )
              }

              {
                !this.props.currentRole.isAnalyst
                  ?
                  (
                    <Menu.Item key="paymentHistory"
                               className={(this.props.menu === 'paymentHistory' && 'ant-menu-item-selected')}>
                      <Icon type="book"/>
                      <span className="nav-text">Payment History</span>
                      <Link to={ROUTE_ACTIONS.PAYMENT_HISTORY}/>
                    </Menu.Item>
                  )
                  : ('')

              }

              {
                canViewUser
                  ?
                  (<Menu.Item key="user"
                              className={(this.props.menu === 'user' && 'ant-menu-item-selected')}>
                    <Icon type="team"/>
                    <span className="nav-text">Users</span>
                    <Link to={ACTIONS.USER_LIST}/>
                  </Menu.Item>)
                  : ('')
              }

              {
                canViewCase
                  ?

                  (<Menu.Item key="case"
                              className={(this.props.menu === 'case' && 'ant-menu-item-selected')}>
                    <Icon type="file"/>
                    <span className="nav-text">Cases</span>
                    <Link to="/cases"/>
                  </Menu.Item>)
                  : ('')
              }

              <Menu.Item key="profile" className={(this.props.menu === 'profile' && 'ant-menu-item-selected')}>
                <Icon type="user"/>
                <span className="nav-text">Profile</span>
                <Link to={ACTIONS.PROFILE_VIEW}/>
              </Menu.Item>

              {/*{*/}
              {/*this.state.isSuperAdmin*/}
              {/*?*/}
              {/*(<Menu.Item key="permissions"*/}
              {/*className={(this.props.menu === 'permissions' && 'ant-menu-item-selected')}>*/}
              {/*<Icon type="file-protect"/>*/}
              {/*<span className="nav-text">Permissions Templates</span>*/}
              {/*<Link to="/permissions"/>*/}
              {/*</Menu.Item>)*/}
              {/*: ('')*/}
              {/*}*/}

              {
                this.props.currentRole.isSuperAdmin
                ? ('')
                : <Menu.Item key="guide">
                    <Icon type="question-circle" />
                    <span className="nav-text">User Guide</span>
                    <a href = {userGuide} target={"_blank"}></a>
                  </Menu.Item>
              }
              <Menu.Item key="logout">
                <Icon type="logout"/>
                <span className="nav-text">Logout</span>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
    loggedInUser: state.auth.loggedInUser,
    pageTitle: state.dashboard.pageTitle,
    secondaryTitle: state.dashboard.secondaryTitle,
    userResources: state.authorisation.userResources,
    enableMenuToggle: state.dashboard.enableMenuToggle,
    showSideMenu: state.dashboard.showSideMenu,
    currentRole: state.auth.currentRole
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleSideMenu: () => {
      dispatch(toggleSideMenu());
    }
  }
};

CustomHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomHeader);

export default CustomHeader;

import React, {Component} from 'react';
import './SearchCytogeneticResult.css';
import {Button, Col, Icon, Input, Popover, Row} from 'antd';

const {Search} = Input;

const globalSearchHelp = (
  <>
  <div>You can search for the following data</div>
  <div>1. Omim ID</div>
  <div>1. Omim Title</div>
  <div>2. Omim alternate symbols</div>
  <div>3. Disorders</div>
  <div>4. Gene symbol</div>
  <div>5. Gene alternate names</div>
  </>
);

class SearchCytogeneticResult extends Component {

  clearSearchFilter = () => {
    this.refs.caseSearchFilter.input.state.value = '';
    this.props.clearSearchFilter();
  };

  render() {
    return (
      <>
      <Row>
        <Col span={12}>
          <Search placeholder="Search..." onSearch={value => this.props.searchGenesUsingQuery(value)}
                  enterButton ref="caseSearchFilter"/>
        </Col>
        <Col span={2}>
          <Popover content={globalSearchHelp} title="Search" trigger="hover">
            <Icon type="info-circle" theme="twoTone" className={"search-info-button"}/>
          </Popover>
        </Col>

      </Row>

      {
        this.props.searchString !== '' ?
          (<Row className="case-dashboard-search-query">
            <Col span={24}>
                          <span>
                            <strong>Search:</strong>&nbsp;
                            {this.props.searchString}
                          </span>
              <Button type="primary" onClick={this.clearSearchFilter} className="case-dashboard-clear-search"
                      size="small">
                Clear
              </Button>
            </Col>
          </Row>) : ''
      }
      </>)
  }

}

export default SearchCytogeneticResult;
import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import PageNotFound from './modules/Authentication/components/PageNotFound/PageNotFound';
import SignIn from './modules/Authentication/components/SignIn/SignIn';
import SignUp from './modules/Authentication/components/SignUp/SignUp';
import ForgotPassword from './modules/Authentication/components/ForgotPassword/ForgotPassword';
import ForceResetPass from './modules/Authentication/components/ForceResetPassword/ForceResetPass';
import Dashboard from './modules/Dashboard/components/Dashboard/Dashboard';
import Home from './modules/Dashboard/components/Home/Home';
import PrivacyPolicy from './utils/components/PrivacyPolicy/PrivacyPolicy';
import {ACTIONS, ROUTE_ACTIONS, STATIC_URLS} from './utils/Constants';
import TermsOfUsage from "./utils/components/TermsOfUsage/TermsOfUsage";
import Disclaimer from "./utils/components/Disclaimer/Disclaimer";
import UserSurvey from "./modules/User/components/UserSurvey/UserSurvey";

/**
 * Used decide the component to render depending on the current route of the application
 * @returns {XML}
 * @constructor
 */
function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route path={ACTIONS.SIGN_IN} component={SignIn}/>
        <Route path={ACTIONS.SIGN_UP} component={SignUp}/>
        <Route path={ACTIONS.FORGOT_PASSWORD} component={ForgotPassword}/>
        <Route path={ACTIONS.FORCE_RESET_PASSWORD} component={ForceResetPass}/>
        <Route exact path={ROUTE_ACTIONS.SURVEY} component={UserSurvey} />
        <Route path={ROUTE_ACTIONS.DASHBOARD} component={(props) => <Dashboard {...props} menu={'dashboard'}/>}/>
        <Route path={ACTIONS.CHANGE_PASSWORD} component={(props) => <Dashboard {...props} menu={'profile'}/>}/>
        <Route path={ACTIONS.USER_PROFILE} component={(props) => <Dashboard {...props} menu={'profile'}/>}/>
        <Route path={ACTIONS.USER_DETAILS} component={(props) => <Dashboard {...props} menu={'user'}/>}/>
        <Route path={ROUTE_ACTIONS.USER_LIST} component={(props) => <Dashboard {...props} menu={'user'}/>}/>
        <Route exact path={ACTIONS.USER_LIST} component={(props) => <Dashboard {...props} menu={'user'}/>}/>
        <Route path={ROUTE_ACTIONS.CASE_DETAILS_UPDATE} component={(props) => <Dashboard {...props} menu={'case'}/>}/>
        <Route path={ROUTE_ACTIONS.CASE_DETAILS_VIEW} component={(props) => <Dashboard {...props} menu={'case'}/>}/>
        <Route path={ROUTE_ACTIONS.CASE_LIST} component={(props) => <Dashboard {...props} menu={'case'}/>}/>
        <Route exact path={ACTIONS.CASE_LIST} component={(props) => <Dashboard {...props} menu={'case'}/>}/>
        <Route path={ACTIONS.ADD_CASE} component={(props) => <Dashboard {...props} menu={'case'}/>}/>
        {/*<Route path={ACTIONS.PERMISSIONS} component={(props) => <Dashboard {...props} menu={'permissions'}/>}/>*/}
        <Route path={ROUTE_ACTIONS.SUBSCRIPTIONS} component={(props) => <Dashboard {...props} menu={'subscription'}/>}/>
        <Route path={ROUTE_ACTIONS.PLANS} component={(props) => <Dashboard {...props} menu={'plans'}/>}/>
        <Route path={ROUTE_ACTIONS.PAYMENT_HISTORY} component={(props) => <Dashboard {...props} menu={'paymentHistory'}/>}/>
        <Route path={ROUTE_ACTIONS.ORGANIZATIONS} component={(props) => <Dashboard {...props} menu={'organizations'}/>}/>
        <Route path={STATIC_URLS.PRIVACY_POLICY} component={PrivacyPolicy}/>
        <Route path={STATIC_URLS.DISCLAIMER} component={Disclaimer}/>
        <Route path={STATIC_URLS.TERMS_OF_USAGE} component={TermsOfUsage} />
        <Route component={PageNotFound}/>
      </Switch>
    </Router>
  );
}

export default Routes;
import {combineReducers} from 'redux';
import auth from './modules/Authentication/AuthenticationReducer';
import dashboard from './modules/Dashboard/DashboardReducer';
import authorisation from './modules/Authorisation/AuthorisationReducer';
import caseReducer from './modules/Case/CaseReducer';
import user from './modules/User/UserReducer';

// Combine all reducers into one root reducer
export default combineReducers({
  auth,
  authorisation,
  user,
  dashboard,
  caseReducer
});
exports.SYSTEM_ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ORG_MANAGER: 'ORG_MANAGER',
  ANALYST: 'ANALYST'
};

exports.HUMAN_READABLE_SYSTEM_ROLES = {
  'SUPER_ADMIN': 'Super Admin',
  'ORG_MANAGER': 'Organization Manager',
  'ANALYST': 'Analyst'
};

exports.RESOURCES = {
  USER: 'user',
  CASE: 'case',
};

exports.LOCATION_UNITS = [{
  id: "BASE",
  name: "Base"
}, {
  id: "KB",
  name: "Kb"
}, {
  id: "MB",
  name: "Mb"
}];

exports.TECHNOLOGY = [{
  id: "TECH1",
  name: "Technology 1"
}, {
  id: "TECH2",
  name: "Technology 2"
}, {
  id: "TECH3",
  name: "Technology 3"
}];

exports.GENOM_ASSEMBLY = [{
  id: "hg18",
  name: "NCBI36/hg18"
}, {
  id: "hg19",
  name: "GRCh37/hg19"
}, {
  id: "hg38",
  name: "GRCh38/hg38"
}];

exports.ANALYSIS_TYPE = [{
  id: 'Regions of homozygosity',
  name: 'Regions of homozygosity'
}, {
  id: 'Microdeletions',
  name: 'Microdeletions'
}];

exports.CHROMOSOMES = [
  {
    "id": "1",
    "name": "chr1"
  },
  {
    "id": "2",
    "name": "chr2"
  },
  {
    "id": "3",
    "name": "chr3"
  },
  {
    "id": "4",
    "name": "chr4"
  },
  {
    "id": "5",
    "name": "chr5"
  },
  {
    "id": "6",
    "name": "chr6"
  },
  {
    "id": "7",
    "name": "chr7"
  },
  {
    "id": "8",
    "name": "chr8"
  },
  {
    "id": "9",
    "name": "chr9"
  },
  {
    "id": "10",
    "name": "chr10"
  },
  {
    "id": "11",
    "name": "chr11"
  },
  {
    "id": "12",
    "name": "chr12"
  },
  {
    "id": "13",
    "name": "chr13"
  },
  {
    "id": "14",
    "name": "chr14"
  },
  {
    "id": "15",
    "name": "chr15"
  },
  {
    "id": "16",
    "name": "chr16"
  },
  {
    "id": "17",
    "name": "chr17"
  },
  {
    "id": "18",
    "name": "chr18"
  },
  {
    "id": "19",
    "name": "chr19"
  },
  {
    "id": "20",
    "name": "chr20"
  },
  {
    "id": "21",
    "name": "chr21"
  },
  {
    "id": "22",
    "name": "chr22"
  },
  {
    "id": "X",
    "name": "chrX"
  },
  {
    "id": "Y",
    "name": "chrY"
  }];

exports.ACTIONS = {
  "PROFILE_VIEW": "/profile",
  "USER_LIST": "/users",
  "USER_DETAILS": "/users/:id/update",
  "USER_PROFILE": "/profile",
  "CHANGE_PASSWORD": "/changePassword",
  "DASHBOARD": "/dashboard",
  "USER_DASHBOARD": "/userDashboard",
  "FORCE_RESET_PASSWORD": "/forceResetPassword",
  "RESET_PASSWORD": "/resetpassword",
  "FORGOT_PASSWORD": "/forgotPassword",
  "CASE_LIST": "/cases",
  "ADD_CASE": "/addCase",
  "PERMISSIONS": "/permissions",
  "SIGN_UP": "/signup",
  "SIGN_IN": "/signin",
  "LOGIN": "/login",
  "CASES": "/cases",
  "GENES": "/genes",
  "AUTO_SUGGEST_HPO_TERMS": "/genes/autoSuggestHpoTerms?hpoTerm=",
  "DOWNLOAD_CASE_ANALYSIS_SPREADSHEET": "/genes/downloadCaseAnalysisSpreadsheet",
  "DOWNLOAD_APPLICATION_SUMMARY_REPORT": "/dashboard/downloadApplicationSummaryReport"
};

exports.ROUTE_ACTIONS = {
  "CASE_DETAILS_VIEW": "/cases/:id/view",
  "CASE_DETAILS_UPDATE": "/cases/:id/update",
  "USER_LIST": "/users/:id/list",
  "CASE_LIST": "/cases/:id/list",
  "SUBSCRIPTIONS": "/subscriptions",
  "PLANS": "/plans",
  "PAYMENT_HISTORY": "/paymentHistory",
  "ORGANIZATIONS": "/organizations",
  "DASHBOARD": "/dashboard",
  "SURVEY": "/survey"
};

exports.ALLOWED_ACTIONS = ["profile", "changePassword", "dashboard", "forceResetPassword", "resetpassword", "forgotPassword", "permissions", "privacyPolicy", "signup", "signin", "login", "subscription", "plans", "paymentHistory", "organizations", "survey"];

exports.STATIC_URLS = {
  "CONTACT_US": "https://www.sivotecbioinformatics.com/contact-us",
  "SUPPORT": "https://www.sivotecbioinformatics.com/contact-us",
  "ABOUT_US": "https://www.sivotecbioinformatics.com/",
  "PRIVACY_POLICY": "/privacyPolicy",
  "TERMS_OF_USAGE": "/termsOfUsage",
  "DISCLAIMER": "/disclaimer",
};

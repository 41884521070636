import React from 'react';
import './SearchResult.css';

export function SearchResult(props) {

  const params = props.query.split('&');
  let clinicalFeature = '';
  let hpoHierarchies = '';

  params.forEach(param => {
    const json = param.split('=');

    if (json.length > 0) {
      switch (json[0]) {
        case 'clinicalFeatureSearchStr':
          clinicalFeature = json[1];
          break;

        case 'hpoQueryStr':
          hpoHierarchies = props.hpoTags.map(tag => tag.text === 'AND' || tag.text === 'OR' ? tag.text : (tag.name + ' (' + tag.hpoId + ')')).join(" ");
          break;

        default:
          break;
      }
    }

  });

  return (
    <div className='cytogenetic-search-result'>
      <div>
        {clinicalFeature !== '' ? <span><b>Clinical Feature:</b> {clinicalFeature}</span> : ''}
      </div>
      <div>
        {hpoHierarchies !== '' ? <span><b>HPO Search:</b> {hpoHierarchies}</span> : ''}
      </div>
    </div>
  )
}
/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import Auth from '../../utils/Auth';

// Export Constants
export const SIGN_IN = 'SIGN_IN';
export const SIGN_UP = 'SIGN_UP';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const FOTGOT_PASSWORD = 'FOTGOT_PASSWORD';
export const SIGN_OUT = 'SIGN_OUT';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const DELETE_USER_PROFILE = 'DELETE_USER_PROFILE';
export const SURVEY_SUBMIT = 'surveySubmit';

/**
 * Actions related to the authentication module
 */

/**
 * Action called after sign in api call
 * @param data
 * @returns {{type: string, user: (*|number), accessToken: *}}
 */
export function signIn(data) {
  return {
    type: SIGN_IN,
    user: data.user,
    accessToken: data.access_token
  };
}

/**
 * Action called after sign up api call
 * @param user
 * @returns {{type: string, user: *}}
 */
export function signUp(user) {
  return {
    type: SIGN_UP,
    user,
  };
}

/**
 * Action called after change password api call
 * @param data
 * @returns {{type: string, data: *}}
 */
export function changePassword(data) {
  return {
    type: CHANGE_PASSWORD,
    data
  }
}

/**
 * Action called after submit survey api call
 * @param data
 * @returns {{type: string, data: *}}
 */
export function surveySubmit(data) {
  return {
    type: SURVEY_SUBMIT,
    data
  }
}

/**
 * User logout/signout action
 * @param data
 * @returns {{type: string, data: *}}
 */
export function signOut() {
  Auth.logout();
  return {
    type: SIGN_OUT
  }
}

export function updateProfileImage(imageUrl) {
  return {
    type: UPDATE_USER_PROFILE,
    imageUrl
  }
}

export function deleteProfileImage() {
  return {
    type: DELETE_USER_PROFILE
  }
}

export function sessionExpired(error = 'Session expired') {
  return {
    type: SESSION_EXPIRED,
    error
  }
}
import React from 'react';

import {Button, Modal} from 'antd';

/**
 * User table component which shows all users data
 */
class MessageModal extends React.Component {

  closeModal = () => {
    this.setState({showModal: false});
  };

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      message: '',
      title: '',
      showOkButton: false,
      showCancelButton: false,
      handleOk: null,
      handleCancel: null,
      okText: 'OK',
      cancelText: 'Cancel'
    }
  }

  componentDidLoad() {
  }

  render() {
    let footerButtons = [];

    if (this.state.showCancelButton) {
      footerButtons.push(
        <Button key="cancel" type="primary" ghost onClick={() => {
          this.setState({showModal: false}, () => {
            if(this.state.handleCancel !== null)
              this.state.handleCancel();
          });
        }}>
          {this.state.cancelText}
        </Button>);
    }

    if (this.state.showOkButton) {
      footerButtons.push(
        <Button key="submit" type="primary" onClick={() => {
          this.setState({showModal: false}, () => {
            if(this.state.handleOk !== null)
              this.state.handleOk();
          });
        }}>
          {this.state.okText}
        </Button>);
    }

    return (
      <Modal
        centered={true}
        confirmLoading={false}
        visible={this.state.showModal}
        title={this.state.title}
        closable={false}
        footer={footerButtons}
      >
        {this.state.message}
      </Modal>
    )
  }

}

export default MessageModal;
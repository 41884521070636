import React from 'react';
import ReactTable from 'react-table';
import {connect} from 'react-redux';

import {Button, Icon, Row, Tooltip} from 'antd';
import {setPageTitle} from "../../../Dashboard/DashboardActions";
import {getAllCasesRequest, updateCaseRequest} from "../../CaseApiActions";
import {GENOM_ASSEMBLY, RESOURCES} from '../../../../utils/Constants';

import './CaseTable.css';
import UtilHelper from "../../../../utils/UtilHelper";

/**
 * User table component which shows all users data
 */
class CaseTable extends React.Component {

  componentDidMount = () => {
    const {id } = this.props.match.params;

    this.props.getAllCases(id).then(() => {
    }, (error) => {
    });

    let title = '';

    if(id) {
      title = ' (' + decodeURI(this.props.location.search.substr(1)) + ')';
    }

    this.props.updatePageTitle(this.props.pageTitle + title, false);
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {id} = this.props.match.params;


    if (this.reloadPage) {
      this.reloadPage = false;
      this.props.getAllCases(id).then(() => {
      }, (error) => {
      });
    } else {

      if(!this.props.match.params.hasOwnProperty('id') && prevProps.match.params.hasOwnProperty('id')) {
        let title = '';

        if(id) {
          title = ' (' + decodeURI(this.props.location.search.substr(1)) + ')';
        }

        this.props.updatePageTitle(this.props.pageTitle + title, false);

        this.props.getAllCases(id).then(() => {
        }, (error) => {
        });
      }
    }
  };

  viewCase = (id) => {
    this.reloadPage = true;
    this.props.history.push(`/cases/${id}/view`);
  };

  showCreateCase = () => {
    this.reloadPage = true;
    this.props.history.push('/addCase');
  };

  constructor(props) {
    super(props);

    this.reloadPage = false;

    this.state = {
      loading: false,
    };
  }

  render() {
    const {cases, userResources} = this.props;

    const resource = userResources.find(resource => resource.name === RESOURCES.CASE);

    const columns = [{
      Header: 'Analysis Type',
      accessor: 'queryType',
      id: 'queryType',
      resizable: false
    }, {
      Header: 'Accession #',
      accessor: 'nickName',
      resizable: false,
      id: 'nickName',
      Cell: row => {
        return <span
          className={
            resource.READ === 'ANY' ||
            (resource.READ === 'OWN' && this.props.loggedInUser.id === row.original.createdBy)
              ? 'case-table-case-name'
              : ''}>
            {row.original.nickName}
          </span>
      }
    }, {
      Header: '# Segments',
      id: 'noOfFragments',
      maxWidth: 100,
      accessor: d => d.Fragments ? d.Fragments.length : 0,
      resizable: false
    }, {
      Header: 'Genome Assembly',
      id: 'genomeAssembly',
      filterable: false,
      resizable: false,
      maxWidth: 150,
      Cell: props => {
        const assembly = GENOM_ASSEMBLY.find(assembly => assembly.id === props.original.genomeAssembly);
        if (assembly !== undefined)
          return assembly.name;
        return "";
      }
    }, {
      Header: '# Versions',
      id: 'CaseVersions',
      filterable: false,
      resizable: false,
      maxWidth: 100,
      accessor: d => d.CaseVersions ? d.CaseVersions.length : 0
    }, {
      Header: 'Created Date',
      id: 'createdDate',
      maxWidth: 200,
      filterable: false,
      resizable: false,
      accessor: d => new Date(d.createdAt).getTime(),
      Cell: d => new Date(d.original.createdAt).toLocaleString('en-us')
    }, {
      Header: 'Action',
      accessor: 'id',
      filterable: false,
      resizable: false,
      maxWidth: 80,
      sortable: false,
      Cell: props => {
        return (
          <div className="text-align-center">
            {
              props.original.isVisible
                ? (
                  <Tooltip placement="topLeft" title="View case details">
                    <Icon type="eye" onClick={() => this.viewCase(props.original.id)} className="view-case-button"/>
                  </Tooltip>
                )
                : (
                  <Tooltip placement="topLeft" title="Pending Payment Approval">
                    <Icon type="exclamation-circle" className="case-danger"/>
                  </Tooltip>
                )
            }

          </div>)
      }
    }];

    if(!this.props.currentRole.isAnalyst) {
      columns.splice(2, 0, {
        Header: 'Created By',
        accessor: 'createdByUser',
        resizable: false
      });
    }

    if(this.props.currentRole.isSuperAdmin) {
      columns.splice(3, 0, {
        Header: 'Organization',
        accessor: d => d.Organization.name,
        resizable: false,
        id: 'Organization'
      });
    }

    return (
      <div className="sivotec-page-content">
        <Row className="add-new-case">
          {
            resource && resource.CREATE !== null ? (
              <Button type={'primary'} onClick={this.showCreateCase}>Add Case</Button>) : (<></>)
          }
        </Row>
        <Row>
          <ReactTable
            filterable
            defaultFilterMethod={UtilHelper.filterColumnReactTable}
            defaultPageSize={10}
            noDataText='No records present. Click Add case to create a new case'
            data={cases}
            columns={columns}
            className="-striped -highlight"
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: (e, handleOriginal) => {
                  if (column.id === 'nickName' && rowInfo) {
                    if (resource.READ === 'ANY' || (resource.READ === 'OWN' && this.props.loggedInUser.id === rowInfo.original.createdBy)) {
                      this.reloadPage = true;
                      this.props.history.push(`/cases/${rowInfo.original.id}/update`);
                    }
                  }
                }
              }
            }}
          />
        </Row>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    cases: state.caseReducer.cases,
    userResources: state.authorisation.userResources,
    loggedInUser: state.auth.loggedInUser,
    currentRole: state.auth.currentRole
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCases: (id) => {
      return dispatch(getAllCasesRequest(id)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    updateCase: (caseId, data) => {
      return dispatch(updateCaseRequest(caseId, data)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    updatePageTitle: (pageTitle, enablePageTitle) => {
      dispatch(setPageTitle(pageTitle, enablePageTitle));
    },
  }
};

CaseTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseTable);

export default CaseTable;
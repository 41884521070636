import {SYSTEM_ROLES} from './Constants';

class UtilHelper {

  static numberToCurrency = (number) => {
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  };

  static validateMobileNumber = (rule, value, callback) => {
    const regex = /\d+/g;

    if (value && value.length > 0 && (!regex.test(value) || value.length !== 10)) {
      callback("Please enter valid mobile number");
    }
    callback();
  };

  static validatePassword = (rule, value, callback) => {
    const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*_(){|}~<=>`+\-.:;"/?[\\\]])(?=.*[a-zA-Z0-9])[a-zA-Z0-9!@#$%^&*_(){|}~<=>`+\-.:;"/?[\\\]]/;

    if (value && !regex.test(value)) {
      callback("Password must contain one alphabet letter, one numeric digit, and one special character");
    } else if (value && value.indexOf(' ') >= 0) {
      callback("Spaces not allowed in the password");
    } else {
      callback();
    }
  };

  static validateUserName = (rule, value, callback) => {
    const regex = /^[a-zA-Z '.-]*$/;

    if (value && !regex.test(value)) {
      callback("Please enter valid name");
    } else if (value && value.trim().length === 0) {
      callback("Only spaces are not allowed");
    } else {
      callback();
    }
  };

  static validateBlankSpaces = (rule, value, callback) => {

    if (value && value.trim().length === 0) {
      callback("Only spaces are not allowed");
    } else {
      callback();
    }
  };

  static validateNegativeValue = (rule, value, callback) => {

    if (value && value < 0) {
      callback("Negative number not allowed");
    } else {
      callback();
    }
  };

  static validateNegativeAndZeroValue = (rule, value, callback) => {

    if (value && value <= 0) {
      callback("Enter value greater than 0");
    } else {
      callback();
    }
  };

  static validateFloatValue = (rule, value, callback) => {

    if (value && value > 0) {
      value.indexOf('.') >= 0 ? callback("Decimal number not allowed") : callback();
    } else {
      callback();
    }
  };

  static isFloat = (n) => {
    return n === +n && n !== (n | 0);
  };

  static stringBeforeDecimal = (n) => {
    return n.split('.')[0];
  };

  static asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  static filterColumnReactTable = (filter, row) => {
    if (!row[filter.id])
      return false;
    return String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())
  };

  static getCurrentRole = (user) => {
    let currentRole = {
      isSuperAdmin: false,
      isOrgManager: false,
      isAnalyst: false
    };

    user && user.roles.forEach(role => {
      if (role.name === SYSTEM_ROLES.SUPER_ADMIN) {
        currentRole.isSuperAdmin = true;
      }

      if (role.name === SYSTEM_ROLES.ANALYST)
        currentRole.isAnalyst = true;

      if (role.name === SYSTEM_ROLES.ORG_MANAGER)
        currentRole.isOrgManager = true;
    });

    return currentRole;
  };

  static sortJsonArray = (array, key) => {
    return array.sort(function (a, b) {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });
  }
}

export default UtilHelper;

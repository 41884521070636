import React from 'react';
import {connect} from 'react-redux';
import {
  cancelCurrentSubscriptionPlan,
  getCurrentSubscriptionPlan,
  getPayAsYouGoMetaInfo,
  getSubscriptionPlans
} from "../../BillingApiActions";
import {setPageTitle, setPageSecondaryTitle} from "../../../Dashboard/DashboardActions";
import './SubscriptedPlan.css';
import {Button} from 'antd';
import PurchasePlan from "../PurchasePlan/PurchasePlan";
import MessageModal from "../../../../utils/components/MessageModal/MessageModal";
import GenaLoader from "../../../../utils/components/GenaLoader/GenaLoader";
import UtilHelper from "../../../../utils/UtilHelper";

/**
 * User table component which shows all users data
 */
class SubscriptionPlan extends React.Component {

  getPlanList = () => {
    this.setState({showLoader: true}, () => {
      this.props.getSubscriptionPlans().then((res) => {
        this.setState({subscriptionPlans: res}, () => {
          this.props.getCurrentSubscriptionPlan().then(res => {
            this.setState({currentPlan: res}, () => {
              this.props.updateSecondaryPageTitle(`Current Subscription Plan: ${res.name}`);

              this.props.getPayAsYouGoMetaInfo().then(res => {
                this.setState({payAsYouGo: res, showLoader: false});
              }, err => {
                this.setState({showLoader: false});
              });
            });
          }, err => {
            this.setState({showLoader: false});
          });
        });
      }, error => {
        this.setState({showLoader: false});
      });

    });
  };

  cancelSubscription = () => {
    this.refs.messageModal.setState({
      showModal: true, message: 'Are you sure you want to cancel this subscription?', title: 'Cancel Subscription',
      showOkButton: true,
      showCancelButton: true,
      handleOk: () => {
        this.cancelCurrentSubscription();
      },
      handleCancel: () => {
      },
      okText: 'Yes',
      cancelText: 'No'
    });

  };

  cancelCurrentSubscription = () => {
    this.setState({showLoader: true}, () => {
      this.props.cancelCurrentSubscriptionPlan().then(res => {
        this.setState({showLoader: false}, () => {
          this.refs.messageModal.setState({
            showModal: true,
            message: 'You have successfully unsubscribed from your current plan; however you will be able to access all available features until the next billing period.',
            title: 'Cancel Subscription',
            showOkButton: true,
            showCancelButton: false,
            handleOk: () => {
              this.getPlanList();
            },
            okText: 'Ok'
          });
        });
      }, error => {
        this.setState({showLoader: false});
      });

    });
  };

  constructor(props) {
    super(props);

    this.state = {
      subscriptionPlans: [],
      payAsYouGo: null,
      currentPlan: null,
      showLoader: false
    }
  }

  componentDidMount() {
    this.props.updatePageTitle(this.props.pageTitle, false);
    this.getPlanList();
  }

  render() {
    const {currentPlan} = this.state;

    return (
      <div>

        <div className="subscription-container">
          {
            this.state.subscriptionPlans.map(plan => {
              let isCurrentPlan = false;
              let classes = "subscription-plan ";
              if (currentPlan && currentPlan.id === plan.id) {
                isCurrentPlan = true;
                classes += "current-plan ";
              }

              let planAmount = plan.payByPaypalOnly ? plan.paypalAmount : plan.amount;
              planAmount = planAmount/100;
              let discountedAmount = planAmount;

              if(!plan.isPromotional && !plan.isDefault && plan.Coupon) {
                discountedAmount = ((planAmount * (100 - plan.Coupon.percentOff))/100).toFixed(2);
              }

              if (currentPlan && currentPlan.id === plan.id) {
                if (currentPlan.Subscription.Coupon) {
                  discountedAmount = ((planAmount * (100 - currentPlan.Subscription.Coupon.percentOff)) / 100).toFixed(2);
                } else {
                  discountedAmount = planAmount;
                }
              }

              classes += this.state.payAsYouGo && currentPlan && currentPlan.noOfCases !== null ? "maximum-subscription-plan " : "minimum-subscription-plan";

              return (
                <div className={classes} key={plan.id}>
                  {
                    currentPlan && currentPlan.id === plan.id &&
                    <div className="current-plan-title">Current plan</div>
                  }

                  <div className="subscription-plan-details">
                    <div className="color-white">
                      {plan.name}
                    </div>


                    <div className="subscription-amount-info">
                      <span className="align-top color-gray">$</span>
                      <span className="color-white plan-amount">
                        {!plan.isOfflinePayment
                          ?  discountedAmount
                          :  currentPlan && currentPlan.id === plan.id ? Number.parseFloat(currentPlan.Subscription.offlineAmount)/100 : (discountedAmount)
                        }
                      </span>
                      { currentPlan && currentPlan.id === plan.id && plan.isOfflinePayment ?<span></span> : <span className="color-gray">/{plan.interval}</span> }
                    </div>

                    {currentPlan && currentPlan.id === plan.id && currentPlan.Subscription.Coupon && (
                      <div className="subscription-actual-plan-amount">
                        <span className="color-white">{currentPlan.Subscription.Coupon.percentOff}% off on </span>
                        <span className="color-white actual-plan-amount">${planAmount}</span>
                      </div>
                    )}

                    {currentPlan && currentPlan.id !== plan.id && plan.Coupon && (
                      <div className="subscription-actual-plan-amount">
                        <span className="color-white">{plan.Coupon.percentOff}% off on </span>
                        <span className="color-white actual-plan-amount">${planAmount}</span>
                      </div>
                    )}
                    <div className="color-white">
                      {plan.noOfCases !== null ? plan.noOfCases + (plan.noOfCases > 1 ? ' Free Cases' : ' Free Case') : 'Unlimited Cases'}
                    </div>

                    {
                      isCurrentPlan ?
                        <>
                        <div className="color-white margin-top-10">
                          Status: {currentPlan.Subscription.status}
                        </div>
                        {
                          currentPlan.Subscription.status === 'incomplete'
                            ?
                            <div className="color-white margin-top-10">We cannot complete the payment at this time. We will keep trying, and it may take up to 3 business days to reflect in your account.</div>
                            :
                            <div></div>
                        }

                        <div className="color-white margin-top-10">
                          Start Date: {new Date(currentPlan.Subscription.startTimestamp * 1000).toLocaleDateString('en-us')}
                        </div>

                        <div className="color-white margin-top-10">
                          {currentPlan.Subscription.cancelAtPeriodEnd ? 'End Date' : 'Renew On'}: {new Date(currentPlan.Subscription.endTimestamp * 1000).toLocaleDateString('en-us')}
                        </div>

                        {
                          currentPlan.Subscription.cancelAtPeriodEnd
                            ? <div className="color-white margin-top-10">Expires On: {new Date(currentPlan.Subscription.endTimestamp * 1000).toLocaleDateString('en-us')}</div>
                            : (!plan.isDefault && currentPlan && currentPlan.Subscription.status !== 'incomplete' ?  <Button type="danger" className="cancel-subscription" onClick={this.cancelSubscription}>Cancel Subscription</Button> : <></>)
                        }


                        </> : (
                          !plan.isPromotional && !plan.isDefault
                          ? <PurchasePlan planId={plan.id} subscription={true} refreshPlanList={this.getPlanList}
                                          paypalPayment={plan.payByPaypalOnly}
                                          amount={discountedAmount}
                                          coupon={plan.Coupon ? plan.Coupon : null}
                            />
                          : <></>
                        )
                    }
                  </div>

                </div>
              )
            })
          }
        </div>

        <GenaLoader showLoader={this.state.showLoader}/>

        <MessageModal
          ref="messageModal"/>
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {}
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updatePageTitle: (pageTitle, enablePageTitle) => {
      dispatch(setPageTitle(pageTitle, enablePageTitle));
    },

    updateSecondaryPageTitle: (pageTitle) => {
      dispatch(setPageSecondaryTitle(pageTitle));
    },

    getSubscriptionPlans: () => {
      return dispatch(getSubscriptionPlans()).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    getPayAsYouGoMetaInfo: () => {
      return dispatch(getPayAsYouGoMetaInfo()).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    getCurrentSubscriptionPlan: () => {
      return dispatch(getCurrentSubscriptionPlan()).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    cancelCurrentSubscriptionPlan: () => {
      return dispatch(cancelCurrentSubscriptionPlan()).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

  }
};

SubscriptionPlan = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionPlan);


export default SubscriptionPlan;

/**
 * @author Punit Vajpeyi <punit@wemotiveforge.com>
 */

import React from 'react';
import {Button, Checkbox, Form, Input, Layout} from 'antd';
import {connect} from 'react-redux';
import {Redirect} from "react-router-dom";
import {ROUTE_ACTIONS, STATIC_URLS, ACTIONS} from '../../../../utils/Constants';
import './ForceResetPassword.css';
import logo from '../../../../assets/logo.png';
import {changePasswordRequest} from "../../AuthenticationApiActions";
import UtilHelper from "../../../../utils/UtilHelper";
import CustomFooter from "../../../Dashboard/components/CustomFooter/CustomFooter";
import GenaLoader from "../../../../utils/components/GenaLoader/GenaLoader";

const {Content, Footer} = Layout;
const FormItem = Form.Item;

/*
 * Form to update the password of the user.
 * This component is shown only when the current password is temporary password
 */
class ForceResetPasswordForm extends React.Component {

  hideLoader = () => {
    this.setState({showLoader: false});
  };

  /**
   * This method is triggered when form is submitted by the user
   * @param e
   */
  handleSubmit = e => {
    e.preventDefault();

    this.setState({submitClicked: true}, () => {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          if (this.state.termsAndConditionSelected) {
            this.setState({showLoader: true}, () => {
              values.userId = this.props.loggedInUser.id;
              this.props.onPasswordChange(values).then(res => {
              }, err => {
                this.hideLoader();
              });
            });
          } else {
          }
        }
      });
    });
  };
  /**
   * This method is used to verify if the password are
   * @param rule
   * @param value
   * @param callback
   */
  checkPassword = (rule, value, callback) => {
    if (value && value !== this.props.form.getFieldValue('password')) {
      callback("The passwords don't match");
    } else {
      callback();
    }
  };
  /**
   * Called when user clicks on accept terms and condition policy
   * @param e
   */
  acceptTermsCondition = e => {
    this.setState({termsAndConditionSelected: e.target.checked});
  };

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      termsAndConditionSelected: false,
      submitClicked: false,
      showLoader: false
    };
  }

  /**
   * Render the UI of the reset password form
   * @returns {XML}
   */
  render() {
    const {getFieldDecorator} = this.props.form;

    const fieldValidationRules = {
      password: {
        rules: [
          {required: true, message: "Please input your new password"},
          {validator: UtilHelper.validateBlankSpaces},
          {validator: UtilHelper.validatePassword}
        ]
      },
      newPassword: {
        rules: [
          {required: true, message: "Please re-enter your new password"},
          {validator: UtilHelper.validateBlankSpaces},
          {validator: this.checkPassword, message: "Password mismatch"}
        ]
      },
      acceptCondition: {
        rules: []
      },
    };


    if (this.props.loggedInUser && !this.props.loggedInUser.isSystemGeneratedPassword) {
      if (this.props.loggedInUser && !this.props.loggedInUser.isSurveySubmitted) {
        return (<Redirect to={ROUTE_ACTIONS.SURVEY}/>);
      } else {
        if(this.props.currentRole && this.props.currentRole.isSuperAdmin)
          return (<Redirect to={ROUTE_ACTIONS.DASHBOARD}/>);
        else
          return (<Redirect to={ACTIONS.CASES}/>);
      }
    } else {
      return (
        <Layout>
          <Content>
            <div className='reset-password-form-outer-div'>
              <div className='reset-password-form-inner-div'>
                <Form onSubmit={this.handleSubmit}>
                  <FormItem>
                    <div className='application-logo-img-div'>
                      <img className='application-logo-img' src={logo} alt="Loading..."/>
                    </div>
                  </FormItem>
                  <Form.Item label="New Password">
                    {getFieldDecorator('password', fieldValidationRules.password)(
                      <Input type="password" placeholder="Enter new password"/>
                    )}
                  </Form.Item>
                  <Form.Item label="Re-enter New Password">
                    {getFieldDecorator('newPassword', fieldValidationRules.newPassword)(
                      <Input type="password" placeholder="Re-enter new password"/>
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('acceptCondition', fieldValidationRules.acceptCondition)(
                      <div>
                        <Checkbox onChange={this.acceptTermsCondition}></Checkbox>
                        <span>I accept the <a href={STATIC_URLS.TERMS_OF_USAGE} target='_blank' rel="noopener noreferrer">terms and conditions</a></span>
                      </div>
                    )}
                  </Form.Item>
                  {
                    this.state.submitClicked && !this.state.termsAndConditionSelected ?
                      <span
                        className="error-terms-condition-not-selected">Please accept the terms and conditions</span> : ''
                  }

                  <FormItem>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="reset-password-form-button">
                      Set Password
                    </Button>
                  </FormItem>
                </Form>
              </div>
            </div>

            <GenaLoader showLoader={this.state.showLoader}/>
          </Content>
          <Footer>
            <CustomFooter/>
          </Footer>
        </Layout>
      )
    }
  }

}

let FirstTimeLogin = Form.create()(ForceResetPasswordForm);

const mapStateToProps = state => {
  return {
    loggedInUser: state.auth.loggedInUser,
    currentRole: state.auth.currentRole
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onPasswordChange: (data) => {
      return dispatch(changePasswordRequest(data)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    }
  }
};

FirstTimeLogin = connect(
  mapStateToProps,
  mapDispatchToProps
)(FirstTimeLogin);


export default FirstTimeLogin;

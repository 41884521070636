/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

// Export Constants
export const PAGE_TITLE = 'PAGE_TITLE';
export const PAGE_SECONDARY_TITLE = 'PAGE_SECONDARY_TITLE';
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';
export const SHOW_SIDE_MENU = 'SHOW_SIDE_MENU';

/**
 * Actions related to the authentication module
 */

/**
 * Action called after page changed
 * @param data
 * @returns {{type: string, pageTitle: string}}
 */
export function setPageTitle(data, enableMenuToggle) {
  document.title = data;

  return {
    type: PAGE_TITLE,
    pageTitle: data,
    enableMenuToggle: enableMenuToggle
  };
}

/**
 * Action called after page changed
 * @param data
 * @returns {{type: string, secondaryTitle: string}}
 */
export function setPageSecondaryTitle(data) {

  return {
    type: PAGE_SECONDARY_TITLE,
    secondaryTitle: data
  };
}

/**
 * Action called after user clicks on show hide side menu
 * @returns {{type: string}}
 */
export function toggleSideMenu() {

  return {
    type: TOGGLE_SIDE_MENU
  };
}

/**
 * Action called to open the side menu by default
 * @returns {{type: string}}
 */
export function showSideMenu() {

  return {
    type: SHOW_SIDE_MENU
  };
}


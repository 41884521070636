/**
 * @author Punit Vajpeyi <punit@wemotiveforge.com>
 */

import React from 'react';
import {connect} from 'react-redux';

import {Badge, Button, Col, Form, Icon, Input, Row, Upload} from 'antd';
import './UserProfile.css';
import MessageModal from "../../../../utils/components/MessageModal/MessageModal";

import ConstantMessages from '../../../../utils/Strings';
import Notification from '../../../../utils/Notification';
import UtilHelper from '../../../../utils/UtilHelper';
import {
  deleteUserProfileImage,
  getUserDetailsRequest,
  getUserProfileImage,
  updateUserProfileRequest,
  uploadProfileImage
} from "../../UserApiActions";
import {setPageTitle} from "../../../Dashboard/DashboardActions";
import {updateProfileImage} from "../../../Authentication/AuthenticationActions";
import MobileNumber from "../../../../utils/components/MobileNumber/MobileNumber";
import {ACTIONS} from '../../../../utils/Constants';

const FormItem = Form.Item;
const notification = new Notification();

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

class UserProfileForm extends React.Component {

  onCancel = () => {
    window.history.back();
  };
  removeProfileImage = () => {
    this.props.deleteUserProfileImage(this.props.loggedInUser.id).then((res) => {
    }, (error) => {
    });
  };
  updateMobileNumber = (phone, validPhoneNumber) => {
    this.setState({phone, validPhoneNumber});
  };
  handleSubmit = e => {
    this.setState({formSubmit: true});
    e.preventDefault();
    this.props.form.validateFields((err, formData) => {
      if (!err && this.state.validPhoneNumber) {
        formData.phone = this.state.phone && this.state.phone.length > 4 ? this.state.phone : '';
        this.props.updateUserProfile(this.props.loggedInUser.id, formData).then((res) => {
          if (res.hasOwnProperty('phone'))
            this.setState({phone: res.phone});

          //TODO: Show modal instead of sweet notification
          this.refs.messageModal.setState({
            showModal: true,
            title: ConstantMessages.updateUserNotificationTitle,
            message: ConstantMessages.updateUserSuccessMessage,
            showOkButton: true,
            handleOk: () => {
            },
            okText: 'Ok',
            cancelText: 'Cancel'
          });
        }, (error) => {
        });
      }
    });
  };
  navigateToChangePassword = () => {
    this.props.history.push(ACTIONS.CHANGE_PASSWORD);
  };

  getAdditionUserDetails = () => {
    this.props.getUserProfileImage(this.props.loggedInUser.id).then(() => {
    }, (error) => {
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      phone: '',
      formSubmit: false,
      validPhoneNumber: true,
      subscriptionPlans: []
    }
  }

  componentWillReceiveProps(nextProps) {

    if (this.props.loggedInUser) {
      if (this.props.loggedInUser.phone !== nextProps.loggedInUser.phone) {
        if (nextProps.loggedInUser.phone !== null)
          this.setState({phone: nextProps.loggedInUser.phone, validPhoneNumber: true});
        else
          this.setState({phone: '', validPhoneNumber: true});
      } else if (this.state.phone === '' && this.props.loggedInUser.phone) {
        if (this.props.loggedInUser.phone !== null)
          this.setState({phone: this.props.loggedInUser.phone, validPhoneNumber: true});
        else
          this.setState({phone: '', validPhoneNumber: true});
      }
    }
  }

  componentDidMount() {

    this.props.getUserDetailsRequest(this.props.loggedInUser.id).then((res) => {
      if (res.hasOwnProperty('phone') && res.phone) {
        this.setState({phone: res.phone}, () => {
          this.getAdditionUserDetails();
        })
      } else {
        this.getAdditionUserDetails();
      }

    }, (error) => {
    });

    this.props.updatePageTitle(this.props.pageTitle, false);
  }

  render() {

    const self = this;

    const uploadOptions = {
      showUploadList: false,

      beforeUpload(file) {
        let allowedFiles = ['image/jpeg', 'image/jpg', 'image/png'];

        let isImageFile = allowedFiles.indexOf(file.type) >= 0;
        if (!isImageFile) {
          notification.show('error', 'Incorrect file format', 'You can only upload file with png/jpg/jpeg extension');
        } else {

          const formData = new FormData();
          formData.append('file', file);

          self.props.uploadProfileImage(formData, self.props.loggedInUser.id).then((res) => {
            getBase64(file, imageUrl => {
              self.props.onProfileImageUpdate(imageUrl);
              notification.show('info', ConstantMessages.imageUploadSuccessful, 'file uploaded successfully');
            });
          }, error => {
            notification.show('error', ConstantMessages.imageUploadFailed, 'file uploaded failed');
          });
        }
        return false;
      }
    };

    const {getFieldDecorator} = this.props.form;

    const {loggedInUser} = this.props;

    const fieldValidationRules = {
      username: {
        initialValue: loggedInUser.username,
        rules: [
          {type: 'email', message: ConstantMessages.enterValidEmailAddress},
          {required: true, message: ConstantMessages.emailAddressRequired}
        ],
      },
      firstName: {
        initialValue: loggedInUser.firstName,
        rules: [
          {required: true, message: ConstantMessages.firstNameRequired},
          {validator: UtilHelper.validateUserName}
        ]
      },
      lastName: {
        initialValue: loggedInUser.lastName,
        rules: [
          {required: true, message: ConstantMessages.lastNameRequired},
          {validator: UtilHelper.validateUserName}
        ]
      }
    };

    let style = {};

    if (!this.props.currentRole.isSuperAdmin) {
      fieldValidationRules['organization'] = {
        initialValue: loggedInUser.organization !== null ? loggedInUser.organization.name : '',
        rules: [
          {required: true, message: ConstantMessages.organizationRequired}
        ],
      }
    } else {
      style = {display: 'none'};
    }

    return (
      <div className="profile-container">

        <Row>
          <Col span={6} className="padding-16 text-align-center">
            <img src={this.props.profileImg} alt="avatar" className="width-200 height-200 userProfile"/>
            <Badge count={"X"} onClick={this.removeProfileImage} className="remove-profile-pic">
            </Badge>

            <div>
              <Upload {...uploadOptions} className="upload-profile-pic">
                <Button>
                  <Icon type="upload"/> Upload Photo
                </Button>
              </Upload>
              <div>
                <i>Note: Allowed files formats png, jpg, jpeg</i>
              </div>
            </div>

            <Button type="primary" ghost className={'change-password'} onClick={this.navigateToChangePassword}>
              Change password
            </Button>
          </Col>
          <Col span={18}>
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col span={12} className="padding-16">
                  <Form.Item label="First Name">
                    {getFieldDecorator('firstName', fieldValidationRules.firstName)(
                      <Input placeholder="Enter first name" maxLength={50}/>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12} className="padding-16">
                  <Form.Item label="Last Name">
                    {getFieldDecorator('lastName', fieldValidationRules.lastName)(
                      <Input placeholder="Enter last name" maxLength={50}/>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12} className="padding-16">
                  <Form.Item label="Email address">
                    {getFieldDecorator('username', fieldValidationRules.username)(
                      <Input placeholder=""/>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12} className="padding-16 internal-phone-number-container">
                  <MobileNumber phone={this.state.phone}
                                updateMobileNumber={this.updateMobileNumber}/>
                  {
                    this.state.formSubmit && !this.state.validPhoneNumber ?
                      <div className="phone-error">Please enter valid mobile number</div> : ''
                  }
                </Col>
              </Row>
              <Row>
                <Col style={style} span={12} className="padding-16">
                  <Form.Item label="Organization name">
                    {getFieldDecorator('organization', fieldValidationRules.organization)(
                      <Input placeholder="" disabled={this.props.currentRole.isAnalyst}/>
                    )}
                  </Form.Item>
                </Col>

                <Col offset={12} span={6} className="padding-16">
                  <div className="blank-div"></div>
                  <FormItem>
                    <Button
                      type="default"
                      htmlType="button"
                      onClick={this.onCancel}
                      className="form-button">
                      Cancel
                    </Button>
                  </FormItem>
                </Col>
                <Col span={6} className="padding-16">
                  <div className="blank-div"></div>
                  <FormItem>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="form-button">
                      Update
                    </Button>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <MessageModal
          ref="messageModal"
        />

      </div>
    );
  }
}


let UserProfile = Form.create()(UserProfileForm);

const mapStateToProps = state => {
  return {
    loggedInUser: state.auth.loggedInUser,
    profileImg: state.auth.profileImg,
    currentRole: state.auth.currentRole
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserDetailsRequest: (id) => {
      return dispatch(getUserDetailsRequest(id)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    getUserProfileImage: (id) => {
      return dispatch(getUserProfileImage(id)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    deleteUserProfileImage: (id) => {
      return dispatch(deleteUserProfileImage(id)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    updateUserProfile: (id, data) => {
      return dispatch(updateUserProfileRequest(id, data)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    },

    updatePageTitle: (pageTitle, enablePageTitle) => {
      dispatch(setPageTitle(pageTitle, enablePageTitle));
    },

    onProfileImageUpdate: (imageUrl) => {
      dispatch(updateProfileImage(imageUrl));
    },

    uploadProfileImage: (formData, id) => {
      return dispatch(uploadProfileImage(formData, id)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });
    }
  }
};

UserProfile = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile);

export default UserProfile;

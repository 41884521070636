/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import React from 'react';
import {connect} from 'react-redux';
import {Button, Divider, Form, Icon, Input, Modal, Select} from 'antd';
import './AddFragment.css';
import UtilHelper from "../../../../utils/UtilHelper";
import {CHROMOSOMES} from '../../../../utils/Constants';

const {Item: FormItem} = Form;
const {Option} = Select;
const {TextArea} = Input;

let AddFragmentForm = Form.create()(class extends React.Component {

  updateSegmentsValue = (event) => {
    this.setState({segments: event.target.value, segmentInputError: ''});
  };

  loadSegmentList = () => {
    let {segments} = this.state;

    // TODO: regex match to verify chromosome format is correct or not
    if (!segments) {
      this.setState({segmentInputError: 'Please input segments'});
    } else if (segments && !segments.match(/^([ ]*chr[X|Y|[0-9]{1,2}]*[ ]*:[ ]*[0-9.,]+[ ]*-[ ]*[0-9.,]+[ ]*\s*[,|\n])*([ ]*chr[X|Y|[0-9]{1,2}]*[ ]*:[ ]*[0-9.,]+[ ]*-[ ]*[0-9.,]+[ ]*\s*)$/)) {
      this.setState({segmentInputError: 'Please input a valid chromosome format'});
    } else {
      // Split string by newline or comma
      let segmentList = segments.split(/[\n]+/);

      let segmentObjList = [];
      let invalidSegment = false;

      segmentList.forEach((segmentStr) => {
        segmentStr = segmentStr.trim();

        if (segmentStr) {
          const arr = segmentStr.split(':');
          let chromosome = arr[0].trim().substr(3);
          // Split coordinates to separate start and end
          const coordinates = arr[1].split('-');

          if (chromosome.toUpperCase() === 'X' || chromosome.toUpperCase() === 'Y' || parseInt(chromosome) < 24) {
            let segmentObj = {
              chromosome,
              startCoordinate: coordinates[0].replace(/,/gi, "").trim(),
              endCoordinate: coordinates[1].replace(/,/gi, "").trim()
            };

            if (!segmentObj.hasOwnProperty("id")) {
              segmentObj.id = Math.floor(1000000000000 + Math.random() * 9000000000000)
            }
            segmentObjList.push(segmentObj);
          } else {
            invalidSegment = true;
            this.setState({segmentInputError: 'Please input a valid chromosome number'});
          }
        }
      });

      if (!invalidSegment)
        this.props.handleAddNewSegmentList(segmentObjList);
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      segments: '',
      segmentInputError: ''
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.visible !== nextProps.visible) {
      const {form} = this.props;
      // Reset form fields
      form.resetFields();
      this.setState({segments: '', segmentInputError: ''});
    }
  }

  render() {
    let {visible, onCancel, handleAddNewSegment, form, fragment, update, handleUpdateFragment} = this.props;
    const {getFieldDecorator, getFieldError} = form;

    // form fields validation rules
    const fieldsConfig = {
      chromosome: {
        rules: [
          {required: true, message: 'Please enter chromosome number'},
          {validator: UtilHelper.validateBlankSpaces}
        ]
      },
      startCoordinate: {
        rules: [
          {required: true, message: 'Please enter start coordinate'},
          {validator: UtilHelper.validateBlankSpaces},
          {validator: (rule, value, callback) => {
            if (value && !value.match(/^[ ]*[0-9]+[,|.|0-9]+[ ]*$/)) {
              callback("Plase enter valid start coordinate");
            } else {
              callback();
            }
          }}
        ]
      },
      endCoordinate: {
        rules: [
          {required: true, message: 'Please enter end coordinate'},
          {validator: UtilHelper.validateBlankSpaces},
          {validator: (rule, value, callback) => {
            if (value && !value.match(/^[ ]*[0-9]+[,|.|0-9]+[ ]*$/)) {
              callback("Plase enter valid end coordinate");
            } else {
              callback();
            }
          }}
        ]
      }
    };

    if (update) {
      fieldsConfig.chromosome.initialValue = fragment.chromosome;
      fieldsConfig.startCoordinate.initialValue = fragment.startCoordinate;
      fieldsConfig.endCoordinate.initialValue = fragment.endCoordinate;
    }

    return (
      <Modal
        visible={visible}
        title= {this.props.update ? "Update Segment" : "Add Segment"}
        maskClosable={false}
        onCancel={onCancel}
        footer={null}
      >

        <Form layout="vertical">

          <FormItem
            validateStatus={getFieldError('chromosome') ? 'error' : ''}
            help={getFieldError('chromosome') || ''}
            label="Chromosome number">
            {getFieldDecorator('chromosome', fieldsConfig.chromosome)(
              <Select placeholder="Select chromosome">
                {
                  CHROMOSOMES && CHROMOSOMES.map((chromosome) => {
                    return <Option key={chromosome.id} value={chromosome.id}>{chromosome.name}</Option>
                  })
                }
              </Select>
            )}
          </FormItem>

          <FormItem
            validateStatus={getFieldError('startCoordinate') ? 'error' : ''}
            help={getFieldError('startCoordinate') || ''}
            label="Start Coordinate">
            {getFieldDecorator('startCoordinate', fieldsConfig.startCoordinate)(
              <Input placeholder="Enter start coordinate"/>
            )}
          </FormItem>

          <FormItem
            validateStatus={getFieldError('endCoordinate') ? 'error' : ''}
            help={getFieldError('endCoordinate') || ''}
            label="End Coordinate">
            {getFieldDecorator('endCoordinate', fieldsConfig.endCoordinate)(
              <Input placeholder="Enter end coordinate"/>
            )}
          </FormItem>
          <FormItem className={"text-right"}>
            {
              update
                ? <Button type="primary" onClick={handleUpdateFragment}>
                  Update Segment
                </Button>
                : <Button type="primary" onClick={handleAddNewSegment}>
                  Add Segment
                </Button>
            }

          </FormItem>
        </Form>

        {
          !update &&
          <>
          <Divider>OR</Divider>

          <FormItem>
              <TextArea
                placeholder="Enter segments in the format chr3:10,324,023-49,412,097"
                autosize={{minRows: 3, maxRows: 6}}
                value={this.state.segments}
                onChange={this.updateSegmentsValue}
              />
            {this.state.segmentInputError && <div className="custom-input-error">{this.state.segmentInputError}</div>}
          </FormItem>

          <FormItem className={"text-right"}>
            <Button type="primary" onClick={this.loadSegmentList} className={"margin-top-7"}>
              Load Segments
            </Button>
          </FormItem>

          </>
        }
      </Modal>
    );
  }

});

class AddFragment extends React.Component {

  showModal = () => {
    this.setState({visible: true});
  };
  handleCancel = () => {
    this.setState({visible: false});
  };
  /**
   * Method to add a new segment
   */
  handleAddNewSegment = () => {
    const {form} = this.formRef.props;

    form.validateFields((err, formData) => {
      if (err) {
        return;
      }
      if (!formData.hasOwnProperty("id")) {
        formData.id = Math.floor(1000000000000 + Math.random() * 9000000000000)
      }

      // remove commas from coordinates
      formData.startCoordinate = formData.startCoordinate.replace(/,/gi, "").trim();
      formData.endCoordinate = formData.endCoordinate.replace(/,/gi, "").trim();

      this.props.handleAddFragment(formData);
      this.handleCancel();
    });
  };
  /**
   * Method to add a new segment
   */
  handleUpdateFragment = () => {
    const {form} = this.formRef.props;

    form.validateFields((err, formData) => {
      if (err) {
        return;
      }

      formData.id = this.props.fragment.id;

      // remove commas from coordinates
      formData.startCoordinate = formData.startCoordinate.replace(/,/gi, "").trim();
      formData.endCoordinate = formData.endCoordinate.replace(/,/gi, "").trim();

      this.props.handleUpdateFragment(formData);
      this.handleCancel();
    });
  };
  /**
   * Method to add new segment list
   */
  handleAddNewSegmentList = (segmentList) => {
    this.props.handleAddFragmentList(segmentList);
    this.handleCancel();
  };
  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false
    }
  };

  render() {
    return (
      <div className="display-inline-block edit-segment-container">
        {
          this.props.update
            ? <Icon type="edit" onClick={this.showModal} className="edit-segment"/>
            : <>
            <span className="fragment-title">Segments</span>
            <Button className="add-fragment" icon="plus" onClick={this.showModal}></Button>
            </>
        }
        <div>
          <AddFragmentForm
            wrappedComponentRef={this.saveFormRef}
            visible={this.state.visible}
            onCancel={this.handleCancel}
            handleAddNewSegmentList={this.handleAddNewSegmentList}
            handleAddNewSegment={this.handleAddNewSegment}
            handleUpdateFragment={this.handleUpdateFragment}
            fragment={this.props.fragment ? this.props.fragment : {}}
            update={this.props.fragment ? true : false}
          />
        </div>
      </div>
    );
  };

}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

AddFragment = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddFragment);

export default AddFragment;
/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import Constants from '../../utils/Constants';

// Export Constants (Action names)
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const ACTIVATE_USER = 'ACTIVATE_USER';
export const DEACTIVATE_USER = 'DEACTIVATE_USER';

export function getAllUsers(users) {

  const usersToDisplay = users.map((user) => {

    const roles = user.roles && user.roles.map((role) => {
      return Constants.HUMAN_READABLE_SYSTEM_ROLES[role.name]
    });

    user['humanReadableRoles'] = roles && roles.join(' ');

    return user
  });

  return {
    type: GET_ALL_USERS,
    users: usersToDisplay
  };
}

export function createUser(user) {
  const roles = user.roles && user.roles.map((role) => {
    return Constants.HUMAN_READABLE_SYSTEM_ROLES[role.name]
  });

  user['humanReadableRoles'] = roles && roles.join(' ');

  return {
    type: CREATE_USER,
    user
  };
}

export function updateUser(user) {
  return {
    type: UPDATE_USER,
    user
  };
}

export function updateCurrentUser(user) {
  return {
    type: UPDATE_CURRENT_USER,
    user
  };
}

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user
  };
}

export function activateUser(user) {
  return {
    type: ACTIVATE_USER,
    user
  };
}

export function deactivateUser(user) {
  return {
    type: DEACTIVATE_USER,
    user
  };
}

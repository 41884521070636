import React from 'react';
import {Checkbox, Popover} from 'antd';

import './FilterSegment.css';

const CheckboxGroup = Checkbox.Group;

class FilterSegment extends React.Component {

  selectAllSegment = e => {
    let allSegmentsSelected = e.target.checked;

    const selectedSegments = allSegmentsSelected ? this.props.segmentMappings.map((frag) => {
      return frag.id
    }) : [];

    const currentState = {
      ...this.state,
      allSegmentsSelected,
      chromosomeIndeterminate: false
    };

    this.setState(currentState, () => {
      this.props.getOmimForSegment(selectedSegments);
    });

  };

  toggleSegmentSelection = checkedList => {
    const selectedSegmentCount = checkedList.length;
    const allSegmentCount = this.props.segmentMappings.length;
    const chromosomeIndeterminate = !!selectedSegmentCount && selectedSegmentCount < allSegmentCount;

    let currentState = {
      ...this.state,
      allSegmentsSelected: selectedSegmentCount === allSegmentCount,
      chromosomeIndeterminate
    };

    this.setState(currentState, () => {
      this.props.getOmimForSegment(checkedList);
    });

  };
  showTooltipForSegment = (fragment) => {

    return (
      <>
      <div>Original coordinates</div>
      <div
        className="margin-bottom-10">{
        `chr${fragment.chromosome}: ${fragment.startCoordinate}-${fragment.endCoordinate}`}
      </div>
      <div className="margin-bottom-10">{`Original Assembly: ${this.props.inputAssembly}`}</div>
      <div className="margin-bottom-10">Length:
        <b>{Number(fragment.endCoordinate - fragment.startCoordinate + 1).toLocaleString()}</b> bp
      </div>

      {
        fragment.isMapped
          ?
          <>
          <div>Lift coordinates</div>
          <div
            className="margin-bottom-10">{`chr${fragment.liftoverChromosome}: ${fragment.liftoverStartCoordinate}-${fragment.liftoverEndCoordinate}`}</div>
          <div className="margin-bottom-10">{`Liftover Assembly: ${this.props.outputAssembly}`}</div>
          <div className="margin-bottom-10">Liftover Length:
            <b>{Number(fragment.liftoverEndCoordinate - fragment.liftoverStartCoordinate + 1).toLocaleString()}</b> bp
          </div>
          </>
          : ''
      }
      </>
    );

  };

  constructor(props) {
    super(props);

    this.state = {
      chromosomeIndeterminate: false,
      allSegmentsSelected: true
    }
  }

  render() {
    return (
      <div className="menu-separator">
        <div className="case-dashboard-option">
          <Checkbox
            indeterminate={this.state.chromosomeIndeterminate}
            onChange={this.selectAllSegment}
            checked={this.state.allSegmentsSelected}
            className="display-table-cell">
          </Checkbox>
          <span className="mainHeading display-table-cell">Segments</span>
        </div>

        <div className="case-dashboard-option case-fragments">
          <CheckboxGroup value={this.props.selectedFragments} onChange={this.toggleSegmentSelection}>
            {this.props.segmentMappings && this.props.segmentMappings.map((fragment) => {
              return (
                <div key={fragment.id} className="margin-top-bottom-7">
                  <Checkbox value={fragment.id} key={fragment.id} className="display-table-cell">
                  </Checkbox>
                  <Popover placement="topLeft" content={this.showTooltipForSegment(fragment)} trigger="hover"
                           className="display-table-cell">
                    <a
                      href={`http://genome.ucsc.edu/cgi-bin/hgTracks?db=${this.props.inputAssembly}&position=chr${fragment.chromosome}:${fragment.startCoordinate}-${fragment.endCoordinate}`}
                      target={'_blank'} rel="noopener noreferrer"
                      className={!fragment.isMapped && this.props.inputAssembly !== this.props.outputAssembly ? 'fragment-color-red' : 'fragment-color-blue'}>
                      chr{fragment.chromosome + ":" + (fragment.startCoordinate / 1000000).toFixed(3) + "-" + (fragment.endCoordinate / 1000000).toFixed(3)}
                      (Mb)
                    </a> ({fragment.facetCount} genes)
                  </Popover>
                </div>
              )
            })
            }
          </CheckboxGroup>
        </div>
      </div>
    )
  }


}

export default FilterSegment;
/**
 * @author Punit Vajpeyi <punit@wemotiveforge.com>
 */

import React from 'react';
import ReactTable from 'react-table';
import {connect} from 'react-redux';
import {setPageTitle} from "../../DashboardActions";
import './UserDashboard.css';
import {getAnalysis, getBillingSummary, getSummary, downloadSummaryReport} from "../../DashboardApiActions";
import {Card, Col, Row, Radio, Button} from 'antd';
import {ACTIONS} from '../../../../utils/Constants';
import Notification from '../../../../utils/Notification';
import GenaLoader from "../../../../utils/components/GenaLoader/GenaLoader";
import UtilHelper from "../../../../utils/UtilHelper";

const notification = new Notification(5)

const adminColumns = [{
    Header: 'Organization', accessor: 'name', resizable: false, id: 'name',
    Cell: row => {
      return <span className={'case-table-case-name'}>
        {row.original.name}
      </span>
    }
  },
  {Header: '# Users', accessor: 'totalNoOfUsers', resizable: false},
  {
    Header: '# Active Users/Wk',
    accessor: d => Number(d.activeUserslast1Week),
    id: 'activeUserslast1Week',
    resizable: false
  },
  {
    Header: '# Active Users/4 Wks',
    accessor: d => Number(d.activeUserslast4Weeks),
    id: 'activeUserslast4Weeks',
    resizable: false
  },
  {
    Header: '# Cases',
    accessor: d => Number(d.totalNoOfCases),
    id: 'totalNoOfCases',
    resizable: false
  },
  {
    Header: '# Cases This Wk',
    accessor: d => Number(d.noOfCasesThisWeek),
    id: 'noOfCasesThisWeek',
    resizable: false
  },
  {
    Header: '# Cases Last Wk',
    accessor: d => Number(d.noOfCasesLastWeek),
    id: 'noOfCasesLastWeek',
    resizable: false
  },
  {
    Header: '# Cases This Month',
    accessor: d => Number(d.noOfCasesThisMonth),
    id: 'noOfCasesThisMonth',
    resizable: false
  },
  {
    Header: '# Cases This Year',
    accessor: d => Number(d.noOfCasesThisYear),
    id: 'noOfCasesThisYear',
    resizable: false
  },
  {
    Header: 'Last Case Created',
    accessor: d => d.lastCaseCreatedAt ? new Date(d.lastCaseCreatedAt).getTime() : 0,
    id: 'lastCaseCreatedAt',
    resizable: false,
    Cell: d => d.original.lastCaseCreatedAt ? new Date(d.original.lastCaseCreatedAt).toLocaleDateString('en-us') : '-'
  }];

const orgManagerColumns = [{Header: 'Email', accessor: 'email', resizable: false},
  {Header: 'First Name', accessor: 'firstName', resizable: false},
  {Header: 'Last Name', accessor: 'lastName', resizable: false},
  {Header: '# Cases', accessor: 'totalNoOfCases', resizable: false},
  {
    Header: '# Cases This Week',
    accessor: d => Number(d.noOfCasesThisWeek),
    id: 'noOfCasesThisWeek',
    resizable: false
  },
  {
    Header: '# Cases Last Week',
    accessor: d => Number(d.noOfCasesLastWeek),
    id: 'noOfCasesLastWeek',
    resizable: false
  },
  {
    Header: '# Cases This Month',
    accessor: d => Number(d.noOfCasesThisMonth),
    id: 'noOfCasesThisMonth',
    resizable: false
  },
  {
    Header: '# Cases This Year',
    accessor: d => Number(d.noOfCasesThisYear),
    id: 'noOfCasesThisYear',
    resizable: false
  },
  {
    Header: 'Last Case Created',
    accessor: d => d.lastCaseCreatedAt ? new Date(d.lastCaseCreatedAt).getTime() : 0,
    id: 'lastCaseCreatedAt',
    resizable: false,
    Cell: d => d.original.lastCaseCreatedAt ? new Date(d.original.lastCaseCreatedAt).toLocaleDateString('en-us') : '-'
  }];

const orgManagerLoginColumns = [{Header: 'Email', accessor: 'email', resizable: false},
  {Header: 'First Name', accessor: 'firstName', resizable: false},
  {Header: 'Last Name', accessor: 'lastName', resizable: false},
  {
    Header: '# Logins This Week',
    accessor: d => Number(d.noOfLoginsThisWeek),
    id: 'noOfCasesThisWeek',
    resizable: false
  },
  {
    Header: '# Logins Last Week',
    accessor: d => Number(d.noOfLoginsLastWeek),
    id: 'noOfCasesLastWeek',
    resizable: false
  },
  {
    Header: '# Logins This Month',
    accessor: d => Number(d.noOfLoginsThisMonth),
    id: 'noOfCasesThisMonth',
    resizable: false
  },
  {
    Header: '# Logins This Year',
    accessor: d => Number(d.noOfLoginsThisYear),
    id: 'noOfCasesThisYear',
    resizable: false
  },
  {
    Header: 'Last Login At',
    accessor: d => d.lastLoginAt ? new Date(d.lastLoginAt).getTime() : 0,
    id: 'lastLoginAt',
    resizable: false,
    Cell: d => d.original.lastLoginAt ? new Date(d.original.lastLoginAt).toLocaleDateString('en-us') : '-'
  }];

class UserDashboard extends React.Component {

  getDashboardSummary = () => {
    this.setState({showLoader: true}, () => {
      this.props.getSummary().then((res) => {

        this.setState({
          totalUsers: res.hasOwnProperty('totalUsers') ? res.totalUsers : '-',
          totalOrganizations: res.hasOwnProperty('totalOrganizations') ? res.totalOrganizations : '-',
          totalCases: res.hasOwnProperty('totalCases') ? res.totalCases : '-',
          totalRevenue: res.hasOwnProperty('totalRevenue') && res.totalRevenue ? `${UtilHelper.numberToCurrency(res.totalRevenue)}` : '-'
        }, () => {
          this.getBillingSummary();
        });
      }, error => {
        this.setState({showLoader: false});
      });
    });
  };

  getDashboardAnalysis = () => {
    const offset = this.state.pageIndex * this.state.pageSize;
    const limit = this.state.pageSize;
    this.setState({ showLoader: true })
    this.props.getAnalysis({ offset, limit }).then((res) => {
      if (this.props.currentRole.isSuperAdmin)
        this.setState({overallAnalysis: res, showLoader: false});
      else if (this.props.currentRole.isOrgManager)
        this.setState({organizationAnalysis: res, showLoader: false});
      else
        this.setState({analystAnalysis: res, showLoader: false});
    }, err => {
      this.setState({showLoader: false});
    });
  };


  getBillingSummary = () => {
    this.props.getBillingSummary().then((res) => {
      this.setState({billingInfo: res}, () => {
        this.getDashboardAnalysis();
      });
    }, err => {
      this.setState({showLoader: false});
    });
  };

  componentDidMount = () => {

    this.props.updatePageTitle(this.props.pageTitle, false);

    if (this.props.currentRole.isAnalyst) {
      this.getDashboardAnalysis();
    } else {
      this.getDashboardSummary();
    }
  };

  onReportChange = e => {
    this.setState({currentReport: e.target.value});
  };

  constructor(props) {
    super(props);

    this.state = {
      totalUsers: '-',
      totalOrganizations: '-',
      totalCases: '-',
      totalRevenue: '-',
      showLoader: true,
      organizationAnalysis: [],
      overallAnalysis: { count:0, rows: [] },
      analystAnalysis: {},
      billingInfo: {},
      currentReport: 'cases',

      pageIndex: 0,
      pageSize: 10,
    };
  }

  downloadReport = () => {
    this.props.downloadSummaryReport().then((res) => {
      notification.show('info', 'Sumary Report', res.message);
    }, (err) => {
      notification.show('error', 'Sumary Report', 'Something went wrong. Please try again!');
    });
    // window.open(`${process.env.REACT_APP_SERVER_API_URL}${ACTIONS.DOWNLOAD_APPLICATION_SUMMARY_REPORT}`, '_blank').focus();
  };

  render() {

    const {billingInfo, analystAnalysis} = this.state;

    return (
      <div className="sivotec-page-content">
        {
          this.props.currentRole.isSuperAdmin &&
          <>
          <Row gutter={16}>
            <Col className="gutter-row" span={6}>
              <Card className="dashboard-card bg-green" onClick={() => window.open('/users', '_blank').focus() }>
                <Col span={4}>
                  <div className="dashboard-image">
                    <i className="fa fa-user col-sm-2"></i>
                  </div>
                </Col>
                <Col span={20}>
                  <div className="dashboard-number text-right">
                    {this.state.totalUsers}
                  </div>
                  <div className="text-right">
                    Users
                  </div>
                </Col>
              </Card>
            </Col>

            <Col className="gutter-row" span={6}>
              <Card className="dashboard-card bg-red" onClick={() => window.open('/organizations', '_blank').focus() }>
                <Col span={4}>
                  <div className="dashboard-image">
                    <i className="fa fa-users col-sm-2"></i>
                  </div>
                </Col>
                <Col span={20}>
                  <div className="dashboard-number text-right">
                    {this.state.totalOrganizations}
                  </div>
                  <div className="text-right">
                    Organizations
                  </div>
                </Col>
              </Card>
            </Col>

            <Col className="gutter-row" span={6}>
              <Card className="dashboard-card bg-blue" onClick={() => window.open('/cases', '_blank').focus() }>
                <Col span={4}>
                  <div className="dashboard-image">
                    <i className="fa fa-briefcase col-sm-2"></i>
                  </div>
                </Col>
                <Col span={20}>
                  <div className="dashboard-number text-right">
                    {this.state.totalCases}
                  </div>
                  <div className="text-right">
                    Cases
                  </div>
                </Col>
              </Card>
            </Col>

            <Col className="gutter-row" span={6}>
              <Card className="dashboard-card bg-yellow">
                <Col span={4}>
                  <div className="dashboard-image">
                    <i className="fa fa-dollar col-sm-2"></i>
                  </div>
                </Col>
                <Col span={20}>
                  <div className="dashboard-number text-right">
                    {this.state.totalRevenue}
                  </div>
                  <div className="text-right">
                    Revenue
                  </div>
                </Col>
              </Card>
            </Col>
          </Row>

          <div className='dashboard-analysis'>
            <div className="dashboard-table-heading">Billing</div>

            <div className="dashboard-billing-container">
              <Row>
                <Col span={12} className='billing-cell'>
                  Revenue Last Week
                </Col>
                <Col span={12} className='billing-cell'>
                  {billingInfo.revenueLast1Week ? `$${UtilHelper.numberToCurrency(parseFloat(billingInfo.revenueLast1Week) / 100)}` : '-'}
                </Col>
              </Row>
              <Row>
                <Col span={12} className='billing-cell'>
                  Revenue Last 4 Weeks
                </Col>
                <Col span={12} className='billing-cell'>
                  {billingInfo.revenueLast4Week ? `$${UtilHelper.numberToCurrency(parseFloat(billingInfo.revenueLast4Week) / 100)}` : '-'}
                </Col>
              </Row>
              <Row>
                <Col span={12} className='billing-cell'>
                  Revenue Last 8 Weeks
                </Col>
                <Col span={12} className='billing-cell'>
                  {billingInfo.revenueLast8Week ? `$${UtilHelper.numberToCurrency(parseFloat(billingInfo.revenueLast8Week) / 100)}` : '-'}
                </Col>
              </Row>
              <Row>
                <Col span={12} className='billing-cell last-billing-cell'>
                  Revenue Last 12 Weeks
                </Col>
                <Col span={12} className='billing-cell last-billing-cell'>
                  {billingInfo.revenueLast12Week ? `$${UtilHelper.numberToCurrency(parseFloat(billingInfo.revenueLast12Week) / 100)}` : '-'}
                </Col>
              </Row>
            </div>
          </div>

          <div className='dashboard-analysis'>
            <div>
              <div className="dashboard-table-heading active-user-report">Active User Report</div>

              <Button type="primary" onClick={this.downloadReport} className="download-dashboard-report">
                Download
              </Button>

            </div>

            <ReactTable
              // defaultFilterMethod={UtilHelper.filterColumnReactTable}
              defaultPage={0}
              defaultPageSize={10}
              noDataText='No records present.'
              data={this.state.overallAnalysis.rows}
              columns={adminColumns}
              className="-striped -highlight"
              filterable={false}
              sortable={false}
              manual={true}
              pages={Math.ceil(this.state.overallAnalysis.count / this.state.pageSize)}
              page={this.state.pageIndex}
              pageSize={this.state.pageSize}
              resizable={false}
              onPageChange={(page) => {
                this.setState({ pageIndex: page }, () => {
                  this.getDashboardAnalysis();
                });
              }}
              onPageSizeChange={(size) => {
                this.setState({ pageIndex: 0, pageSize: size }, () => {
                  this.getDashboardAnalysis();
                });
              }}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, handleOriginal) => {
                    if (column.id === 'name') {
                      window.open(`/cases/${rowInfo.original.id}/list?${rowInfo.original.name}`, '_blank').focus();
                    }
                  }
                }
              }}
            />
          </div>
          </>
        }

        {
          this.props.currentRole.isOrgManager &&

          <>
          <Row gutter={16}>
            <Col className="gutter-row" span={6}>
              <Card className="dashboard-card bg-green" onClick={() => window.open('/users', '_blank').focus() }>
                <Col span={4}>
                  <div className="dashboard-image">
                    <i className="fa fa-user col-sm-2"></i>
                  </div>
                </Col>
                <Col span={20}>
                  <div className="dashboard-number text-right">
                    {this.state.totalUsers}
                  </div>
                  <div className="text-right">
                    Users
                  </div>
                </Col>
              </Card>
            </Col>

            <Col className="gutter-row" span={6}>
              <Card className="dashboard-card bg-blue" onClick={() => window.open('/cases', '_blank').focus() }>
                <Col span={4}>
                  <div className="dashboard-image">
                    <i className="fa fa-briefcase col-sm-2"></i>
                  </div>
                </Col>
                <Col span={20}>
                  <div className="dashboard-number text-right">
                    {this.state.totalCases}
                  </div>
                  <div className="text-right">
                    Cases
                  </div>
                </Col>
              </Card>
            </Col>
          </Row>

          <div className='dashboard-analysis'>
            <div className="dashboard-table-heading">Billing</div>

            <div className="dashboard-billing-container">
              <Row>
                <Col span={12} className='billing-cell'>
                  Charges Last Week
                </Col>
                <Col span={12} className='billing-cell'>
                  {billingInfo.revenueLast1Week ? `$${UtilHelper.numberToCurrency(parseFloat(billingInfo.revenueLast1Week) / 100)}` : '-'}
                </Col>
              </Row>
              <Row>
                <Col span={12} className='billing-cell'>
                  Charges Last 4 Weeks
                </Col>
                <Col span={12} className='billing-cell'>
                  {billingInfo.revenueLast4Week ? `$${UtilHelper.numberToCurrency(parseFloat(billingInfo.revenueLast4Week) / 100)}` : '-'}
                </Col>
              </Row>
              <Row>
                <Col span={12} className='billing-cell'>
                  Charges Last 8 Weeks
                </Col>
                <Col span={12} className='billing-cell'>
                  {billingInfo.revenueLast8Week ? `$${UtilHelper.numberToCurrency(parseFloat(billingInfo.revenueLast8Week) / 100)}` : '-'}
                </Col>
              </Row>
              <Row>
                <Col span={12} className='billing-cell last-billing-cell'>
                  Charges Last 12 Weeks
                </Col>
                <Col span={12} className='billing-cell last-billing-cell'>
                  {billingInfo.revenueLast12Week ? `$${UtilHelper.numberToCurrency(parseFloat(billingInfo.revenueLast12Week) / 100)}` : '-'}
                </Col>
              </Row>
            </div>
          </div>

          <div className='dashboard-analysis'>

            <div className="dashboard-table-heading">User Activity Report</div>


            <div className="report-toggle-container">
              <Radio.Group onChange={this.onReportChange} value={this.state.currentReport}>
                <Radio.Button value="cases" key="cases">Cases</Radio.Button>
                <Radio.Button value="logins" key="logins">Logins</Radio.Button>
              </Radio.Group>
            </div>

            {
              this.state.currentReport === 'cases'
                ?
                  <ReactTable
                    defaultFilterMethod={UtilHelper.filterColumnReactTable}
                    defaultPageSize={10}
                    noDataText='No records present.'
                    data={this.state.organizationAnalysis}
                    columns={orgManagerColumns}
                    className="-striped -highlight"
                  />
                :

                  <ReactTable
                    defaultFilterMethod={UtilHelper.filterColumnReactTable}
                    defaultPageSize={10}
                    noDataText='No records present.'
                    data={this.state.organizationAnalysis}
                    columns={orgManagerLoginColumns}
                    className="-striped -highlight"
                    />
            }
          </div>
          </>
        }
        {
          this.props.currentRole.isAnalyst &&
          <>
          <div className='dashboard-analysis'>
            <div className="dashboard-table-heading">Analyst Statistics</div>

            <div className="dashboard-billing-container">
              <Row>
                <Col span={12} className='billing-cell'>
                  Total No of Cases
                </Col>
                <Col span={12} className='billing-cell'>
                  {analystAnalysis.totalNoOfCases ? analystAnalysis.totalNoOfCases : '-'}
                </Col>
              </Row>
              <Row>
                <Col span={12} className='billing-cell'>
                  No of Cases This Week
                </Col>
                <Col span={12} className='billing-cell'>
                  {analystAnalysis.noOfCasesThisWeek ? analystAnalysis.noOfCasesThisWeek : '-'}
                </Col>
              </Row>
              <Row>
                <Col span={12} className='billing-cell'>
                  No of Cases Last Week
                </Col>
                <Col span={12} className='billing-cell'>
                  {analystAnalysis.noOfCasesLastWeek ? analystAnalysis.noOfCasesLastWeek : '-'}
                </Col>
              </Row>
              <Row>
                <Col span={12} className='billing-cell last-billing-cell'>
                  No of Cases This Month
                </Col>
                <Col span={12} className='billing-cell last-billing-cell'>
                  {analystAnalysis.noOfCasesThisMonth ? analystAnalysis.noOfCasesThisMonth : '-'}
                </Col>
              </Row>
              <Row>
                <Col span={12} className='billing-cell last-billing-cell'>
                  No of Cases This Year
                </Col>
                <Col span={12} className='billing-cell last-billing-cell'>
                  {analystAnalysis.noOfCasesThisYear ? analystAnalysis.noOfCasesThisYear : '-'}
                </Col>
              </Row>
              <Row>
                <Col span={12} className='billing-cell last-billing-cell'>
                  Last case creation date
                </Col>
                <Col span={12} className='billing-cell last-billing-cell'>
                  {analystAnalysis.lastCaseCreatedAt ? new Date(analystAnalysis.lastCaseCreatedAt).toLocaleDateString('en-us') : '-'}
                </Col>
              </Row>
            </div>
          </div>
          </>
        }

        <GenaLoader showLoader={this.state.showLoader}/>

      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    loggedInUser: state.auth.loggedInUser,
    currentRole: state.auth.currentRole
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updatePageTitle: (pageTitle, enablePageTitle) => {
      dispatch(setPageTitle(pageTitle, enablePageTitle));
    },

    getSummary: () => {
      return dispatch(getSummary()).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });

    },

    getAnalysis: (params) => {
      return dispatch(getAnalysis(params)).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });

    },

    getBillingSummary: () => {
      return dispatch(getBillingSummary()).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });

    },

    downloadSummaryReport: () => {
      return dispatch(downloadSummaryReport()).then((res) => {
        return Promise.resolve(res);
      }, (error) => {
        return Promise.reject(error);
      });

    }
  }
};

UserDashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDashboard);

export default UserDashboard;

import React from 'react';
import './CustomFooter.css';
import {STATIC_URLS} from '../../../../utils/Constants';

export default function CustomFooter() {
  return (
    <ul className="footer-list-inline">
      <li>
        <a href={STATIC_URLS.CONTACT_US} target={'_blank'} rel="noopener noreferrer">
          Contact Us
        </a>
      </li>
      <li>|</li>
      <li>
        <a href={STATIC_URLS.SUPPORT} target={'_blank'} rel="noopener noreferrer">
          Support
        </a>
      </li>
      <li>|</li>
      <li>
        <a href={STATIC_URLS.TERMS_OF_USAGE} target={'_blank'} rel="noopener noreferrer">
          Terms of Use
        </a>
      </li>
      <li>|</li>
      <li>
        <a href={STATIC_URLS.PRIVACY_POLICY} target={'_blank'} rel="noopener noreferrer">
          Privacy Policy
        </a>
      </li>
      <li>|</li>
      <li>
        <a href={STATIC_URLS.ABOUT_US} target={'_blank'} rel="noopener noreferrer">
          About Us
        </a>
      </li>
    </ul>
  )
}
import React from 'react';
import './Disclaimer.css';

export default function Disclaimer(props) {
  return (<div className="privacy-policy-container">
    <p><b>Disclaimer</b></p>

    <p>
      GENA is designed primarily as a diagnostic/decision support and educational resource for health care
      providers in delivering quality medical genetic services. This tool should not be considered inclusive
      of all proper and appropriate procedures and tests or exclusive of other procedures and tests that are
      reasonably directed to obtaining the same results. In determining the propriety of any specific
      procedure or test, the user of GENA should apply his or her own professional judgment to the specific
      clinical circumstances presented by the individual patient or specimen. It may be prudent, however, to
      document in the patient's record the rationale for any significant deviation. If our tool assists the
      user in reaching a diagnosis and publication is being considered, please acknowledge its use by citing
      Wierenga KJ, Jiang Z, Yang AC, Mulvihill JJ, Tsinoremas NF (2013) A clinical evaluation tool for SNP
      arrays, especially for autosomal recessive conditions in offspring of consanguineous parents. Genetics
      In Medicine 15, 354-60.<br/><br/>

      <b>Acknowledgements:</b><br/>
      1. Online Mendelian Inheritance in Man, OMIM (TM). McKusick-Nathans Institute of Genetic Medicine,
      Johns Hopkins University (Baltimore, MD) World Wide Web URL:
      <a href="http://www.omim.org">http://www.omim.org</a><br/>
      2. National Center for Biotechnology Information (NCBI), National Library of Medicine (Bethesda, MD).
      World Wide Web URL:
      <a href="http://www.ncbi.nlm.nih.gov/">http://www.ncbi.nlm.nih.gov/</a><br/>
      3. UCSC Genome Browser. World Wide Web URL: <a
      href="http://genome.ucsc.edu/">http://genome.ucsc.edu/</a>; contributors to the UCSC Human Genome
      Browser Project, as listed in: World Wide Web URL: <a
      href="http://genome.ucsc.edu/goldenPath/credits.html#human_credits">http://genome.ucsc.edu/goldenPath/credits.html#human_credits</a>.<br/>
      4. The Human Phenotype Ontology, World Wide Web URL: <a
      href="http://www.human-phenotype-ontology.org">http://www.human-phenotype-ontology.org</a>.<br/>
      5. Advanced Computing Group at Center for Computational Science

    </p>

  </div>);
}
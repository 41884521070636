import HttpRequest from '../../utils/HttpRequest';

export function getSummary() {
  return (dispatch) => {
    return new HttpRequest(dispatch, '/dashboard/getSummary', 'get')
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }, error => {
        return Promise.reject(error);
      });
  };
}

export function getAnalysis(params) {
  return (dispatch) => {
    return new HttpRequest(dispatch, '/dashboard/getAnalysis', 'get', {}, {}, { queryParams: params })
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }, error => {
        return Promise.reject(error);
      });
  };
}

export function getBillingSummary() {
  return (dispatch) => {
    return new HttpRequest(dispatch, '/dashboard/getBillingSummary', 'get')
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }, error => {
        return Promise.reject(error);
      });
  };
}

export function downloadSummaryReport() {
  return (dispatch) => {
    return new HttpRequest(dispatch, '/dashboard/downloadApplicationSummaryReport', 'get')
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }, error => {
        return Promise.reject(error);
      });
  };
}

import React, {Component} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import './CheckoutForm.css';

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};

class CheckoutForm extends Component {
  state = {
    errorMessage: '',
  };

  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };

  handleSubmit = (evt) => {
    evt.preventDefault();

    if (this.props.stripe) {
      this.props.showLoader();

      this.props.stripe.createToken().then(this.props.handleTokenCreation);
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <label>
          Card details
          <CardElement
            hidePostalCode={true}
            onChange={this.handleChange}
            {...createOptions()}
          />
        </label>
        <div className="stripe-error" role="alert">
          {this.state.errorMessage}
        </div>
        <div className="text-align-right">
          <button className="pay-button">Submit</button>
        </div>
      </form>
    );
  }
}

export default injectStripe(CheckoutForm);
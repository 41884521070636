/**
 * @author Vaibhav Raut <vaibhav@wemotiveforge.com>
 */

import HttpRequest from '../../utils/HttpRequest';
import {createCase, getAllCases, updateCase} from "./CaseActions";
import Constants from '../../utils/Constants';

/**
 * Server API call to get all cases
 * - Dispatch getAllResources action after successful response from server
 * @returns {function(*)}
 */
export function getAllCasesRequest(id) {
  return (dispatch) => {
    const url = Constants.ACTIONS.CASES + (id ? `?organizationId=${id}`: '');

    return new HttpRequest(dispatch, url, 'get')
      .send()
      .then(res => {
        dispatch(getAllCases(res.data.data));
        return Promise.resolve(res.data.data);
      }, error => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to create a new case
 * - Dispatch createCase action after successful response from server
 * @returns {function(*)}
 */
export function createCaseRequest(data) {
  return (dispatch) => {
    return new HttpRequest(dispatch, Constants.ACTIONS.CASES, 'post', data)
      .send()
      .then(res => {
        dispatch(createCase(res.data.data));
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to update an case
 * - Dispatch updateCase action after successful response from server
 * @returns {function(*)}
 */
export function updateCaseRequest(caseId, data) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `${Constants.ACTIONS.CASES}/${caseId}`, 'patch', data)
      .send()
      .then(res => {
        dispatch(updateCase(res.data.data));
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to dissociate from Organization
 * - Dispatch updateCase action after successful response from server
 * @returns {function(*)}
 */
export function dissociateFromOrganization(userId) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `${Constants.ACTIONS.USER_LIST}/${userId}/dissociateFromOrganization`, 'post', {})
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

/**
 * Server API call to update an case
 * - Dispatch updateCase action after successful response from server
 * @returns {function(*)}
 */
export function getCaseRequest(userId, data) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `${Constants.ACTIONS.CASES}/${userId}`, 'get', data)
      .send()
      .then(res => {
        return res.data.data;
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function getCaseAnalysisDetails(caseId, queryString) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `${Constants.ACTIONS.GENES}?caseVersionId=${caseId}${queryString}`, 'get', {})
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function getHpoTermHierarchyForSegment(caseId, queryString) {
  return new HttpRequest(null, `${Constants.ACTIONS.GENES}/getHpoTermHierarchyForSegment?caseVersionId=${caseId}${queryString}`, 'get', {})
    .send()
    .then(res => {
      return Promise.resolve(res.data.data);
    }).catch((error) => {
      return Promise.reject(error);
    });
}

export function checkAnalysisStatus(caseVersionId) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `caseVersions/${caseVersionId}/analysisStatus`, 'get', {})
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function suggestHpoRequest(query) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `${Constants.ACTIONS.AUTO_SUGGEST_HPO_TERMS}${query}`, 'get', {})
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function createVersion(caseId) {
  return (dispatch) => {
    return new HttpRequest(dispatch, `caseVersions`, 'put', {caseId})
      .send()
      .then(res => {
        return Promise.resolve(res.data.data);
      }).catch((error) => {
        return Promise.reject(error);
      });
  };
}
